import Amplify, { Auth } from "aws-amplify";
import { withOAuth } from "aws-amplify-react";
import ReactQueryParams from 'react-query-params';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import React, { Fragment } from 'react';
import { Container, Row, Col, Nav, Navbar, NavDropdown, Dropdown, Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faBars } from '@fortawesome/free-solid-svg-icons'
import './App.css';
import Routes from "./Routes";
import Footer from './components/Footer';
import LoginHeader from './components/LoginHeader';
import Cookies from 'universal-cookie';
import config from "./config";
import axios from 'axios';

/*********************************
* Amplify Configuration
*********************************/
Amplify.configure({
	Auth: {
			mandatorySignIn: true,
			region: config.cognito.REGION,
			userPoolId: config.cognito.USER_POOL_ID,
			identityPoolId: config.cognito.IDENTITY_POOL_ID,
			userPoolWebClientId: config.cognito.APP_CLIENT_ID
		},
		Storage: {
			region: config.s3.REGION,
			bucket: config.s3.BUCKET,
			identityPoolId: config.cognito.IDENTITY_POOL_ID
		},
		API: {
			endpoints: [
					{
						name: "notes",
						endpoint: config.apiGateway.URL,
						region: config.apiGateway.REGION
					},
			]
		}
});

function getUrlVars() {
	var vars = {};
	var parts = window.location.href.replace(/[?#]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
		vars[key] = value;
	});
	return vars;
}

function getUrlParam(parameter, defaultvalue) {
	var urlparameter = defaultvalue;
	if(window.location.href.indexOf(parameter) > -1) {
		urlparameter = getUrlVars()[parameter];
	}
	return urlparameter;
}

class App extends ReactQueryParams {

	constructor(props) {
		super(props);

		this.handleShowProgress = this.handleShowProgress.bind(this);
		this.handleCloseProgress = this.handleCloseProgress.bind(this);
		this.userDataCallback = this.userDataCallback.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		var userData = localStorage.getItem("loginUserData");

		this.state = {
			screenWidth: null,
			mobileMaxWidth: 768,
			isAuthenticated: false,
			isAuthenticating: true,
			fullName: '',
			showProgress: false,
			notifications: [],
			userDataIsLoaded: false, //used for render trigger
			...(userData && { userData: { ...JSON.parse(userData) } })
		};
	}

	/*refreshUserData() {
		localStorage.clearItem("loginUserData");

		try {
			await Auth.currentAuthenticatedUser().then(response => {
				this.setState({ fullName: response.attributes.given_name + " " + response.attributes.family_name });
				var jwtToken = response.signInUserSession.idToken.jwtToken;

				axios.get(config.apiGateway.URL + '/me', { headers: {Authorization: jwtToken} })
					.then(result => {
						console.log("GET /me success");
						console.log(result.data.data)
						this.userDataCallback(result.data.data);
					})
					.catch(error => {
						console.log("GET /me error");
						console.log(error);
						//window.alert(JSON.stringify(error));
					})
			});

		} catch(e) {
			console.log(e);
		}
	}*/

	updateWindowDimensions() {
		console.log("updating " + window.innerWidth);
		this.setState({ screenWidth: window.innerWidth });
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions)
	}

	async componentDidMount() {
			let userData = localStorage.getItem("loginUserData");
			const idToken = getUrlParam('id_token', '');
			//var userData = localStorage.getItem("loginUserData");

			if (idToken !== '') {
				localStorage.clear();
				userData = null;
				//var url = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
				var url = 'https://' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

			const params = {
				ClientId: config.cognito.APP_CLIENT_ID,
				UserPoolId: config.cognito.USER_POOL_ID,
				AppWebDomain: config.cognito.DOMAIN,
				TokenScopesArray: [
					'phone',
					'email',
					'profile',
					'openid',
					'aws.cognito.signin.user.admin'
				],
				RedirectUriSignIn: url,
				RedirectUriSignOut: url,
				IdentityProvider: 'Facebook'
			}

			const cognitoAuthClient = new CognitoAuth(params);

			cognitoAuthClient.userhandler = {
				// user signed in
				onSuccess: (result) => {
					console.log("success");
					console.log(result);
				},
				onFailure: (err) => {
					console.log("Error in cognito hosted auth response", err);
				}
			};

			const curUrl = window.location.href;
			cognitoAuthClient.parseCognitoWebResponse(curUrl);

			const cookies = new Cookies();
			cookies.set('showProgress', 'yes', { path: '/' });
		}

		await this.loadFacebookSDK();

		try {
			await Auth.currentAuthenticatedUser().then(response => {
				this.userHasAuthenticated(true);
				this.setState({ fullName: response.attributes.given_name + " " + response.attributes.family_name });
				var jwtToken = response.signInUserSession.idToken.jwtToken;

				if(!userData) {
					axios.get(config.apiGateway.URL + '/users/me', { headers: {Authorization: jwtToken} })
						.then(result => {
							console.log("GET /users/me success");
							console.log(result.data.data)
							this.userDataCallback(result.data.data);
						})
						.catch(error => {
							console.log("GET /users/me error");
							console.log(error);
							//window.alert(JSON.stringify(error));
						})
				}

				const cookies = new Cookies();
				// get notifications
				if (cookies.get('showProgress') === 'yes') {
					axios.get(config.apiGateway.URL + '/users/me/notifications', { headers: {Authorization: jwtToken} })

						.then(response => {
							console.log("GET /users/me/notifications success");
							console.log(response);

							this.setState({ notifications: response.data.data });
							this.setState({ showProgress: true });
						 })
						 .catch(error => {
							console.log("GET /users/me/notifications error");
							console.log(error);
						 })
				}
			});

		}
		catch(e) {
			if (e !== 'No current user') {
				console.log(e);
			}
		}

		this.setState({ isAuthenticating: false });
		window.addEventListener("resize", this.updateWindowDimensions());
	}

	loadFacebookSDK() {
		window.fbAsyncInit = function() {
			window.FB.init({
				appId            : config.social.FB,
				autoLogAppEvents : true,
				xfbml            : true,
				version          : 'v3.1'
			});
		};

		(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}

	userHasAuthenticated = authenticated => {
		this.setState({ isAuthenticated: authenticated });
	}

	handleCloseProgress() {
			this.setState({ showProgress: false });
			const cookies = new Cookies();
		cookies.set('showProgress', 'no', { path: '/' });
	}

	handleShowProgress() {
			this.setState({ showProgress: true });
			const cookies = new Cookies();
		cookies.set('showProgress', 'no', { path: '/' });
		window.location = '/account';
	}

	handleLogout = async event => {
		await Auth.signOut();

		this.userHasAuthenticated(false);
		localStorage.clear();

		//this.props.history.push("/login");
		window.location = "/login";
	}

	// this is how logins performed in different routes can bubble user data back up to the login header
	userDataCallback(data) {
		console.log("app usr dat call");
		const userData = { "fullName": data.given_name + " " + data.family_name
				, "profile_image": data.profile_image, "cognitosub": data.cognito_sub };
		localStorage.setItem("loginUserData", JSON.stringify(userData));
		this.setState({ userData: userData, userDataIsLoaded: true });
		//window.location = "/";
	}

	isMobile() {
		//console.log("checking");
		return this.state.screenWidth <= this.state.mobileMaxWidth;
	}

	render() {
		/* the footer is passed as a prop to all main pages and rendered there - the design has it floating above the content backgrounds */
		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated,
			userDataCallback: this.userDataCallback,
			footer: <Footer/>
		};

		//let isMobile = this.state.screenWidth <= this.state.mobileMaxWidth;
		//console.log(this.state.screenWidth);
		//console.log(isMobile);
		let jtsTooltip = <Tooltip>Coming soon!</Tooltip>;
		let dropdownIcon = <div>Channels&nbsp; <FontAwesomeIcon icon={ faChevronDown } className="channelsDropdownFa" /></div>;
		let mobileIcon = <div><FontAwesomeIcon icon={ faBars } className="channelsMobileDropdownFa" /></div>;

		return (
			!this.state.isAuthenticating && (
				<div className="App">
					<div>
						{/* Page Content */}
						<Container fluid="true" className="px-0">
							<Row className="no-gutters mainPageRow">
								<Col sm={12} md={2} className="mainNavCol" style={{zIndex: 100}}>
									<div className="main-sidebar">
										<h1 className="main-logo my-4">{/*CelebYou*/}<a href="/"><img className="img-fluid" src="/img/main/celebyou-logo01.png" alt="CelebYou - No Bullying, Just Music!" /></a></h1>
										{
											<Fragment>
												<div className="mobileNavMenu my-3 mx-0 d-inline-block">
													<Nav className="">
														<NavDropdown title={ mobileIcon } open={ this.state.dropdownMenuIsOpen } className="d-inline-block dropdown-menu-right">
															<NavDropdown.Item href="/channels">Channels</NavDropdown.Item>
															<NavDropdown.Item href="/genres">Genres</NavDropdown.Item>
															<NavDropdown.Item href="/artists">Artists</NavDropdown.Item>
															<NavDropdown.Item href="/about">About CelebYou</NavDropdown.Item>
															{
																childProps.isAuthenticated ?
																	<Fragment><NavDropdown.Item href="/account">My Account</NavDropdown.Item>
																	<NavDropdown.Item onClick={ this.handleLogout }>Logout</NavDropdown.Item></Fragment> :
																	<NavDropdown.Item href="/login">Login / Register</NavDropdown.Item>
															}
														</NavDropdown>
													</Nav>
												</div>
												<div className="mobile-logo"><a href="/"><img className="img-fluid" src="/img/main/celebyou-logo03.png" alt="CelebYou" /></a></div>
												{/*<div className="d-inline-block mobileLoginHeader" key={ this.state.userDataIsLoaded }>
													<LoginHeader childProps={childProps} userData={ this.state.userData } />
												</div>*/}
											</Fragment>
										}

										<div className="nav-menu px-sm-2 px-lg-4">
											<div className="menu-item mainMenuChannels">
												<NavDropdown title={ dropdownIcon } className="btn">
													<NavDropdown.Item href="/channels">Current Contest</NavDropdown.Item>
													{/*<NavDropdown.Item href="#">Past Contests</NavDropdown.Item>*/}
												</NavDropdown>
											</div>
											<div className="menu-item">
												<a className="btn" href="/genres">Genres</a>
											</div>
											<div className="menu-item">
												<a className="btn" href="/artists">Artists</a>
											</div>
											<div className="menu-item">
												<OverlayTrigger placement="right" overlay={ jtsTooltip }>
													<a className="btn" href="#">Trivia</a>
												</OverlayTrigger>
											</div>
											{/*<div className="menu-item">
												<a className="btn" href="/contest/winners">Latest Winner</a>
											</div>*/}
											<div className="menu-item">
												<a className="btn" href="/about">About CelebYou</a>
											</div>
											<div className="menu-item">
											{
												childProps.isAuthenticated ?
													<a className="btn" href="/account">My Account</a> :
													<a className="btn" href="/login">Signup</a>
											}
											</div>
										</div>

									</div>
								</Col>
								<Col sm={12} md={10} className="mainContentCol">
									<div className="mainLoginHeader" key={ this.state.userData }>
										<LoginHeader childProps={childProps} userData={ this.state.userData } />
									</div>
									<Routes childProps={childProps} />
								</Col>
							</Row>
						</Container>
					</div>

					<Modal size="lg" show={this.state.showProgress} onHide={this.handleCloseProgress}>
						<Modal.Header style={{backgroundColor: '#222'}}>
						</Modal.Header>
						<Modal.Body>
							<div className="row justify-content-center align-items-center">
									<img className="img-fluid w-75" src="/img/progress-received-items-header.png" alt="" />
							</div>
							<div className="d-flex justify-content-center pt-3">
									<div className="p-2">
										<h2><b>Welcome Back</b></h2>
									</div>
							</div>
							<div className="d-flex justify-content-center">
								<div className="p-2">
										<h4>While you were away you received <span id="totalItems" className="highlight">{this.state.notifications.length}</span> item(s)</h4>
								</div>
							</div>
							{/*<div className="d-flex justify-content-center">
								<div className="p-2">
									<img id="imgGifts" className="img-fluid w-75" src="/img/progress-gifts.png" alt="" />
									<div id="totalGifts" className="top-right"></div>
								</div>
								<div className="p-2">
									<img id="imgComments" className="img-fluid w-75" src="/img/progress-comments.png" alt="" />
									<div id="totalComments" className="top-right"></div>
								</div>
								<div className="p-2">
									<img id="imgLikesLoves" className="img-fluid w-75" src="/img/progress-likes-loves.png" alt="" />
									<div id="totalLikesLoves" className="top-right"></div>
								</div>
							</div>*/}
							<div className="d-flex justify-content-center">
								<div className="p-2">
									{this.state.notifications.map((notification, notification_key) => {
										return (
											<p key={notification_key}>{ notification.message }</p>
										)
									})}
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleCloseProgress}>
								Close
							</Button>
							<Button variant="primary" onClick={this.handleShowProgress}>
								GO TO PROFILE
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			)
		);
	}
}

export default withOAuth(App);
