import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
	HelpBlock,
	FormGroup,
	FormControl,
	FormLabel,
	Row,
	Col,
	Container
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

export default class ResetPassword extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			code: "",
			email: "",
			password: "",
			codeSent: false,
			confirmed: false,
			confirmPassword: "",
			isConfirming: false,
			isSendingCode: false,
			newPassword: "",
			mode: props.mode,
			cognitoUser: props.cognitoUser
		};
	}
	
	
	validateCodeForm() {
		//TODO??? : regex 
		return this.state.email.length > 0;
	}

	
	validateResetForm() {
		var passValid = this.state.password.length > 0 && this.state.password === this.state.confirmPassword;
		
		// for reset password a code is needed, but not for new account password
		if(this.state.mode === "reset")
			passValid = passValid && this.state.code.length > 0;

		return passValid;
	}

	
	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	
	handleSendCodeClick = async event => {
		event.preventDefault();

		this.setState({ isSendingCode: true });

		try {
			await Auth.forgotPassword(this.state.email);
			this.setState({ codeSent: true });
		} catch (e) {
			alert(e.message);
			this.setState({ isSendingCode: false });
		}
	};


	handleNewConfirmClick =	 async event => {
		event.preventDefault();

		try {
			if(this.state.cognitoUser) {
				
				Auth.completeNewPassword(
					this.state.cognitoUser,
					this.state.password
				).then(user => { 
					this.setState({ confirmed: true });
				}).catch(error => {
					console.log(error);
				});
			} else {
				console.log("no user selected");
			}
			
		} catch (e) {
			//alert("handleNewConfirmClick");
			alert(e.message);
		}
	};
	
	
	handleConfirmClick = async event => {
		event.preventDefault();

		this.setState({ isConfirming: true });

		try {
			await Auth.forgotPasswordSubmit(
				this.state.email,
				this.state.code,
				this.state.password
			);
			this.setState({ confirmed: true });
		} catch (e) {
			//alert("handleConfirmClick");
			alert(e.message);
			this.setState({ isConfirming: false });
		}
	};

	
	renderRequestCodeForm() {
		return (
			<Container>
				<Row className="my-4">
					<Col><h2>Reset password</h2></Col>
				</Row>
				<Row>
					<Col>
						<form onSubmit={this.handleSendCodeClick}>
							<FormGroup controlId="email">
								<FormLabel>Email</FormLabel>
								<FormControl
									autoFocus
									type="email"
									value={this.state.email}
									onChange={this.handleChange}
								/>
							</FormGroup>
							<LoaderButton
								block
								type="submit"
								loadingText="Sending…"
								text="Send Confirmation"
								className="my-3 py-2"
								isLoading={this.state.isSendingCode}
								disabled={!this.validateCodeForm()}
							/>
						</form>
					</Col>
				</Row>
			</Container>
		);
	}

	
	renderConfirmationForm() {
		return (
			<Container className="mt-4">
				{
					(this.state.mode === "new") ? (
						<Fragment>
							<Row className="my-2">
								<Col><h2>Create new password</h2></Col>
							</Row>
							<Row className="my-1">
								<Col className="text-muted py-2">
									<span>Please choose a new password. This step is required to avoid losing access to your account.</span>
								</Col>
							</Row>
						</Fragment>
					) : ( "" )
				}
				<Row>
					<Col>
						<form onSubmit={ (this.state.mode === "reset") ? this.handleConfirmClick : this.handleNewConfirmClick }>
							{
								(this.state.mode === "reset") ? (
									<Fragment>
										<FormGroup controlId="code">
											<FormLabel>Confirmation Code</FormLabel>
											<FormControl
												autoFocus
												type="tel"
												value={this.state.code}
												onChange={this.handleChange}
											/>
											<span className="text-muted">
												Please check your email ({this.state.email}) for the confirmation
												code.
											</span>
										</FormGroup>
										<hr />
									</Fragment>
								) : (
									""
								)
							}
							<FormGroup controlId="password" className="mt-2">
								<FormLabel>New Password</FormLabel>
								<FormControl
									type="password"
									value={this.state.password}
									onChange={this.handleChange}
								/>
							</FormGroup>
							<FormGroup controlId="confirmPassword">
								<FormLabel>Confirm Password</FormLabel>
								<FormControl
									type="password"
									onChange={this.handleChange}
									value={this.state.confirmPassword}
								/>
							</FormGroup>
							<LoaderButton
								block
								type="submit"
								text="Confirm"
								loadingText="Confirm…"
								className="my-3 py-2"
								isLoading={this.state.isConfirming}
								disabled={!this.validateResetForm()}
							/>
						</form>
					</Col>
				</Row>
			</Container>
		);
	}

	
	renderSuccessMessage() {
		return (
			<div className="success">
				<p>Your password has been reset.</p>
				<p>
					<Link to="/login">
						Click here to login with your new credentials.
					</Link>
				</p>
			</div>
		);
	}

	
	render() {
		return (
			<Container>
				<Row>
					<Col sm={1}></Col>
					{
						this.state.mode === "reset" ? (
							<Col sm={10}>
								<div>
								{
									!this.state.codeSent
										? this.renderRequestCodeForm()
										: !this.state.confirmed
											? this.renderConfirmationForm()
											: this.renderSuccessMessage()
								}
								</div>
							</Col>
						) : this.state.mode === "new" ? (
							<Col sm={10}>
								<div>		
								{					
									!this.state.confirmed ?
										this.renderConfirmationForm()
									: this.renderSuccessMessage()
								}
								</div>
							</Col>
						) : ""
					}
				</Row>
			</Container>
		);
	}
}
