import React, { Component } from 'react';
import styled from 'styled-components';

// inline stylesheet
const Styles = styled.div`
	html, body {
		height: 100%;
	}
	.crowd-bg {
		width: 100%;
		// background: #fff url('/img/bg-crowd.jpg') no-repeat right bottom;
	}
	.container {
		max-width: 100%;
	}
	.content {
		width: 70%;
		margin: auto;
	}
	p {
		font-size: 14px;
		padding-top: 20px;
	}
	@media only screen and (max-width: 768px) {
		/* For mobile phones: */
		.content {
			width: 100%;
			text-align: center;
		}
		p {
			text-align: left;
		}
		[class*="col-"] {
			width: 100%;
		}
		img { 
			width: 90%;
		}
	}
	.center { text-align: center; }
`;

// main renderer
class ContestWinners extends Component {
	render() {
		return (
			<Styles>
				<div className="container crowd-bg">
					<div className="content">
						<div className="row pt-5">
							<h1 className="display-3 artistsHeader mt-5 ml-3 d-inline-block">CONTEST WINNERS</h1>
						</div>
						<div className="row">			
							<h1 className="center pt-4"><b>Bryce Nicholas <span className="red">August 2018 Winner</span></b></h1>
						</div>
						<div className="row">
							<p className="center pt-4">Bryce Nicholas was selected as the winner of our contest that ended in August 2018. His winning contest entry is below.</p>
						</div>
						<div className="row">
							<iframe title="Bryce Nicholas" className="center pt-4" src="https://www.youtube.com/embed/iN9UaQ9rGoo" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={562} frameBorder={0} />
						</div>
						<div className="row">			
							<h1 className="center pt-5"><b>Alicia Moore <span className="red">February 2018 Winner</span></b></h1>
						</div>
						<div className="row">
							<p className="center pt-4">Alicia Moore was selected as the winner of our contest that ended in February 2018. Alicia is from Virginia Beach, VA and her winning contest entry is below.</p>
						</div>
						<div className="row">
							<iframe title="Alicia Moore" className="center pt-4" src="https://www.youtube.com/embed/yeCXEc95ZfU" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={562} frameBorder={0} />
						</div>
						<div className="row pt-5">
							<img className="img-fluid center" src="/img/contest-winners-2018-top5.png" alt="" />
						</div>
						<div className="row pt-3">
							<div className="col-md-2 col-sm-12" />
							<div className="col-md-2 col-sm-12 text-center">
								<iframe title="Brenda Lucas" className="center pt-5" src="https://www.youtube.com/embed/JWeV15gnwSk" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={150} frameBorder={0} />
								<p><b>Brenda Lucas</b></p>
							</div>
							<div className="col-md-2 col-sm-12 text-center">
								<iframe title="Lady Montalvo" className="center pt-5" src="https://www.youtube.com/embed/DV2mFFMeXrs" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={150} frameBorder={0} />
								<p><b>Lady Montalvo</b></p>
							</div>
							<div className="col-md-2 col-sm-12 text-center">
								<iframe title="Andre Mayberry" className="center pt-5" src="https://www.youtube.com/embed/Bmrboc9If_c" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={150} frameBorder={0} />
								<p><b>Andre Mayberry</b></p>
							</div>
							<div className="col-md-2 col-sm-12 text-center">
								<iframe title="Virgo Star" className="center pt-5" src="https://www.youtube.com/embed/TqYx58dy_5g" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={150} frameBorder={0} />
								<p><b>Virgo Star</b></p>
							</div>
							<div className="col-md-2 col-sm-12" />
						</div>
					</div>
				</div>


			</Styles>
		)
	}
}

export default ContestWinners;