import React, { Component, Fragment } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip, Dropdown, DropdownButton
	, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faHeart, faGift, faChevronRight, faPlay, faComment } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { Auth } from "aws-amplify";
import config from '../config.js';
import VideoCarousel from './VideoCarousel';
import './Channels.css';
import './SingleGenre.css';

// delay function for resolving toast promises
const delay = t => new Promise(resolve => setTimeout(resolve, t));

export default class SingleGenre extends Component {

	constructor(props) {
		super(props);

		this.parseGenreData = this.parseGenreData.bind(this);
		this.getCurrentVideo = this.getCurrentVideo.bind(this);
		this.showVideoCallback = this.showVideoCallback.bind(this);
		this.sortByLikeCount = this.sortByLikeCount.bind(this);
		this.sortByLoveCount = this.sortByLoveCount.bind(this);
		this.sortByName = this.sortByName.bind(this);
		this.clearToast = this.clearToast.bind(this);
		this.getCurrentGenre = this.getCurrentGenre.bind(this);

		this.state = {
			isLoading: true,
			showVideo: false,
			genreId: props.match.params.id,
			genre: { id: props.match.params.id, name: props.match.params.name },
			sortType: "alphabetical",
			selectedBoxKey: null,
			toastMsg: "",
			toastTime: 5000,
			toastSuccess: null,
			currentPage: 1,
			pageSize: 15,
			videosOnPage: [],
			pagesLoaded: [],
			showViewMoreButton: true,
			showGifts: false,
			artists: [],
			gifts: []
		}
	}

	componentDidMount() {
		// get genre data for all genres
		Auth.currentAuthenticatedUser()
			.then(response => {
				this.setState({ isAuthenticated: true });
				var jwtToken = response.signInUserSession.idToken.jwtToken;
				this.setState({ jwtToken: jwtToken });
				this.loadGenres();
			})
			.catch(error => {
				console.log("Auth.currentAuthenticatedUser error");
				console.log(error);
				this.getGenrePage(1);
			})
	}

	// TODO combine the following 2 functions
	getGenrePageAuth(page) {
		//console.log("getting page " + page);
		if(!this.state.pagesLoaded.includes(page))
			axios.get(config.apiGateway.URL + `/videos/genre/${this.state.genreId}?page=${page}&size=${this.state.pageSize}`, { headers: {Authorization: this.state.jwtToken} })
				.then(result => {
					//console.log("GET auth /videos/genres/ success");
					//console.log(result.data.data);
					let tempLoaded = this.state.pagesLoaded;
					tempLoaded.push(page);
					this.parseGenreData(result.data.data);
					this.setState({ currentPage: page, pagesLoaded: tempLoaded });
				})
				.catch(error => {
					console.log("GET auth /videos/genres/ error");
					console.log(error);
				})
	}

	getGenrePage(page) {
		//console.log("getting page " + page);
		if(!this.state.pagesLoaded.includes(page)) {
			this.setState({ isLoading: true });
			axios.get(config.apiGateway.URL + `/videos/genre/${this.state.genreId}?page=${page}&size=${this.state.pageSize}`)
				.then(result => {
					//console.log("GET /videos/genres/ success");
					//console.log(result.data.data);
					let tempLoaded = this.state.pagesLoaded;
					tempLoaded.push(page);
					this.parseGenreData(result.data.data);
					this.setState({ currentPage: page, pagesLoaded: tempLoaded, isLoading: false });
				})
				.catch(error => {
					console.log("GET /videos/genres/ error");
					console.log(error);
				})
		}
	}

	parseGenreData(genreVideos) {
		//console.log("parsing");
		//console.log(genreVideos);
		var tempVids = this.state.videosOnPage;
		var tempVids = tempVids.concat(genreVideos);
		this.setState({ videosOnPage: tempVids, isLoading: false });
	}

	handleCloseGifts() {
		this.setState({ showGifts: false });
	}

	async handleShowGifts() {
		this.setState({ disableSend: true });

		await this.getArtists();
		await this.getGifts();
		await this.getMe();

		this.setState({ disableSend: false, showGifts: true });
	}

	showVideoCallback(show) {
		this.setState({ showVideo: show });
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	getCurrentGenre() {
		return this.state.genre.id;
	}

	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);
		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}
	
	clearToast() {
		
	}

	getToastClass() {
		return (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden"
	}

	loadGenres() {
		this.getGenrePageAuth(1);
		/*axios.get(config.apiGateway.URL+'/genres/current', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /genres/current success");
				console.log(result.data.data);

				this.parseGenreData(result.data.data);
				this.getComments();
			})
			.catch(error => {
				console.log("GET /genres/current error");
				console.log(error);
			})*/
	}

	sortTypeToString(type) {
		switch(type) {
			case "alphabetical":
				return "Alphabetical";
			case "lovecount":
				return "Love Count";
			case "likecount":
				return "Like Count";
		}
	}

	setActiveVideoBox(key) {
		if(key !== this.state.selectedBoxKey)
			this.setState({ selectedBoxKey: key, ...(this.state.videos && { currentVideo: this.state.videos[key] }) });
	}

	clearActiveVideoBox() {
		//console.log("clearing");
		//this.setState({ selectedBoxKey: null });
	}

	getCurrentVideo() {
		return this.state.currentVideo;
	}

	sortByLikeCount(a, b) {
		return this.sortByCount(a, b, "like_count");
	}

	sortByLoveCount(a, b) {
		return this.sortByCount(a, b, "love_count");
	}

	sortByCount(a, b, label) {
		var result = parseInt(b[label]) - parseInt(a[label]);
		return result / Math.abs(result);
	}

	sortByName(a, b) {
		var result = a.title.toLowerCase().localeCompare(b.title.toLowerCase());
		if(result === 0)
			return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
		else
			return result;
	}

	sortVideos(type) {
		this.setState({ isLoading: true });
		//console.log("sort " + type);
		const toSort = this.state.videosOnPage;
		if(type === "alphabetical") {
			//console.log("alpha sort");
			toSort.sort(this.sortByName);
		} else if(type === "likecount") {
			toSort.sort(this.sortByLikeCount);
		} else if(type === "lovecount") {
			toSort.sort(this.sortByLoveCount);
		}
		this.setState({ videosOnPage: toSort, sortType: type, isLoading: false });
	}

	redirectCallback() {
		
	}

	render() {
		let videoCarouselProps = {
			mode: "tile",
			videos: this.state.videosOnPage,
			redirectCallback: this.redirectCallback,
			redirectId: this.state.genreId,
			isAuthenticated: this.state.isAuthenticated,
			jwtToken: this.state.jwtToken,
			...(this.state.selectedVideo && {selectedVideo: this.state.selectedVideo }),
			...(this.state.selectedArtist && {selectedArtist: this.state.selectedArtist })
		};
		//console.log(videoCarouselProps);
		return (
			<>{
				this.state.isLoading ?
					<div className="loading"></div>
				:
				<div className="genre-background">
					<Row className="pl-lg-5 pl-sm-2">
						<Col sm={8} className="mt-md-5 mt-sm-2 pt-5">
							<h1 className="genresHeader mt-md-5 mt-sm-2 ml-md-5 ml-sm-4 px-4 d-inline-block">
								{ this.state.genre ? this.state.genre.name : "" }
							</h1>
						</Col>
						<Col sm={4}>
							<div className="genreSortingControlBar m-3">
								<span className="mr-4">Sorted By</span>
								<DropdownButton className="sortDropdown d-inline" title={ this.sortTypeToString(this.state.sortType) }>
									<Dropdown.Item onClick={ () => { this.sortVideos("alphabetical") } }>Alphabetical</Dropdown.Item>
									<Dropdown.Item onClick={ () => { this.sortVideos("lovecount") } }>Love Count</Dropdown.Item>
									<Dropdown.Item onClick={ () => { this.sortVideos("likecount") } }>Like Count</Dropdown.Item>
								</DropdownButton>
							</div>
						</Col>
					</Row>
					<div className="row pl-lg-4 pl-sm-2 pt-3 pr-1 contentWidthConstraint">
						<div className="col pb-5">
							<div id="toast" className={ "toastStyle " + this.getToastClass() }>{ this.state.toastMsg }</div>
							<VideoCarousel {...videoCarouselProps} key={ this.state.videosOnPage.length } />
							{
								this.state.genre && this.state.showViewMoreButton ?
									<div className="genreViewMoreBacking mt-4">
									{
										!this.state.isLoading ?
											<div className="genreViewMoreButton" onClick={ () => this.getGenrePage(this.state.currentPage + 1) }>
												VIEW MORE
											</div>
										: <div className="loading"></div>
									}
									</div>
								: ""
							}
						</div>
					</div>
					{
						this.props.footer
					}
				</div>
			}</>
		);
	}
}
