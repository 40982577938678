export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "celebyou-user-images",
        AWS_ACCESS_KEY_ID: "AKIAIJDIOJ5OZCAYXVMA",
        AWS_SECRET_ACCESS_KEY: "PO2CrNDONiJdYxZhDRGMaKTGiI+jLDj7sz7uWS9/"
    },
    apiGateway: {
        REGION: "YOUR_API_GATEWAY_REGION",
        URL: "https://staging.celebyou.info"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_RfKPGkT30",
        APP_CLIENT_ID: "6sj4ji2rsrphmfvces5ovogvve",
        IDENTITY_POOL_ID: "us-east-1:2250f9d9-591a-4a6d-a96c-f0bd3caf9f52",
        DOMAIN: "https://celebyou-dev.auth.us-east-1.amazoncognito.com"
    },
    social: {
        //FB: "1159477044203805"
        FB: "374219260112350"
    }
};
