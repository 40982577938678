import React, { Component } from "react";
import {
    FormGroup,
    FormControl,
    FormLabel,
    Row,
    Col,
	Tooltip,
	OverlayTrigger
} from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import "./Register.css";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import config from '../config';

// import FacebookButton from "./FacebookButton";

import PhoneInput from 'react-phone-number-input'
require('react-phone-number-input/style.css');

// this global is to get around infinite update loops that occur from using state to track field validation error messages
var formErrors = {};

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            username: "",
            firstname: "",
            lastname: "",
            email: props.email,
            phone: "",
            password: "",
            confirmPassword: "",
            confirmationCode: "",
            birthMonth: "",
            birthDay: "",
            birthYear: "",
			expandDob: false,
            newUser: null,
			errors: {},
			formErrorCount: 0,
			userDataCallback: props.userDataCallback,
			toastTime: 5000,
			toastMsg: ""
        };
    }
	
    validateForm() {
		// short-circuit the expensive regex calls if no data is entered yet by testing length > 0
        var registrationIsValid = (
            this.state.username.length > 0 &&
            this.state.firstname.length > 0 &&
            this.state.lastname.length > 0 &&
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
			this.state.confirmPassword.length > 0 &&
            this.state.birthMonth.length > 0 &&
            this.state.birthDay.length > 0 &&
            this.state.birthYear.length > 0
			//(this.state.phone && this.state.phone.length) > 0 // OPTIONAL
        );
		
		if(!registrationIsValid) {
			this.addError('registration', "Please complete all fields");
			this.setState({ formErrorCount: this.state.formErrorCount + 1 });
			return registrationIsValid;
		}
		
		registrationIsValid = registrationIsValid && (
            this.validateUsername(this.state.username) &&
            this.validateName("first", this.state.firstname) &&
            this.validateName("last", this.state.lastname) &&
            this.validateEmail(this.state.email) &&
            this.validatePassword(this.state.password) &&
			this.validateConfirmPassword(this.state.confirmPassword) &&
            this.validateBirthMonth(this.state.birthMonth) &&
            this.validateBirthDay(this.state.birthDay) &&
            this.validateBirthYear(this.state.birthYear)
        );
		
		if(!registrationIsValid) {
			this.addError('registration', "Please fix the errors listed below");
			this.setState({ formErrorCount: this.state.formErrorCount + 1 });
		}
		
		return registrationIsValid;
    }

    validateConfirmationForm() {
        return this.state.confirmationCode.length > 0;
    }

	getUserData(callback) {
		console.log("getting user data");
		Auth.currentAuthenticatedUser()
		.then(response => {
			var jwtToken = response.signInUserSession.idToken.jwtToken;
			this.setState({ 'jwtToken': jwtToken });

			axios.get(config.apiGateway.URL + '/users/me', { headers: { Authorization: jwtToken } })
				.then(result => {
					console.log("GET /users/me success REGISTER");
					//alert("/me reg");
					console.log(result.data.data)
					callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /users/me error");
					console.log(error);
				})
		})
	}

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

		if(!this.validateForm())
			return;
		
        this.setState({ isLoading: true });

        try {
            const {username, password, email, firstname, lastname, birthMonth, birthDay, birthYear, phone} = this.state;
            const newUser = await Auth.signUp({
                username: username,
                password: password,
                attributes: {
                    'email': email,
                    'phone_number': phone,
                    'given_name': firstname,  // custom attribute, not standard
                    'family_name': lastname,  // custom attribute, not standard
                    'birthdate': birthYear + '-' + birthMonth + '-' + birthDay    // custom attribute, not standard
                }
            });
			formErrors = {};
            this.setState({ newUser, formErrorCount: 0 });
        } catch (e) {
			this.makeToast(e.message, false)
            //alert(e.message);
        }

        this.setState({ isLoading: false });
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.confirmSignUp(this.state.username, this.state.confirmationCode);
            //await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
            await Auth.signIn(this.state.email, this.state.password);
            this.props.userHasAuthenticated(true);
			this.getUserData(this.state.userDataCallback);
        } catch (e) {
            //alert(e.message);

			if(e.message.slice(-25) === "key 'users_email_unique'.") {
				this.makeToast("An account already exists for that email address", false)
			} else
				this.makeToast(e.message, false)
		
            this.setState({ isLoading: false });
        }
    }

    handleFbLogin = () => {
        this.props.userHasAuthenticated(true);
    };

	/* register form input validation */
	validateEmail(email) {
		var valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
		if(!valid && email.length > 0)
			this.addError('email', "Please enter a valid email address");
		else
			this.deleteError('email');
		return valid;
	}
	
	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}
	
	getToastClass() {
		let toastClass = (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden";
		
		return toastClass;
	}
	
	/*addError(key, msg) {
		//const temp = this.state.errors;
		//temp[key] = msg;
		console.log("adding err");
		console.log(this.state.errors);
		this.state.errors[key] = msg;
		console.log(this.state.errors);
		//this.setState({ errors: temp });
	}*/
	
	addError(key, msg) {
		//const temp = this.state.errors;
		//temp[key] = msg;
		//console.log("adding err");
		//console.log(this.state.errors);
		//this.state.errors[key] = msg;
		//console.log(this.state.errors);
		//this.setState({ errors: temp });
		formErrors[key] = msg;
		//console.log(formErrors);
	}
	
	/*deleteError(key) {
		if(key in this.state.errors)
			delete this.state.errors[key];
	}*/
	deleteError(key) {
		if(key in formErrors)
			delete formErrors[key];
	}
	
	/*
		https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
	*/
	validateUsername(username) {
		var valid = /^[\S]+$/.test(username) && username.length <= 128;
		if(!valid && username.length > 0)
			this.addError('username', "Username cannot have spaces");
		else
			this.deleteError('username');
		return valid;
	}
	
	/*
		https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AttributeType.html#CognitoUserPools-Type-AttributeType-Name
	*/
	validateName(type, name) {
		var valid = /^[\S]+$/.test(name) && name.length <= 32;
		if(!valid && name.length > 0)
			this.addError(type + 'name', type.charAt(0).toUpperCase() + type.slice(1) + " name cannot have spaces");
		else
			this.deleteError(type + 'name');		
		return valid;
	}
	
	validateBirthMonth(month) {
		var intMonth = parseInt(month);
		var valid = /^\d{2}$/.test(month) && intMonth > 0 && intMonth <= 12;
		if(!valid && month.length > 0)
			this.addError('birthmonth', "Please enter two digits for the month (01 to 12)");
		else
			this.deleteError('birthmonth');
		return valid;
	}
	
	validateBirthDay(day) {
		var intDay = parseInt(day);
		var valid = /^\d{2}$/.test(day) && intDay > 0 && intDay <= 31;
		if(!valid && day.length > 0)
			this.addError('birthday', "Please enter two digits for the day (01 to 31)");
		else
			this.deleteError('birthday');
		return valid;
	}
	
	/* maximum age for validity calculated as 1 standard deviation above highest recorded age */
	validateBirthYear(year) {
		var intYear = parseInt(year);
		var minAge = 13;
		var maxAge = 125;
		var currentDate = new Date();
		var valid = /^\d{4}$/.test(year);
		
		if(currentDate.getFullYear() - minAge < parseInt(this.state.birthYear) ||
				(currentDate.getFullYear() - minAge === parseInt(this.state.birthYear)
					&& currentDate.getMonth() + 1 <= parseInt(this.state.birthMonth)
					&& currentDate.getDate() < parseInt(this.state.birthDay) )) {
			valid = false;
			this.addError('birthyear', "You must be at least 13 years of age to signup");
		} else if(!valid && year.length > 0) {
			this.addError('birthyear', "Please enter four digits for the year, eg. 1989)");
		} else {
			this.deleteError('birthyear');
		}

		return valid;
	}
	
	validatePassword(password) {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(password);
	}
	
	validateConfirmPassword(confirmPassword) {
		var valid = (confirmPassword === this.state.password);
		if(!valid)
			this.addError('confirmPassword', "Password does not match");
		return confirmPassword.length > 0 && valid;
	}
	
    renderConfirmationForm() {
        return (
            <form onSubmit={this.handleConfirmationSubmit}>
                <FormGroup controlId="confirmationCode">
                    <FormLabel>Confirmation Code</FormLabel>
                    <FormControl
                        type="tel"
                        value={this.state.confirmationCode}
                        onChange={this.handleChange}
                    />
                    <small className="form-text text-muted">Please check your email for the code.</small>
                </FormGroup>
                <LoaderButton
                    block
                    size="lg"
                    disabled={!this.validateConfirmationForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Verify"
                    loadingText="Verifying…"
                />
            </form>
        );
    }

    renderForm() {
        return (
            <form onSubmit={ this.handleSubmit } autoComplete="off" className="registerForm">
				<OverlayTrigger placement="auto-start" trigger="focus"
						overlay={<Tooltip className="validationTooltip"
									style={ { 'display':  (this.state.username.length === 0 || this.validateUsername(this.state.username)) ? 'none' : 'inline' } }>
									{ formErrors['username'] || "Missing field" }
								</Tooltip> }>
					<FormGroup>
						<FormControl
							autoFocus
							type="text"
							value={this.state.username}
							onChange={this.handleChange}
							id="username"
							placeholder="Username*"
							isValid={ this.validateUsername(this.state.username) }
							isInvalid={ this.state.username.length !== 0 && !this.validateUsername(this.state.username) }
						/>
						<FontAwesomeIcon icon={ faUser } className="inputIcon registerInputIcon"/>
					</FormGroup>
				</OverlayTrigger>
				<OverlayTrigger placement="auto-start" trigger="focus"
						overlay={<Tooltip className="validationTooltip"
									style={ { 'display': (this.state.email.length === 0 || this.validateEmail(this.state.email)) ? 'none' : 'inline' } }>
									{ formErrors['email'] || "Missing field" }
								</Tooltip> }>
					<FormGroup>
						<FormControl
							type="text"
							value={this.state.email}
							onChange={this.handleChange}
							id="email"
							placeholder="Email*"
							isValid={ this.validateEmail(this.state.email) }
							isInvalid={ this.state.email.length !== 0 && !this.validateEmail(this.state.email) }
						/>
						<FontAwesomeIcon icon={ faEnvelope } className="inputIcon registerInputIcon"/>
					</FormGroup>
				</OverlayTrigger>				
				<FormGroup className="m-0">
					<Row>
						<OverlayTrigger placement="auto-start" trigger="focus"
								overlay={<Tooltip className="validationTooltip"
											style={ { 'display':  (this.state.firstname.length === 0 || this.validateName('first', this.state.firstname)) ? 'none' : 'inline' } }>
											{ formErrors['firstname'] || "Missing field" }
										</Tooltip> }>					
							<Col sm={6} className="mb-3">
								<FormControl
									type="text"
									value={this.state.firstname}
									onChange={this.handleChange}
									id="firstname"
									placeholder="First Name*"
									isValid={ this.validateName('first', this.state.firstname) }
									isInvalid={ this.state.firstname.length !== 0 && !this.validateName('first', this.state.firstname) }
								/>
							</Col>
						</OverlayTrigger>
						<OverlayTrigger placement="auto-start" trigger="focus"
								overlay={<Tooltip className="validationTooltip"
											style={ { 'display':  (this.state.lastname.length === 0 || this.validateName('last', this.state.lastname)) ? 'none' : 'inline' } }>
											{ formErrors['lastname'] || "Missing field" }
										</Tooltip> }>					
							<Col sm={6} className="mb-3">
								<FormControl
									type="text"
									value={this.state.lastname}
									onChange={this.handleChange}
									id="lastname"
									placeholder="Last Name*"
									isValid={ this.validateName('last', this.state.lastname) }
									isInvalid={ this.state.lastname.length !== 0 && !this.validateName('last', this.state.lastname) }
								/>
							</Col>
						</OverlayTrigger>
					</Row>
				</FormGroup>
				<OverlayTrigger placement="auto-start" trigger="focus"
						overlay={<Tooltip className="validationTooltip"
									style={ { 'display':  (this.state.password.length === 0 || this.validatePassword(this.state.password)) ? 'none' : 'inline' } }>
									{ formErrors['password'] || "Password must contain at least 8 characters: a minimum of 1 UPPER CASE, 1 lower case, 1 number, and 1 symbol" }
								</Tooltip> }>
					<FormGroup>
						<FormControl
							value={this.state.password}
							onChange={this.handleChange}
							type="password"
							id="password"
							placeholder="Password*"
							isValid={ this.validatePassword(this.state.password) }
							isInvalid={ this.state.password.length !== 0 && !this.validatePassword(this.state.password) }
						/>
						<FontAwesomeIcon icon={ faLock } className="inputIcon registerInputIcon"/>					
					</FormGroup>
				</OverlayTrigger>
				<OverlayTrigger placement="auto-start" trigger="focus"
						overlay={<Tooltip className="validationTooltip"
									style={ { 'display':  (this.state.confirmPassword.length === 0 || this.validatePassword(this.state.confirmPassword)) ? 'none' : 'inline' } }>
									{ formErrors['confirmPassword'] || "Password must contain at least 8 characters: a minimum of 1 UPPER CASE, 1 lower case, 1 number, and 1 symbol" }
								</Tooltip> }>				
					<FormGroup>
						<FormControl
							value={this.state.confirmPassword}
							onChange={this.handleChange}
							type="password"
							id="confirmPassword"
							placeholder="Confirm Password*"
							isValid={ this.validateConfirmPassword(this.state.confirmPassword) }
							isInvalid={ this.state.password.length !== 0 && !this.validateConfirmPassword(this.state.confirmPassword) }
						/>
						<FontAwesomeIcon icon={ faLock } className="inputIcon registerInputIcon"/>
					</FormGroup>
				</OverlayTrigger>
				<FormGroup className="m-0">	
				{
					(this.state.expandDob === true) ? (
						<>
						<Row>
							<Col className="text-center text-white mb-2 dobSubHeading">
								Date of Birth
							</Col>
						</Row>
						<Row>
							<OverlayTrigger placement="auto-start" trigger="focus"
								overlay={<Tooltip className="validationTooltip" style={ { 'display': this.validateBirthMonth(this.state.birthMonth) ? 'none' : 'inline' } }>
											{ formErrors['birthmonth'] || "Missing field" }</Tooltip> }>
								<Col sm={4} className="mb-3">
									<FormControl
										type="text"
										value={this.state.birthMonth}
										onChange={this.handleChange}
										placeholder="MM"
										id="birthMonth"
										maxLength="2"
										isValid={ this.validateBirthMonth(this.state.birthMonth) }
										isInvalid={ !this.validateBirthMonth(this.state.birthMonth) }
										autoFocus
									/>
								</Col>
							</OverlayTrigger>
							<OverlayTrigger placement="auto-start" trigger="focus"
								overlay={<Tooltip className="validationTooltip" style={ { 'display': this.validateBirthDay(this.state.birthDay) ? 'none' : 'inline' } }>
											{ formErrors['birthday'] || "Missing field" }</Tooltip> }>
								<Col sm={4} className="mb-3">
									<FormControl
										type="text"
										value={this.state.birthDay}
										onChange={this.handleChange}
										placeholder="DD"
										id="birthDay"
										maxLength="2"
										isValid={ this.validateBirthDay(this.state.birthDay) }
										isInvalid={ !this.validateBirthDay(this.state.birthDay) }
									/>
								</Col>
							</OverlayTrigger>
							<OverlayTrigger placement="auto-start" trigger="focus"
								overlay={<Tooltip className="validationTooltip" style={ { 'display': this.validateBirthYear(this.state.birthYear) ? 'none' : 'inline' } }>
											{ formErrors['birthyear'] || "Missing field" }</Tooltip> }>
								<Col sm={4} className="mb-3">
									<FormControl
										type="text"
										value={this.state.birthYear}
										onChange={this.handleChange}
										placeholder="YYYY"
										id="birthYear"
										maxLength="4"
										isValid={ this.validateBirthYear(this.state.birthYear) }
										isInvalid={ !this.validateBirthYear(this.state.birthYear) }
									/>
								</Col>	
							</OverlayTrigger>
						</Row>
						</>
					) : (
						<Row>
							<Col className="mb-3">
								<FormControl
									type="text"
									placeholder="Date of Birth*"
									onFocus={ () => { 
										this.setState({ expandDob: true });
									} }
								/>
							</Col>
						</Row>
					)
				}
				</FormGroup>
				<FormGroup controlId="phone">
					<Row>
						<Col>				
							<PhoneInput
								country="US"
								placeholder="Phone Number"
								value={ this.state.phone }
								className="phoneInput"
								onChange={ value => { this.setState({ phone: value }) } }
							/>
						</Col>
					</Row>
				</FormGroup>
				<Row>
					<Col sm={2}></Col>
					<Col sm={8}>
						<LoaderButton
							size="lg"
							type="submit"
							isLoading={ this.state.isLoading }
							disabled={ this.validateForm }
							text="COMPLETE ACCOUNT SETUP"
							loadingText="Signing up…"
							className="signupButton"
						/>	
					</Col>
				</Row>
            </form>
        );
    }

    render() {
		/*
			{
				Object.keys(this.state.errors).map(val => {
					return (
						<div className="validationErrorMessage text-warning">{ this.state.errors[val] }</div>
					);
				})
			}
		*/
		// individual field validation messages are inline
        return (
			<div className="Register" key={ this.state.formErrorCount }>
				<div id="toast" className={ this.getToastClass() + " loginToastStyle" }>
					{ this.state.toastMsg }</div>
				{
					('registration' in formErrors)
						? <div className="validationErrorMessage text-warning">{ formErrors['registration'] }</div>
						: ""
				}
				{
					this.state.newUser === null	? this.renderForm()
						: this.renderConfirmationForm()
				}
			</div>
        );
    }
}
