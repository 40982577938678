import React, { Component } from 'react';
// import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

// inline stylesheet
const Styles = styled.div`
	html, body {
		height: 100%;
	}
	.crowd-bg {
		width: 100%;
		background: #fff url('img/bg-crowd.jpg') no-repeat right bottom;
	}
	.container {
		max-width: 100%;
	}
	.content {
		width: 70%;
		margin: auto;
	}
	p {
		font-size: 14px;
		padding-top: 20px;
	}
	@media only screen and (max-width: 768px) {
		/* For mobile phones: */
		.content {
			width: 100%;
			text-align: center;
		}
		p {
			text-align: left;
		}
		[class*="col-"] {
			width: 100%;
		}
		img {
			width: 90%;
		}
	}
`;

// main renderer
class PrivacyPolicy extends Component {
	render() {
		return (
			<Styles>

				<div className="container">
					<div className="row content">
						<div className="col-md-12">
							<p className="text-center">
								<img className="img-fluid" src="/img/privacy-policy-header.png" alt="Privacy Policy" />
							</p>
							<p><strong>Privacy Statement </strong>CelebYou, LLC (“CelebYou”) is committed to protecting your privacy online. We are also committed to providing you with the very best experience we can on our website. In order to enhance your experience, we gather certain personal information about you that helps us customize our content to your tastes and preferences. Please read the following policy to understand how your personal information will be treated as you make full use of our website. This policy may change from time to time, so please check back periodically.</p>
							<p><strong>What information do we collect? </strong>When you enter the CelebYou Contest online, we collect several items of personal information, including your name, e-mail address, mailing address, and telephone number.  We do <u>not</u> collect your credit card or other payment information once a payment has been processed.  For your security, we never store credit card information on our servers.  Instead, we transmit credit card information directly to the third party credit card processor through secure connections.</p>
							<p><strong>What information do parties other than CelebYou collect? </strong>Some advertisers or websites that have links on our site, as well as third party websites you may utilize for uploading videos for our contest, may collect personally identifiable information about you when you access or utilize those links and/or websites.  CelebYou does not control the collection or use of such information, and the practices of those advertisers and third party websites are not covered by this Privacy Policy.</p>
							<p><strong>What are “cookies” and how do we use them? </strong>CelebYou uses “Cookies” to help you navigate our website, and to track and record your preferences and activity on our website in order to personalize and enhance your user experience.  Cookies are small bits of data that are sent to your browser and stored in your computer’s hard drive.  Small pieces of data may be sent from our website and stored on your computer by your browser as you access different portions of our website.  Each time you access our website, our server may deliver certain customized information to you based on the data stored in your cookie.If you do not wish to allow for the use of cookies, you can generally opt-out of providing this information by setting your browser to reject cookies.  However, please be aware that some areas of this website may not provide you with an acceptable user experience if you have disabled the use of cookies.</p>
							<p><strong>What do we do with the information we collect? </strong>CelebYou’s primary goal in collecting personal information is to provide you, the user, with a customized experience on our website.  We also collect personal information in order to notify the Grand Prize winner of the contest results.  The information we collect (including information contained in your cookie) may be used to provide such capabilities as personalization services and interactive communications.  We may also use the information to estimate the size of our audience and measure certain traffic patterns, to track the progress and number of entries in our contests, to notify our visitors about updates to our website, to contact you for marketing purposes, and/or to contact you on behalf of certain third parties and deliver targeted advertisements that may be of interest to you.  We may also occasionally contact you with information regarding special promotions or contests at CelebYou.</p>
							<p><strong>With whom do we share your information and for what purpose? </strong>CelebYou does not sell, rent, or trade your personal information with others.  However, when one or more of our business partners co-sponsor a promotion or contest, we may share some or all of the information collected in connection with such promotion or contest with the co-sponsor(s).  If you do not want your information to be shared, you will be able to choose not to allow the transfer by not using or signing up for that particular promotion or contest.In addition to the foregoing, if we have reason to suspect that a user has abused the CelebYou website in any way, including but not limited to possible copyright infringement, possible libel and slander, or possible credit card fraud, we reserve the right to share your personal information with third parties in such instances.</p>
							<p><strong>Changes to our Privacy Policy </strong>From time to time, we may change our Privacy Policy.  We will post those changes on this page so that our customers will always be aware of what information we collect, how we use it, and under what circumstances we share the information with others.</p>
						</div>
					</div>
				</div>

				<div className="container">
					{ this.props.footer }
				</div>
			</Styles>
		)
	}
}

export default PrivacyPolicy;