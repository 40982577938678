import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Auth } from "aws-amplify";
import $ from "jquery";
import config from '../config.js';
import ReactPlayer from 'react-player';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Channels.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChevronRight, faChevronLeft, faStar
	, faThumbsUp, faHeart, faGift, faComment, faPlay } from '@fortawesome/free-solid-svg-icons'
import VideoCarousel from './VideoCarousel';

const CustomRightArrow = ({ onClick, ...rest }) => {
  // onMove means if dragging or swiping in progress.
  return (
	<div onClick={() => onClick()} className="customCarouselArrow customRightArrow">
		<FontAwesomeIcon icon={ faChevronRight } />
	</div>
	);
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  // onMove means if dragging or swiping in progress.
  return (
	<div onClick={() => onClick()} className="customCarouselArrow customLeftArrow">
		<FontAwesomeIcon icon={ faChevronLeft } />
	</div>
	);
};

function timeBetweenDates(contestEndDate, contestId, timer) {

	var dateEntered = contestEndDate;
	var now = new Date();
	var difference = dateEntered.getTime() - now.getTime();

	if (difference <= 0) {
		// Timer done
		clearInterval(timer);
	} else {
		var seconds = Math.floor(difference / 1000);
		var minutes = Math.floor(seconds / 60);
		var hours = Math.floor(minutes / 60);
		var days = Math.floor(hours / 24);

		hours %= 24;
		minutes %= 60;
		seconds %= 60;

		$(".countdown-" + contestId).text(days.toString() + "d " + hours.toString().padStart(2, '0') + "h "
			+ minutes.toString().padStart(2, '0') + "m " + seconds.toString().padStart(2, '0') + "s");
	}
}

// main renderer
class ChannelsPage extends Component {

	constructor(props) {
		super(props)

		this.state = {
			contests: [],
			comments: [],
			artists: [],
			gifts: [],
			jwtToken: '',
			isAuthenticated: false,
			commentId: '',
			artistId: '',
			giftId: '',
			showGifts: false,
			disableSend: true,
			totalToken: 0,
			showVideo: false,
			currentVideo: [],
			currentContestId: null,
			isLoading: true,
			windowWidth: window.innerWidth,
			defaultPageSize: 10
		}
	};

	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	componentDidMount() {
		Auth.currentAuthenticatedUser()
			.then(response => {
				var jwtToken = response.signInUserSession.idToken.jwtToken;
				this.setState({ jwtToken: jwtToken, isAuthenticated: true });
				this.getMe();
				this.loadContestsAuth();
			})
			.catch(error => {
				console.log("Auth.currentAuthenticatedUser error");
				console.log(error);
				// without auth, loadContestsAuth() is for logged in users
				axios.get(config.apiGateway.URL+'/contests/current')
					.then(result => {
						console.log("GET /contests/current success");
						console.log(result.data.data);
						this.setState({ contests: result.data.data, isLoading: false });
						this.insertHardcodedThinEnergyAd();
					})
					.catch(error => {
						console.log("GET /contests/current error");
						console.log(error);
					})
			})
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	loadContestsAuth() {
		axios.get(config.apiGateway.URL+`/contests/current?page=1&size=${this.state.defaultPageSize}`
				, { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /contests/current success");
				console.log(result.data.data);
				this.setState({ contests: result.data.data, isLoading: false });
				this.insertHardcodedThinEnergyAd();
			})
			.catch(error => {
				console.log("GET /contests/current error");
				console.log(error);
			})
	}

	getArtists() {
		axios.get(config.apiGateway.URL+'/users/artists', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /users/artists success");
				console.log(result.data.data);
				this.setState({ artists: result.data.data });
			})
			.catch(error => {
				console.log("GET /users/artists error");
				console.log(error);
			})
	}

	getMe() {
		axios.get(config.apiGateway.URL+'/users/me', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /users/me success");
				console.log(result.data.data)
				this.setState({ totalToken: result.data.data.token });
			})
			.catch(error => {
				console.log("GET /users/me error");
				console.log(error);
			})
	}

/*
	getComments() {
		axios.get(config.apiGateway.URL+'/comments/canned', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /comments/canned success");
				console.log(result.data.data);
				this.setState({ comments: result.data.data });
			})
			.catch(error => {
				console.log("GET /comments/canned error");
				console.log(error);
			})
	}
	
	getGifts() {
		axios.get(config.apiGateway.URL+'/gifts', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /gifts success");
				console.log(result.data.data);
				this.setState({ gifts: result.data.data });
			})
			.catch(error => {
				console.log("GET /gifts error");
				console.log(error);
			})
	}
*/

	endsIn(contestEndDate, contestId) {
		var timer;

		var compareDate = new Date(contestEndDate);

		timer = setInterval(function() {
			timeBetweenDates(compareDate, contestId, timer);
		}, 0);
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	/*findContestFromId(id) {
		for(var i = 0; i < this.state.contests.length; i++) {
			if(parseInt(this.state.contests[i].id) === parseInt(id)) {
				console.log("contest " + id + " found");
				return i;
			}
		}
		return -1;
	}*/
	
	
	/* temporary ad-related code */
	insertHardcodedThinEnergyAd() {
		let url = "https://www.youtube.com/embed/8m2cGXraFAI";
		let title = "Earn 20 points for watching";
		let description = "Exclusive Brand brought to you by Multi Shot Nutrition&reg;, LLC.<br/>#TheHealthyAlternative&reg;<br/>\"Give It A ShotTM\" Exclusive Brand brought to you by Multi Shot Nutrition&reg;, LLC."; 
		let caption = "Try thin energy<br/> 5 day supply $9.99<br/> FREE SHIPPING";
		let subcaption = "Energy you need. Weight loss benefits you want!";
		let brand = "/img/ads/thin_energy/brand.png";
		let profile = "/img/ads/thin_energy/profile.png";
		let thumbnail = "https://img.youtube.com/vi/8m2cGXraFAI/sddefault.jpg";
		let pos = 0;
		this.insertAd(null, url, title, description, profile, thumbnail, brand, caption, subcaption, pos); 
	}
	
	/*
		Construct a video object for use with the buildVideoCarouselItem ad-specific render block.
		
		vidId: the ID of the video in the API
		title: ...
		description ...
		profileImg: the URI of the profile image for the ad owner, displayed in usual video profile img spot
		thumbnail: the URI of the thumbnail image of the ad
		caption: a secondary text label displayed above the thumbnail
		position: where to insert the ad in the sequence of videos (0 to N, if > N it will be placed at end)
	*/
	insertAd(vidId, videoUrl, title, description, profileImg
			, thumbnail, brand, caption, subcaption, position) {
		let videoItem = {
			type: "ad",
			videoUrl: videoUrl,
			id: vidId,
			title: title,
			description: description,
			profile_image: profileImg,
			thumbnail: thumbnail,
			brand_image: brand,
			caption: caption,
			subcaption: subcaption,
			position: position
		};
		
		let contests = this.state.contests;
		
		if(this.state.contests.length > 0) {
			let temp = this.state.contests[0].videos;
			
			if(position < 0) {
				console.log("Can't insert ad before index 0");
				return;
			} else if(position >= temp.length) {
				// add at end
				temp.push(videoItem);
				this.setState({ contests: contests });
			} else {
				// insert
				temp.splice(position, 0, videoItem);
				this.setState({ contests: contests });
			}
		}
	}
	/* end of temporary ad-related code */
	

	/* redirect to artist page on user avatar click */
	redirectToArtist(artist) {
		if(artist)
			window.location = `/artist/${artist}`;
		else
			console.log("no artist selected for redirect");
	}

	redirectCallback(contestId) {
		window.location = `/contest/${contestId}`;
	}

	render() {
		return (
			<div className="contest-background">
				<div className="container-fluid w-100 m-0 p-0">
					<div className="row h-100 pt-5">
						<div className="col-md-12 h-100">
							<div className="pl-md-5 pl-sm-2 pt-4">
								<h1 className="contestsHeader mt-md-5 ml-3 px-4 d-inline-block">CHANNELS</h1>
							</div>
						</div>
					</div>
					<div className="row p-0 m-0">
						<div className="col-md-12 p-0 m-0">
							{ 
								this.state.isLoading
								? <div className="loading"></div>
								: ""
							}

							{
								this.state.contests.map((contest, contest_key) => {
									
									let videoCarouselProps = {
										mode: "slider",
										videos: contest.videos,
										backButtonCallback: this.redirectToChannels,
										redirectCallback: this.redirectCallback,
										redirectId: contest.id,
										isAuthenticated: this.state.isAuthenticated,
										jwtToken: this.state.jwtToken,
										...(this.state.selectedVideo && {selectedVideo: this.state.selectedVideo }),
										...(this.state.selectedArtist && {selectedArtist: this.state.selectedArtist })
									};
									
									return (
										(contest.videos && contest.videos.length > 0) ?
											<Fragment key={ contest_key }>
												{/*<div className="container">
													<div className="row">
														<div className="col-md-12 pt-3 pb-2">
														</div>
														{/*<div className="col-md-5">
															<h4 className="section-date text-right px-2">Ends in <span className={"countdown-" + contest.id}></span></h4>
															{this.endsIn(contest.end_date, contest.id)}
														</div>
													</div>
												</div>*/}
												<div>
													<div className="container videoCarouselContainer">
														<div className="row">
															<div className="col-12 mb-3 p-0">
																<h3 className="section-title-channels ml-md-5 ml-sm-3 mt-0">{contest.name}{/*CURRENT CONTEST*/}</h3>
																	{/* 	key={ this.state.selectedVideo || this.state.selectedArtist } */}
																<VideoCarousel {...videoCarouselProps} />
															</div>
														</div>
													</div>
												</div>
											</Fragment>
										: ""
									)
								})
							}
							{
								this.props.footer
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ChannelsPage;
