import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";
import FacebookButton from "./FacebookButton";
import ResetPassword from "./ResetPassword";
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import config from '../config';
import "./Login.css";

export default class Login extends Component {
	constructor(props) {
    	super(props);

    	this.state = {
  			isLoading: false,
  			email: "",
  			password: "",
			rememberCredentials: false,
			showResetPasswordForm: props.mode === "recovery",
			userDataCallback: props.userDataCallback,
			toastMsg: "",
			toastTime: 5000
		};

		this.goBack = this.goBack.bind(this);
  	}

  	validateForm() {
    	return this.state.email.length > 0 && this.state.password.length > 0;
  	}

  	handleChange = event => {
    	this.setState({
      		[event.target.id]: event.target.value
    	});
  	}

	handleSubmit = async event => {
  		event.preventDefault();

  		this.setState({ isLoading: true });

  		try {
    		const user = await Auth.signIn(this.state.email, this.state.password);
			//console.log(user);
			//alert("");
            const cookies = new Cookies();
            cookies.set('showProgress', 'yes', { path: '/' });
    		this.props.userHasAuthenticated(true);
			this.getUserData(this.state.userDataCallback);
			//window.location = "/";   these two lines break the user data callback functions
            //window.location.reload();
  		} catch (e) {
    		//alert(e.message);
			this.makeToast(e.message, false);
    		this.setState({ isLoading: false });
  		}
	}

	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}
	
	getToastClass() {
		let toastClass = (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden";
		
		return toastClass;
	}
	

    handleFbLogin = () => {
        this.props.userHasAuthenticated(true);
		this.getUserData(this.state.userDataCallback);
    };

	getUserData(callback) {
		Auth.currentAuthenticatedUser()
		.then(response => {
			var jwtToken = response.signInUserSession.idToken.jwtToken;
			this.setState({ 'jwtToken': jwtToken });

			axios.get(config.apiGateway.URL + '/users/me', { headers: { Authorization: jwtToken } })
				.then(result => {
					console.log("GET /users/me success LOGIN");
					console.log(result.data.data)
					callback(result.data.data);
				})
				.catch(error => {
					console.log("GET /users/me error");
					console.log(error);
				})
		})
	}

	// go back to previous form state
	goBack(e) {
		if(this.state.showResetPasswordForm)
			this.setState({ showResetPasswordForm: false });
	}

  	render() {
    	return (
            <div className="Login">
				<div id="toast" className={ this.getToastClass() + " loginToastStyle" }>
					{ this.state.toastMsg }</div>
			{
				(!this.state.showResetPasswordForm) ? (
					<form onSubmit={this.handleSubmit} autoComplete="on">
						<FormGroup>
							<FormControl
								autoFocus
								type="email"
								value={ this.state.email }
								onChange={ this.handleChange }
								placeholder="Email Address or Username"
								id="email"
							/>
							<FontAwesomeIcon icon={ faEnvelope } className="inputIcon"/>
						</FormGroup>
						<FormGroup className="passwordGroup">
							<FormControl
								value={this.state.password}
								onChange={this.handleChange}
								type="password"
								placeholder="Password"
								id="password"
							/>
							<FontAwesomeIcon icon={ faLock } className="inputIcon"/>
						</FormGroup>
						<Row>
							<Col className="text-center">
								<LoaderButton
									disabled={!this.validateForm()}
									type="submit"
									isLoading={this.state.isLoading}
									text="LOGIN"
									loadingText="Logging in…"
									className="loginButton"
								/>
							</Col>
						</Row>
						<div className="forgotPasswordLink" onClick={ () => { this.setState({ showResetPasswordForm: true }) } }>
							Forgot Password
						</div>
						<div className="thickHrContainer">
							<Row>
								<Col className="text-center mt-3">
									<hr className="thickHorizontalRule d-inline-block"/>
									<h4 className="d-inline-block font-weight-bold thickHrLabel">OR</h4>
									<hr className="thickHorizontalRule d-inline-block"/>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3 text-center">
									<FacebookButton
										onLogin={ this.handleFbLogin }
									/>
									{/*<LoaderButton
										className="federatedLoginButton TwitterButton"
										text="Login with Twitter"
										onClick={ this.handleClick }
										disabled={ this.state.isLoading }
										type="twitter"
									/>
									<LoaderButton
										className="federatedLoginButton GoogleButton"
										text="Login with Google"
										onClick={ this.handleClick }
										disabled={ this.state.isLoading }
										type="google"
									/>*/}								
								</Col>
							</Row>
						</div>
					</form>
				) : (
					<>
						<div onClick={ this.goBack } className="text-left backButton">
							<FontAwesomeIcon icon={ faArrowLeft } className="d-inline-block mx-2"/>
							<div className="d-inline-block backButtonLabel">Back</div>
						</div>
						<ResetPassword mode="reset"/>
					</>
				)
			}
      		</div>
    	);
  	}
}