import React, { Component } from 'react';
import styled from 'styled-components';

// inline stylesheet
const Styles = styled.div`
	html, body {
		height: 100%;
	}
	h3 { color:#e51e26; }
	.crowd-bg {
		width: 100%;
		background: #000 url('/img/bg-crowd-dark.jpg') no-repeat right bottom;
	}
	.container {
		max-width: 100%;
	}
	.content {
		width: 70%;
		margin: auto;
	}
	h3 { margin-top: 2rem; }
	p {
		font-size: 14px;
		padding-top: 20px;
		font-size: 1.1em;
	}
	@media only screen and (max-width: 768px) {
		/* For mobile phones: */
		.content {
			width: 100%;
		}
		p {
			text-align: left;
		}
		[class*="col-"] {
			width: 100%;
		}
		img {
			width: 90%;
		}
	}

	.pageHeader {
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 10%;
	}

	.pageSubHeading {
		font-weight: 700;
		margin-top: 10%;
		text-transform: uppercase;
	}

`;

// main renderer
class ContestRules extends Component {
	render() {
		const bgStyle = {
			'background': `linear-gradient(to bottom, rgba(45, 45, 45, 0) 0%, rgba(45, 45, 45, 1.0) 800px) left top no-repeat, url("/img/about/element01.jpg") left top / 100% repeat-x`,
			'backgroundSize': '100%'
		};

		return (
			<Styles>

				<div>
					<div className="row no-gutters">
						<div className="col-md-12">
							<div className="about-element">
								<div className="about-logo"><img className="img-fluid" src="/img/main/celebyou-logo02.png" alt="No Bullying, Just Music" /></div>
								<div className="about-desc">
									<h1 className="about-title">Our <span className="highlight">Social<span className="line-break"></span> Responsibility<span className="line-break"></span></span> Program</h1>
									<p>CelebYou’s online singing contest is dedicated to helping independent artists/bands to pursue their passions and live their dreams. Our safe online platform allows independent and aspiring artists to engage with fans and showcase their talents in online singing competitions.</p>
									<p>We’re reimagining the journey with a comprehensive platform designed to discover and develop up-and-coming stars. Our talented team combines Grammy-winning songwriters with Emmy-winning producers to create engaging, family-friendly content ranging from drama to comedy, music to e-sports, including network TV properties.</p>
								</div>
							</div>
						</div>
					</div>{/* /.row */}
				</div>

				<div className="container">
					<div className="row content">
						<div className="col-md-12">
							<h2 className="display-4 pageSubHeading">About <span className="highlight">the Contest</span></h2>
							<p>The next contest is coming soon. Register now to receive notification to your phone. Don't miss out!</p>

							<h3>The Prize</h3>
							<p>The next prize will be announced soon! The judges will be looking for the following:</p>
							<ul>
								<li>Vocal quality and Melody</li>
								<li>Fan Likes</li>
								<li>Creativity and originality</li>
							</ul>
							<p>Judges for CelebYou’s online singing contest is a panel representing varying industry professionals across multiple genres of music, including: Rap, Rock, R&B, Country, Christian, Pop and others. The panel consists of current and former music industry professionals who love music and are looking to help find undiscovered talent. Everyone gets their fair shot in our contest. It doesn’t matter where you live or what your genre is, everyone gets judged equally. The best part is your music will be judged by professionals in your genre.</p>

							<h3>Past Winners</h3>
							<p><b>Bryce Nicholas, August 2018 Winner</b></p>
							<p>Bryce Nicholas was selected as the winner of our contest that ended in August 2018. His winning contest entry is below.</p>
							<p><iframe title="Bryce Nicholas" className="center pt-4" src="https://www.youtube.com/embed/iN9UaQ9rGoo" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={562} frameBorder={0} /></p>
							<p><b>Alicia Moore February 2018 Winner</b></p>
							<p>Alicia Moore was selected as the winner of our contest that ended in February 2018. Alicia is from Virginia Beach, VA and her winning contest entry is below.</p>
							<p><iframe title="Alicia Moore" className="center pt-4" src="https://www.youtube.com/embed/yeCXEc95ZfU" allow="autoplay; encrypted-media" allowFullScreen width="100%" height={562} frameBorder={0} /></p>
						</div>
					</div>
					{ this.props.footer }
				</div>
			</Styles>
		)
	}
}

export default ContestRules;