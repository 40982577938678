import React, { Component, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import axios from 'axios';
import { Auth } from "aws-amplify";
import config from "../config";
import './Account.css';
import './Artist.css';

// import ReactPlayer from 'react-player';
import { useDropzone } from 'react-dropzone'

function DragAndDropDialog(props) {
	const onDrop = useCallback(acceptedFiles => {
		props.callback(acceptedFiles, props.ddkey);
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div { ...getRootProps() } className={ props.styles }>
			<input { ...getInputProps() } />
			{
				isDragActive ?
					<span>Drop the file here ...</span> : (
						props.selectedFile ?
							<div className="m-2">{ props.selectedFile }</div>
						: (
							<div className="ddBorder m-0 py-5 px-2">
								<div>
									{ props.prompt }, or
									<span className="text-info m-2">Browse</span>
								</div>
								{
									(props.format) ? (
										<>
											<span className="ddDialogFormat py-2 text-muted">{ props.format }</span>
											{
												(props.dims) ? <span className="ddDialogFormat px-2">{ props.dims }</span> : ""
											}
										</>
									) : ("")
								}
							</div>
						)
					)
			}
		</div>
	);
}


// main renderer
class AccountPage extends Component {
	// state object
	constructor(props) {
		super(props)

		this.state = {
			me: [],
			bio: '',
			jwtToken: '',
			imageUploadStatus: {},
			userDataCallback: props.userDataCallback,
			isLoading: true
		}

		this.dragAndDropFileChange = this.dragAndDropFileChange.bind(this);
	};

	async refreshUserData() {
		//console.log("refreshUserData");
		//localStorage.removeItem("loginUserData");

		try {
			await Auth.currentAuthenticatedUser().then(response => {
				axios.get(config.apiGateway.URL + '/users/me', { headers: {Authorization: this.state.jwtToken} })
					.then(result => {
						console.log("GET /users/me success");
						console.log(result.data.data)
						this.state.userDataCallback(result.data.data);
					})
					.catch(error => {
						console.log("GET /users/me error");
						console.log(error);
						//window.alert(JSON.stringify(error));
					})
			});
		} catch(e) {
			console.log(e);
		}
	}

	componentDidMount() {

		Auth.currentAuthenticatedUser()
			.then(response => {
				var jwtToken = response.signInUserSession.idToken.jwtToken;

				this.setState({ jwtToken: jwtToken});

				axios.get(config.apiGateway.URL+'/users/me', { headers: {Authorization: jwtToken} })
					.then(result => {
						//console.log("GET /users/me success");
						//console.log(result)
						this.setState({ me: result.data.data });
						this.setState({ bio: this.state.me.bio, isLoading: false });

						//console.log(this.state.me);
					})
					.catch(error => {
						console.log("GET /users/me error");
						console.log(error);
					})
			})
	}

	handleBioChange = (e) => {
		const temp = this.state.me;
		temp.bio = e.target.value;
		this.setState({ bio: e.target.value, me: temp });
	}

	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}

	handleSubmit = (e) => {

		e.preventDefault();

		//console.log("handleSubmit");

		console.log(this.state.me);
		var tempStatus = { ...(this.state.profileFileName && { profile: false } ), ...(this.state.coverFileName && { cover: false }) };
		this.setState({ imageUploadStatus: { ...tempStatus } });

		if(this.state.profileFileName) {
			//console.log("uploading profile");
			this.handleImageUpload('profile');
		}

		if(this.state.coverFileName) {
			//console.log("uploading cover");
			this.handleImageUpload('cover');
		}

		if(Object.keys(tempStatus).length === 0) {
			//console.log("putting bio only");
			// no image file names / upload status flags set, so do PUT call here for the biography field
			axios.put(config.apiGateway.URL+'/users/me',
				this.state.me,
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					console.log("PUT /users/me success");
					console.log(result);
					//if(localStorage.getItem("loginUserData"))
					//	localStorage.removeItem("loginUserData");
					window.location.reload();
				})
				.catch(error => {
					console.log("PUT /users/me error");
					console.log(error);
				})
		} else {
			//console.log("leaving put for img");
		}

		return false;
	}

	updateMe(type) {
		// assume bio is the only required field and can be empty
		var temp = this.state.imageUploadStatus;
		temp[type] = true;

		// take boolean state of values if defined... this uses undef as a state
		var ready = (temp['profile'] === undefined || temp['profile'] === true) && (temp['cover'] === undefined || temp['cover'] === true);

		this.setState({ imageUploadStatus: temp });

		if(ready)
			axios.put(config.apiGateway.URL+'/users/me',
				this.state.me,
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					console.log("PUT /users/me success");
					console.log(result);
					this.refreshUserData();
					//window.location.reload();
				})
				.catch(error => {
					console.log("PUT /users/me error");
					console.log(error);
				})
		else
			console.log("not ready for upload");
	}

	// Perform the upload
	handleImageUpload(type) {
		//console.log("handleUpload");

		let file;
		let fileParts;

		if (type === 'profile') {
			file = this.state.profileFileData[0];
			// Split the filename to get the name and type
			fileParts = this.state.profileFileName.split('.');
		} else if (type === 'cover') {
			file = this.state.coverFileData[0];
			// Split the filename to get the name and type
			fileParts = this.state.coverFileName.split('.');
		}
		//console.log(file);
		let fileName = fileParts[0] + Math.random().toString(36).substr(1, 16);
		let fileType = fileParts[1];

		console.log("fileName: " + fileName);
		//console.log("fileType: " + fileType);

		/*********************************
		* S3 Configuration
		*********************************/
		var aws = require('aws-sdk');
		require('dotenv').config();

		// Configure aws with your accessKeyId and your secretAccessKey
		aws.config.update({
			region: config.s3.REGION,
			accessKeyId: config.s3.AWS_ACCESS_KEY_ID, //process.env.AWSAccessKeyId,
			secretAccessKey: config.s3.AWS_SECRET_ACCESS_KEY //process.env.AWSSecretKey
		})

		//console.log(aws.config);

		const S3_BUCKET = config.s3.BUCKET //process.env.bucket

		//console.log("s3_bucket");
		//console.log(S3_BUCKET);

		const s3 = new aws.S3();  // Create a new instance of S3

		// Set up the payload of what we are sending to the S3 api
		const s3Params = {
			Bucket: S3_BUCKET,
			Key: fileName,
			Expires: 500,
			ContentType: fileType,
			ACL: 'public-read'
		};

		// Make a request to the S3 API to get a signed URL which we can use to upload our file
		s3.getSignedUrl('putObject', s3Params, (err, data) => {
			if(err) {
				console.log("Error");
				console.log(err);
				return;
				//res.json({success: false, error: err})
			}
			// Data payload of what we are sending back, the url of the signedRequest and a URL where we can access the content after its saved. const returnData = {
			const returnData = {
				signedRequest: data,
				url: `https://${S3_BUCKET}.s3.amazonaws.com/${fileName}`
			};

			//console.log("returnData");
			//console.log(returnData);

			var signedRequest = returnData.signedRequest;
			var url = returnData.url;

			// Put the fileType in the headers for the upload
			var options = {
				headers: {
					'Content-Type': fileType
				}
			};
			axios.put(signedRequest,file,options)
				.then(result => {
					//console.log("Response from s3");
					//console.log(result);
					//console.log(url);

					if (type === 'profile') {
						const temp = this.state.me;
						temp.profile_image = url;
						this.setState({ me: temp });
					} else if (type === 'cover') {
						this.setState(state => (state.me.cover_image = url, state));
					}

					this.updateMe(type);
				})
				.catch(error => {
					console.log(JSON.stringify(error));
				})
		});
	}

	dragAndDropFileChange(files, key) {
		//console.log("dragAndDropFileChange");
		//console.log(files);

		if(key === "profile") {
			//console.log("setting profile picture");
			this.setState({ profileFileName: files[0].name, profileFileData: files });
		} else if(key === "cover") {
			//console.log("setting cover picture");
			this.setState({ coverFileName: files[0].name, coverFileData: files });
		}

		//this.setState({ fileName:
	}

	backToAccountClickHandler = (e) => {
		e.preventDefault();
		window.location = "/account";
	}

	render() {

		const profileImageStyle = {
			border: '1px solid #e51e26'
		}

		const successMessage = () => (
			<div style={{padding:50}}>
			<h3 style={{color: 'green'}}>SUCCESSFUL UPLOAD</h3>
			<a href={this.state.url}>Access the file here</a>
			<br/>
			</div>
		)

		const toastClass = (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden";

		return (
			!this.state.isLoading ?
				<div className="container">
					<div className="content">
						<div className="row pt-4">
							<div className="col-md-10 pt-md-5">
								<div className="row justify-content-center mt-5 position-relative">
									<div className="m-3 backControl" onClick={ this.backToAccountClickHandler }>
										<FontAwesomeIcon icon={ faChevronLeft }/> Back to My Account
									</div>
									{/*<img className="img-fluid" src="/img/edit-account-header.png" alt="" />*/}
									<h1 className="editAccountHeading mt-md-3">Edit Account</h1>
								</div>
								<div id="toast" className={ toastClass } style={ this.toastStyle }>{ this.state.toastMsg }</div>
								<div className="row pt-3">
									<div className="col-md-6">
										<div className="form-group">
											<label><h4>Profile Image</h4></label>
											{ this.state.me.profile_image
												? <img src={this.state.me.profile_image} className="img-fluid float-center w-100" style={profileImageStyle} alt="" />
												: <img src="https://s3.amazonaws.com/celebyou-assets/Celebyou_ProfileDefault_1.png" className="img-fluid float-left" style={profileImageStyle} alt="" />
											}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label><h4>Cover Image</h4></label>
											{ this.state.me.cover_image
												? <img src={this.state.me.cover_image} className="img-fluid float-center w-100" style={profileImageStyle} alt="" />
												: <img src="https://s3.amazonaws.com/celebyou-assets/Celebyou_ProfileDefault_2.png" className="img-fluid float-left" style={profileImageStyle} alt="" />
											}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											{this.state.success ? <successMessage/> : null}
											<DragAndDropDialog callback={ this.dragAndDropFileChange }
												prompt="Drag your profile image here"
												format="Supports: jpg, png"
												dims="Max size: 2MB"
												styles="dragAndDropDialog videoUploadTextInput"
												ddkey="profile"
												selectedFile={ this.state.profileFileName }/>
												{/*<input onChange={this.handleChange} ref={(ref) => { this.uploadInputProfile = ref; }} type="file"/>*/}
												{/*<button id="uploadProfileImage" onClick={this.handleUpload}>UPLOAD</button>*/}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											{this.state.success ? <successMessage/> : null}
											<DragAndDropDialog callback={ this.dragAndDropFileChange }
												prompt="Drag your cover image here"
												format="Supports: jpg, png"
												dims="Max size: 2MB"
												styles="dragAndDropDialog videoUploadTextInput"
												ddkey="cover"
												selectedFile={ this.state.coverFileName }/>
													{/*<input onChange={this.handleChange} ref={(ref) => { this.uploadInputCover = ref; }} type="file"/>
													<button id="uploadCoverImage" onClick={this.handleUpload}>UPLOAD</button>*/}
										</div>
									</div>
								</div>
								<form onSubmit={this.handleSubmit}>
									<div className="row pt-3">
										<div className="col-md-12">
											<div className="form-group">
												<label><h4>Bio</h4></label>
												<textarea id="bio" name="bio" onChange={this.handleBioChange} className="form-control p-3" value={this.state.bio} />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group my-3">
												<Button type="submit" className="uploadButton">Submit</Button>
											</div>
										</div>
									</div>
								</form>
							</div>
							{ this.props.footer }
						</div>
					</div>
				</div>
			:
				<div className="loading"></div>
		)
	}
}

export default AccountPage;