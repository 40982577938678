import React, { Component } from 'react';
// import { Nav, NavItem, Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
// import axios from 'axios';
// import { Auth } from "aws-amplify";
import VideoCarousel from './VideoCarousel.js';

// inline stylesheet
const Styles = styled.div`
	.btn { white-space:normal!important; }
	.title-row h2 { text-transform:uppercase; font-size:1.2em; letter-spacing:1px; }

	.song-card {
		background-color: #191919;
		padding: 1rem;
		margin-top: 6rem;
		margin-bottom: 3rem;
		margin-left: -15px;
		text-transform: uppercase;
	}
	.song-card .title {
		color: #e51e26;
		font-size: 1.25em;
		font-weight: 600;
	}
	.song-card .title .highlight {
		color: #FFF;
	}
	.song-card p { margin-bottom:0; }

	.info-card {
		background-color: #191919;
		padding: 1rem;
		margin-top: 5rem;
		margin-bottom: 3rem;
		margin-left: -15px;
	}
	.info-card .title {
		color: #e51e26;
		font-size: 1.25em;
		font-weight: 600;
		text-transform: uppercase;
	}
	.info-card .title .highlight {
		color: #FFF;
	}

	@media (min-width: 768px) {
		.cs, .cs-l {
			margin-left: .25rem!important;
		}
		.cs, .cs-r {
			margin-right: .25rem!important;
		}
	}

	.bd-highlight {
		background-color: rgba(86,61,124,.15);
		border: 1px solid rgba(255,255,255,.15);
	}

	.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
`;

// main renderer
class LandingHome extends Component {
	// state object
	constructor(props) {
		super(props)

		this.state = {
			first_name: '',
			last_name: '',
			email: '',
			modalShow: false,
			modalTitle: 'Modal heading',
			modalMessage: 'This is a message',
			hideAlert: false,
			isLoading: true,
			videosOnPage: [
			]
		}
	};

	componentDidMount() {
		let tempTrending = [
				{
					"type":"youtube",
					"title":"Essence of U. (Feat. Glitch)",
					"description":"Bryce Nicholas was selected as the winner of our contest that ended in August 2018.",
					"videoUrl":"https://www.youtube.com/embed/iN9UaQ9rGoo",
					"thumbnail":"http://i3.ytimg.com/vi/iN9UaQ9rGoo/maxresdefault.jpg",
					"given_name":"Bryce",
					"family_name":"Nicholas",
				},
				{
					"type":"youtube",
					"title":"Wake Up",
					"description":"Alicia Moore was selected as the winner of our contest that ended in February 2018. Alicia is from Virginia Beach, VA.",
					"videoUrl":"https://www.youtube.com/embed/yeCXEc95ZfU",
					"thumbnail":"http://i3.ytimg.com/vi/yeCXEc95ZfU/maxresdefault.jpg",
					"given_name":"Alicia",
					"family_name":"Moore",
				},
				{
					"type":"youtube",
					"title":"Enough Said",
					"description":"Virgo Star V.S \"Enough Said\" Prod:Lexi Banks - filmed&Directed by Kevin Morman",
					"videoUrl":"https://www.youtube.com/embed/TqYx58dy_5g",
					"thumbnail":"http://i3.ytimg.com/vi/TqYx58dy_5g/maxresdefault.jpg",
					"given_name":"Virgo",
					"family_name":"Star",
				},
				{
					"type":"youtube",
					"title":"Essence of U. (Feat. Glitch)",
					"description":"Bryce Nicholas was selected as the winner of our contest that ended in August 2018.",
					"videoUrl":"https://www.youtube.com/embed/iN9UaQ9rGoo",
					"thumbnail":"http://i3.ytimg.com/vi/iN9UaQ9rGoo/maxresdefault.jpg",
					"given_name":"Bryce",
					"family_name":"Nicholas",
				},
				{
					"type":"youtube",
					"title":"Wake Up",
					"description":"Alicia Moore was selected as the winner of our contest that ended in February 2018. Alicia is from Virginia Beach, VA.",
					"videoUrl":"https://www.youtube.com/embed/yeCXEc95ZfU",
					"thumbnail":"http://i3.ytimg.com/vi/yeCXEc95ZfU/maxresdefault.jpg",
					"given_name":"Alicia",
					"family_name":"Moore",
				}
		];
		this.setState({ videosOnPage: tempTrending, isLoading: false });
	}


	render() {

		let trendingVideosProps = {
			mode: "trending",
			videos: this.state.videosOnPage,
			nextPageCallback: this.nextPage,
			showViewMoreButton: this.state.showViewMoreButton,
			redirectCallback: this.redirectCallback,
			redirectId: this.state.contestId,
			isAuthenticated: this.state.isAuthenticated,
			jwtToken: this.state.jwtToken,
			...(this.state.selectedVideo && {selectedVideo: this.state.selectedVideo }),
			...(this.state.selectedArtist && {selectedArtist: this.state.selectedArtist })
		};

		return (
			<Styles>

			<div>
				<div className="row no-gutters">
					<div className="col-md-12">
						<div className="cy-element element01">
							<img className="img-fluid w-100" src="/img/main/element01.jpg" alt="" />
							<h1 className="main-title">The Online<span className="line-break"></span> Singing Competition<span className="line-break"></span> for Independent<span className="line-break"></span> Artists &amp; Bands</h1>
							<div className="main-logo"><img className="img-fluid" src="/img/main/celebyou-logo02.png" alt="No Bullying, Just Music" /></div>
						</div>
					</div>
				</div>{/* /.row */}
				{ this.state.isLoading === false ?
					<VideoCarousel {...trendingVideosProps} />
					: ""
				}
				<div className="row no-gutters">
					<div className="col-md-12">
						<div className="cy-element element07">
							{/*<img className="img-fluid w-100" src="/img/main/element07.jpg" alt="" />*/}
							<p>
								<a href="/channels"><img className="img-fluid" src="/img/main/placeholder03.png" alt="" /></a>
							</p>
						</div>
					</div>
				</div>{/* /.row */}
				<div className="row no-gutters">
					<div className="col-md-12">
						<div className="cy-element element02">
							<a href="/signup">
								<img className="img-fluid w-100" src="/img/main/element02.jpg" alt="" />
							</a>
						</div>
					</div>
				</div>{/* /.row */}
				<div className="row no-gutters">
					<div className="col-md-5">
						<div className="embed-container"><iframe title="Thin Energy" src="https://www.youtube.com/embed/8m2cGXraFAI?rel=0" frameBorder="0" allowFullScreen></iframe></div>
					</div>
					<div className="col-md-7">
						<div className="cy-element thin-energy-spot">
							<a href="https://thinenergy.com/" target="_blank" rel="noopener noreferrer">
								<img className="img-fluid w-100" src="/img/main/thin-energy-spot.jpg" alt="Sponsored by Thin Energy, the healthy alternative energy source that promotes weight loss." />
							</a>
						</div>
					</div>
				</div>{/* /.row */}
				<div className="row no-gutters">
					<div className="col-md-12">
						<div className="cy-element element03">
							<a href="/signup">
								<img className="img-fluid w-100" src="/img/main/element03.jpg" alt="Play Journey to Stardom!" />
							</a>
						</div>
					</div>
				</div>{/* /.row */}
				<div className="row no-gutters">
					<div className="col-md-12">
						<div className="cy-element element04">
							<a href="/channels">
								<img className="img-fluid w-100" src="/img/main/element04.jpg" alt="Register as a fan to vote for your favorite contest entry." />
							</a>
						</div>
					</div>
				</div>{/* /.row */}
					{/*<div className="row no-gutters" style={{backgroundColor: '#fff'}}>
					<div className="col-md-8">
						<div className="cy-element cy-slanted element05">
							<img className="img-fluid w-100" src="/img/main/element05.jpg" alt="" />
						</div>
					</div>
					<div className="col-md-4">
						<div className="cy-white slanted slanted-right slant-to-left p-4">
							<a href="/channels"><img className="img-fluid" src="/img/main/placeholder01.png" alt="" /></a>
						</div>
					</div>
				</div>{/* /.row */}
				{ this.props.footer }
			</div>

			</Styles>
		)
	}
}

export default LandingHome;
