import React, { Component } from 'react';
// import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

// inline stylesheet
const Styles = styled.div`
`;

// main renderer
class Judges extends Component {
	render() {
		return (
			<Styles>

				<div className="bg-image-full" style={{backgroundColor:'#fff', color:'#000'}}>
					<div className="content" style={{width: "90%", margin: "auto"}}>
						<div className="row">
							<div className="col-md-6 col-sm-12 text-center">
								<h2 className="heading-title">The judges will be looking for the following:</h2>
								<img className="img-fluid" src="./img/about-the-judges-pie.png" alt="" />
							</div>
							<div className="col-md-6 col-sm-12 text-md-left text-sm-center">
								<img className="img-fluid" src="./img/about-the-judges-header.png" alt="" />
								<p className="p-4">Judges for CelebYou’s online singing contest is a panel representing varying industry professionals across multiple genres of music, including: Rap, Rock, R&B, Country, Christian, Pop and others. The panel consists of current and former music industry professionals who love music and are looking to help find undiscovered talent. Everyone gets their fair shot in our contest. It doesn’t matter where you live or what your genre is, everyone gets judged equally. The best part is your music will be judged by professionals in your genre.</p>
								<img className="img-fluid" src="./img/about-the-judges-breakdown.png" alt="Judges Breakdown" />
							</div>
						</div>
						<div className="row pt-5">
							<div className="col-md-6 col-sm-12 text-md-left text-sm-center">
								<img className="img-fluid" src="./img/about-the-judges-meet-our-judges.png" alt="Meet Our Judges" />
							</div>
							<div className="col-md-6 col-sm-12">
							</div>
						</div>
						<div className="row pt-5 pb-5">
							<div className="col-md-6 col-sm-12">
								<div className="row">
									<div className="col-md-4 col-sm-4">
										<img className="img-fluid" src="./img/about-the-judges-andrew-rollins.jpg" alt="Andrew Rollins" />
									</div>
									<div className="col-md-8 col-sm-8">
										<h2 className="judges-title">Andrew Rollins</h2>
										<p>An accomplished composer, songwriter and musician, he’s an acoustic master. Some awards that he has received include an Emmy Award in 2018, 3 SISA International Music Awards for Album of the Year, Producer of the Year, and Song of the Year for I Believe,  a Telly Award,  and the Axion Award for Musical Excellence.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-sm-12"></div>
						</div>
						<div className="row spacer"></div>
					</div>
				</div>

			</Styles>
		)
	}
}

export default Judges;