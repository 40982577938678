import React, { Component } from 'react';
import axios from 'axios';
import config from '../config.js';
import { Row, Col, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactPlayerModal from './ReactPlayerModal';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './VideoCarousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft, faStar
	, faThumbsUp, faHeart, faGift, faComment, faPlay, faCoins } from '@fortawesome/free-solid-svg-icons'

const parse = require('html-react-parser');

const CustomRightArrow = ({ onClick, ...rest }) => {
  // onMove means if dragging or swiping in progress.
  return (
	<div onClick={() => onClick()} className="customCarouselArrow customRightArrow">
		<FontAwesomeIcon icon={ faChevronRight } />
	</div>
	);
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  // onMove means if dragging or swiping in progress.
  return (
	<div onClick={() => onClick()} className="customCarouselArrow customLeftArrow">
		<FontAwesomeIcon icon={ faChevronLeft } />
	</div>
	);
};

export default class VideoCarousel extends Component {

	constructor(props) {
		super(props);

		this.handleShowGifts = this.handleShowGifts.bind(this);
		this.handleCloseGifts = this.handleCloseGifts.bind(this);
		this.handleShowGiftsCallback = this.handleShowGiftsCallback.bind(this);
		this.handleShowCommentsCallback = this.handleShowCommentsCallback.bind(this);

		this.handleShowVideo = this.handleShowVideo.bind(this);
		this.handleShowExternalVideo = this.handleShowExternalVideo.bind(this);

		this.handleImgError = this.handleImgError.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showVideoCallback = this.showVideoCallback.bind(this);
		this.handleCloseVideo = this.handleCloseVideo.bind(this);
		this.getCurrentVideo = this.getCurrentVideo.bind(this);
		this.getVideoUrl = this.getVideoUrl.bind(this);
		this.videoFinished = this.videoFinished.bind(this);
		this.getComments = this.getComments.bind(this);
		this.updateLikes = this.updateLikes.bind(this);
		this.updateLoves = this.updateLoves.bind(this);
		this.redirectToViewAllPage = this.redirectToViewAllPage.bind(this);

		//console.log("building VC with videos");
		//console.log(props.videos);
		//console.log(props.selectedVideo);

		this.state = {
			mode: props.mode,
			maxCharsInSliderVideoDescription: 280,
			jwtToken: props.jwtToken,
			isAuthenticated: props.isAuthenticated,
			parentId: props.parentId,
			videos: props.videos,
			currentSlide: 1,
			selectedVideoItem: null,
			disableViewAll: props.disableViewAll,
			showViewMoreButton: props.showViewMoreButton,
			nextPageCallback: props.nextPageCallback,
			redirectCallback: props.redirectCallback,
			redirectId: props.redirectId,
			toastTime: 4000,
			toastMsg: "",
			toastSuccess: null,
			imgErrorTriggered: {},
			...(props.selectedArtist && { selectedArtist: props.selectedArtist }),
			...(props.selectedVideo && { selectedVideo: props.selectedVideo })
		};

		//console.log(props.selectedArtist);
	}

	redirectToViewAllPage(e) {
		e.preventDefault();
		if(this.state.redirectCallback && this.state.redirectId)
			this.state.redirectCallback(this.state.redirectId);
	}


	componentDidMount() {

	}

	componentWillUnmount() {

	}

	handleChange = (e) => {
		console.log("handle change");
		console.log(e.target.name);
		console.log(e.target.value);
		this.setState({ [e.target.name]: e.target.value });
	}

	handleImgError(e) {
		//console.log("handling");
		let key = e.target.getAttribute('data-key');
		if(e.target && !this.state.imgErrorTriggered[key]) {
			//console.log("hiding " + key);
			//console.log(e.target);
			//console.log(e.target.style);
			e.target.style.display = "none";
			let temp = this.state.imgErrorTriggered;
			temp[key] = true;
			this.setState({ imgErrorTriggered: temp });
		} else {
			//console.log("skipping " + key);
		}
	}

	getCurrentVideo() {
		return this.state.currentVideo;
	}

	showVideoCallback(show) {
		this.setState({ showVideo: show });
	}

	async handleShowGiftsCallback(video) {
		//console.log("handleShowGifts");
		this.handleShowGifts(video);
		//this.setState({ selectedArtist: [video.cognito_sub, `${video.given_name} ${video.family_name}`]
		//	, showVideo: false });
		//this.setState({ selectedArtist: [video.cognito_sub, `${video.given_name} ${video.family_name}`] });
	}

	async handleShowCommentsCallback(video) {
		//console.log("handleShowComments");
		this.handleShowComments(video);
		//this.setState({ selectedVideo: [video.id, video.title], showVideo: false });
		//this.setState({ selectedVideo: [video.id, video.title] });
	}

	handleCloseGifts() {
		this.setState({ showGifts: false });
	}

	buildGiftsModal() {
		//console.log("build gift modal " + this.state.selectedArtist);
		//console.log(this.state.selectedArtist);

		return (
			<Modal size="lg" className="modalDimmer" show={ this.state.showGifts } onHide={() => this.handleCloseGifts() }>
				{/*<div id="toast" className={ "modalToastStyle " + this.getToastClass() }>{ this.state.toastMsg }</div>*/}
				<Modal.Header closeButton style={{backgroundColor: '#222'}}>
					<img className="img-fluid" src="/img/gifts-header.jpg" width="" alt="" />
				</Modal.Header>
				<Modal.Body>
					<div className="row justify-content-center align-items-center px-2">
						<h3 className="giftsHeaderText">
							SHOW YOUR LOVE
						</h3>
						<div className="p-2 giftsHeaderSubtitle inline-block">
							Spend your points and show an artist how much they rock!
						</div>
					</div>
					<div className="row px-4 mt-4">
						<div className="col-md-6">
							<h4 className="mr-4 text-uppercase font-weight-bold d-inline-block">Give a gift to </h4>
							<b className="capitalize">{ this.state.selectedArtist[1] }</b>
						</div>
						<div className="col-md-6 form-group">
						</div>
					</div>
					<div className="row pt-2 px-4">
						<div className="col-md-9 form-group giftsSelect">
							<select id="giftId" name="giftId" onChange={ this.handleChange } className="form-control" disabled={this.state.disableSend}>
								<option value="">Select a gift</option>
								{
									this.state.gifts && this.state.gifts.map((gift, gift_key) => {
										return (
											<option value={ gift.id } key={ gift_key }>{ gift.name } (cost: { gift.cost })</option>
										)
									})
								}
							</select>
						</div>
						<div className="col-md-3 text-right">
						{
							this.state.totalToken !== undefined ? (
							<>
								<div className="text-uppercase font-weight-bold" key={ this.state.totalToken }>Your tokens</div>
								<span className="faTokens" >
									<span className="tokenCount">
										<FontAwesomeIcon icon={ faCoins } />
										<span>{ this.state.totalToken }</span>
									</span>
								</span>
							</>
							) : ""
						}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => this.handleCloseGifts() }>
						Close
					</Button>
					<Button variant="primary" className="ml-3 genericWhiteButton" onClick={this.handleSubmitGift} disabled={this.state.disableSend}>
						SEND IT!
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	setActiveVideoBox(key, video) {
		if(key !== this.state.selectedBoxKey)
			this.setState({ selectedBoxKey: key, selectedVideo: video });
	}

	clearActiveVideoBox() {
		if(this.state.selectedBoxKey)
			this.setState({ selectedBoxKey: null, selectedVideo: null });
	}

	getToastClass() {
		let toastClass = (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden";

		return toastClass;
	}

	handleCloseComments() {
		this.setState({ showComments: false });
	}

	getComments() {
		axios.get(config.apiGateway.URL+'/videos/comments/canned', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /videos/comments/canned success");
				console.log(result.data.data);
				this.setState({ comments: result.data.data });
			})
			.catch(error => {
				console.log("GET /videos/comments/canned error");
				console.log(error);
			})
	}

	getArtists() {
		axios.get(config.apiGateway.URL+'/users/artists', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /users/artists success");
				console.log(result.data.data);
				this.setState({ artists: result.data.data });
			})
			.catch(error => {
				console.log("GET /users/artists error");
				console.log(error);
			})
	}

	getGifts() {
		axios.get(config.apiGateway.URL+'/users/gifts', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /users/gifts success");
				console.log(result.data.data);
				this.setState({ gifts: result.data.data });
			})
			.catch(error => {
				console.log("GET /users/gifts error");
				console.log(error);
			})
	}

	getMe() {
		axios.get(config.apiGateway.URL+'/users/me', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /users/me success");
				console.log(result.data.data)
				this.setState({ totalToken: result.data.data.tokens });
			})
			.catch(error => {
				console.log("GET /users/me error");
				console.log(error);
			})
	}

	getGift(gifts, giftId) {
		for (const gift of gifts) {
			if (gift.id === parseInt(giftId)) {
				return gift
			}
		}
	}

	async handleShowGifts(video) {
		if(!this.state.isAuthenticated) {
			this.makeToast("Signup or login to send a gift");
			return;
		}
		let tempArtist = video.given_name ? `${video.given_name} ${video.family_name}` : `${this.state.selectedArtist[1]}`;
		this.setState({ disableSend: true, showGifts: true, selectedArtist: [video.cognito_sub, tempArtist] });
		await this.getMe(); // for tokens
		await this.getGifts(); // for gift options
		this.setState({ disableSend: false });
	}

	async handleShowComments(video) {
		if(!this.state.isAuthenticated) {
			this.makeToast("Signup or login to comment");
			return;
		}
		this.setState({ disableSend: true, showComments: true, selectedVideo: [video.id, video.title ] });
		await this.getComments();
		this.setState({ disableSend: false });
	}

	updateLikes(vidId) {
		let tempVid = this.state.currentVideo;
		let tempVids = this.state.videos;

		for(var i = 0; i < tempVids.length; i++) {
			if(tempVids[i].id === vidId) {
				tempVid = tempVids[i];
				tempVid.video_i_like = tempVid.video_i_like ? 0 : 1;
				//tempVids[i].video_i_like = tempVid.video_i_like;
				break;
			}
		}
		//console.log((tempVid && { currentVideo: tempVid }));
		this.setState({ currentVideo: tempVid , videos: tempVids });

		if(this.state.showVideo)
			this.resetVideoModal(vidId);
	}

	updateLoves(vidId) {
		let tempVid = this.state.currentVideo;
		let tempVids = this.state.videos;

		for(var i = 0; i < tempVids.length; i++) {
			if(tempVids[i].id === vidId) {
				tempVid = tempVids[i];
				tempVid.video_i_love = tempVid.video_i_love ? 1 : 1;// **** bug temp fix
				//tempVids[i].video_i_love = tempVid.video_i_love;
			} else {
				// reset all other loves because user can only love 1 at a time
				tempVids[i].video_i_love = 0;
			}
		}

		this.setState({ currentVideo: tempVid , videos: tempVids });
		if(this.state.showVideo)
			this.resetVideoModal(vidId);
	}

	resetVideoModal(id) {
		this.handleCloseVideo();
		this.handleShowVideo(id);
	}

	/*
		video title and description can overflow its box on the slider.
		this looks ugly, so this function does a calculation on maximum length and returns truncated
		title / description for display
	*/
	truncateTitleAndDescription(title, desc) {
		//em ratio of title to desc
		const titleScaleFactor = 2.5;
		//console.log("old title " + title);
		//console.log("old desc " + desc);
		var newTitle = title;
		var ntLength = newTitle.length * titleScaleFactor;
		var newDesc = desc;
		if(newTitle && newDesc) {
			let max = this.state.maxCharsInSliderVideoDescription;
			let total = ntLength + newDesc.length;
			//console.log(total);
			//console.log(max);
			if(ntLength > max) {
				newTitle = newTitle.substring(0, max) + "...";
				newDesc = "";
			} else if(total > max) {
				newDesc = newDesc.substring(0, Math.abs(max - ntLength)) + "...";
			}
		}
		//console.log("new title " + title);
		//console.log("new desc " + desc);
		return [newTitle, newDesc];
	}

	buildCommentsModal() {
		return (
			<Modal size="lg" className="modalDimmer" show={this.state.showComments} onHide={() => this.handleCloseComments() }>
				<div id="toast" className={ "modalToastStyle " + this.getToastClass() }>{ this.state.toastMsg }</div>
				<Modal.Header closeButton style={{backgroundColor: '#222'}}>
					<img className="img-fluid" src="/img/gifts-header.jpg" width="" alt="" />
				</Modal.Header>
				<Modal.Body>
					<div className="row justify-content-center align-items-center px-2">
						<h3 className="giftsHeaderText">
							SHOW YOUR LOVE
						</h3>
						<div className="p-2 giftsHeaderSubtitle inline-block">
							Send a comment and tell an artist how much they rock!
						</div>
					</div>
					<div className="row px-4 mt-4">
						<div className="col-md-12">
							<h4 className="mr-4 text-uppercase font-weight-bold d-inline-block">Comment on the video </h4>
							<b className="capitalize commentVidTitle">{ this.state.selectedVideo[1] }</b>
						</div>
					</div>
					<div className="row pt-2 px-4">
						<div className="col-md-12 mt-2 form-group giftsSelect">
						{/*<label><b>Choose a comment</b></label>*/}
							<select id="commentId" name="commentId" onChange={ this.handleChange } className="form-control" disabled={this.state.disableSend}>
								<option value="">Select a comment</option>
								{
									this.state.comments && this.state.comments.map((comment, comment_key) => {
										return (
											<option value={ comment.id } key={ comment_key }>{ comment.comment }</option>
										)
									})
								}
							</select>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => this.handleCloseComments() }>
						Close
					</Button>
					<Button variant="primary" className="ml-3 genericWhiteButton"
							onClick={ () => this.handleSubmitComment() } disabled={this.state.disableSend}>
						SEND IT!
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	handleSubmitLike = (videoId) => {
		console.log("handleSubmitLike VC");
		if(this.state.isAuthenticated)
			axios.post(config.apiGateway.URL+'/videos/' + videoId + '/like',
				{},
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					console.log("POST /videos/{video_id}/like success");
					console.log(result.data.data);
					this.updateLikes(videoId);
				})
				.catch(error => {
					console.log("POST /videos/{video_id}/like error");
					console.log(error);

					if(error.response && error.response.data.data.public === "You cannot like your own video")
						this.makeToast("You cannot like your own video", null);
				})
		else
			this.makeToast("Signup or login to like this video");
	}

	handleSubmitLove = (videoId) => {
		console.log("handleSubmitLove");
		if(this.state.isAuthenticated)
			axios.post(config.apiGateway.URL+'/videos/' + videoId + '/love',
				{},
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					console.log("POST /videos/{video_id}/love success");
					console.log(result.data.data);
					this.updateLoves(videoId);
				})
				.catch(error => {
					console.log("POST /videos/{video_id}/love error");
					console.log(error);

					if(error.response && error.response.data.data.public === "You cannot love your own video")
						this.makeToast("You cannot love your own video", null);
				})
		else
			this.makeToast("Signup or login to love this video");
	}

	handleSubmitComment = (e) => {
		if(this.state.commentId === undefined || this.state.selectedVideo === undefined) {
			console.log("invalid submit comment arguments");
			return;
		}
		if(this.state.isAuthenticated)
			axios.post(config.apiGateway.URL+'/videos/' + this.state.selectedVideo[0] + '/comment/' + this.state.commentId,
				{},
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					console.log("POST /videos/{video_id}/comment/{comment_id} success");
					console.log(result.data.data);
					this.makeToast("Comment sent!", true);
				})
				.catch(error => {
					console.log("POST /videos/{video_id}/comment/{comment_id} error");
					console.log(error);
					if(error.response && error.response.data.data.public)
						this.makeToast(error.response.data.data.public, null);
					else if(error.response && error.response.data.data.public === "You cannot comment on your own video")
						this.makeToast("You cannot comment on your own video", null);
				})
		else
			this.makeToast("Signup or login to comment");
	}

	handleSubmitGift = () => {
		if(!this.state.giftId || !this.state.selectedArtist) {
			console.log("invalid submit gift arguments");
			return;
		}
		if(this.state.isAuthenticated)
			axios.post(config.apiGateway.URL + '/users/' + this.state.selectedArtist[0] + '/gift/' + this.state.giftId,
				{},
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					//console.log("POST /gift/{gift_id}/user/{user_sub} success");
					//console.log(result.data.data);
					this.makeToast("Gift sent! Thanks for showing your support.", true);
				})
				.catch(error => {
					console.log("POST /gift/{gift_id}/user/{user_sub} error");
					console.log(error);
					if(error.response && error.response.data.data.details === "User cannot afford to give gift")
						this.makeToast(error.response.data.data.public, null);
					else if(error.response && error.response.data.data.public === "You cannot give a gift to your own video")
						this.makeToast(error.response.data.data.public, null);
				})
		else
			this.makeToast("Signup or login to give a gift");
	}

	handleCloseVideo() {
		this.setState({ showVideo: false, currentVideo:[] });
	}

	async handleShowVideo(videoId) {
		console.log("handleShowVideo");
		this.setState({ disableSend: true });
		await this.getVideoUrl(videoId);
	}

	handleShowExternalVideo(video) {
		console.log("handleShowExternalVideo");
		this.setState({ disableSend: true });
		this.playExternalVideoInPlayer(video);
	}

	playExternalVideoInPlayer(video) {
		console.log("playExternalVideoInPlayer");
		this.setState({ currentVideo: video, showVideo: true });
	}

	getVideoUrl(videoId) {
		console.log("getVideoUrl " + videoId);
		if(this.state.isAuthenticated) {
			console.log("auth get vid");
			axios.get(config.apiGateway.URL+'/videos/' + videoId, { headers: {Authorization: this.state.jwtToken} })
				.then(result => {
					console.log("GET /videos/" + videoId + " success");
					console.log(result.data.data);
					this.setState({ currentVideo: result.data.data, showVideo: true, disableSend: false });
				})
				.catch(error => {
					console.log("GET /videos/" + videoId + " error");
					console.log(error);
					this.makeToast("Sorry, we could not play that video.", null);
				});
		} else {
			console.log("unauth get vid");
			axios.get(config.apiGateway.URL + '/videos/' + videoId)
				.then(result => {
					console.log("GET /videos/" + videoId + " success");
					console.log(result.data.data);
					this.setState({ currentVideo: result.data.data, showVideo: true, disableSend: false });
				})
				.catch(error => {
					console.log("GET /videos/" + videoId + " error");
					console.log(error);
					this.makeToast("Sorry, we could not play that video.", null);
				});
		}
	}

	videoFinished(videoId) {
		console.log("videoFinished " + videoId);
		if (this.state.isAuthenticated) {
			axios.post(config.apiGateway.URL+'/videos/' + videoId + '/watch',
				{},
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					console.log("POST /videos/"+videoId+"/watch success");
					console.log(result);
				})
				.catch(error => {
					console.log("POST /videos/"+videoId+"/watch error");
					console.log(error);
				})
		}
	}


	expandVideoSliderItem(id) {
		this.setState({ selectedVideoItem: id, showViewAllButton: false });
	}

	closeVideoSliderItem(id) {
		this.setState({ selectedVideoItem: "", showViewAllButton: true });
	}

	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}


	buildVideoTileItem(video, key) {
		let boxBgClass = (this.state.selectedBoxKey !== null && this.state.selectedBoxKey === key)
			? "activeBg" : ((this.state.selectedBoxKey !== null &&  this.state.selectedBoxKey !== key) ? "dimBg" : "");
		let likeTooltip = <Tooltip className={ !video.video_i_like ? "hidden" : "" }>You already liked this!</Tooltip>;
		let loveTooltip = <Tooltip className={ !video.video_i_love ? "hidden" : "" }>You already loved this!</Tooltip>;
		let controlsBgClass = (this.state.selectedBoxKey !== null && this.state.selectedBoxKey === key) ? "" : "hidden";

		//console.log("buildVideoTileItem");
		//console.log(video);

		if(video.type === "ad") {
			//console.log("IS AD");
			return (
				<div className={ "col-md-4 col-sm-6 videoBox adItem py-2 px-lg-4 px-md-3 px-sm-2 mx-2 text-center " + boxBgClass } key={ key }
						onClick={ (e) => { this.setActiveVideoBox(key, video) } }
						onMouseOver={ () => { this.setActiveVideoBox(key, video) } }
						onMouseOut={ () => { this.clearActiveVideoBox() } }>

					<div className="video-thumbnail tileVideoThumbnail">
						<img data-key={ key } src={ video.thumbnail } />
						<div className={ "modalFaPlayBacker " + controlsBgClass }
								onClick={ () => this.handleShowExternalVideo(video) }>
							<FontAwesomeIcon icon={ faPlay } className={ "faPlay " + controlsBgClass } />
						</div>
						<div className="caption">
							{ parse(video.caption) }
						</div>
						<div className="fineprint">
							{ parse(video.subcaption) }
						</div>
					</div>
					<div className="brand pb-2">
						<img src="/img/ads/thin_energy/brand.png" />
					</div>
					<div>
						<span className="videoTitle pl-2">
							{ parse(video.title) }
						</span>
						<div className="videoCaption px-2 pb-3 pt-1">
							<p>{ parse(video.description) || "Be the first to like this video!" }</p>
						</div>
					</div>
				</div>
			)
		} else {
			//console.log("returning");
			return (
				<div className={ "col-md-4 col-sm-6 videoBox py-2 px-lg-4 px-md-3 px-sm-2 mx-2 text-center " + boxBgClass } key={ key }
					onClick={ (e) => { this.setActiveVideoBox(key, video) } }
					onMouseOver={ () => { this.setActiveVideoBox(key, video) } }
					onMouseOut={ () => { this.clearActiveVideoBox() } }>

					<div className={ "videoFeedbackButtons " + controlsBgClass }>
						<OverlayTrigger overlay={ likeTooltip } placement="right">
							<div className={ video.video_i_like ? "feedbackButtonActive" : "" } onClick={this.handleSubmitLike.bind(this, video.id)}>
								<FontAwesomeIcon icon={ faThumbsUp }/>
							</div>
						</OverlayTrigger>
						<OverlayTrigger overlay={ loveTooltip } placement="right">
							<div className={ video.video_i_love ? "feedbackButtonActive" : "" } onClick={this.handleSubmitLove.bind(this, video.id)}>
								<FontAwesomeIcon icon={ faHeart}/>
							</div>
						</OverlayTrigger>
						<div onClick={ () => this.handleShowGifts(video) }>
							<FontAwesomeIcon icon={ faGift }/>
						</div>
						<div onClick={ () => this.handleShowComments(video) }>
							<FontAwesomeIcon icon={ faComment }/>
						</div>
					</div>
					<div className="video-thumbnail tileVideoThumbnail" style={{backgroundImage: "url("+video.vimeo_thumbnail+")"}}>
						<img className="img-fluid" src="/img/blank-4x3.png" alt="" />
						<div className={ "modalFaPlayBacker " + controlsBgClass }
								onClick={ () => this.handleShowVideo(video.id) }>
							<FontAwesomeIcon icon={ faPlay } className={ "faPlay " + controlsBgClass } />
						</div>
					</div>
					<div className="videoDetails text-left pl-2">
						<div className="cumulativeLikeCount">
							<span>{ parseInt(video.like_count) + parseInt(video.love_count) }</span>
							<FontAwesomeIcon icon={ faStar } className="mx-1 faIcon" />
						</div>
						<div className="videoTitle text-left mt-2 mr-4">
							{ video.title }
						</div>
						<div className="videoCaption text-left py-3">
							<p>{ video.description || "Be the first to like this video!" }</p>
						</div>
					</div>

				</div>
			)
		}
	}

	buildVideoCarouselItem(video, video_key, type) {
		let vidTitleDescription = this.truncateTitleAndDescription(video.title, video.description);
		let controlsBgClass = (this.state.selectedVideoItem === video_key) ? "" : "hidden";
		let likeTooltip = <Tooltip className={ !video.video_i_like ? "hidden" : "" }>You already liked this!</Tooltip>;
		let loveTooltip = <Tooltip className={ !video.video_i_love ? "hidden" : "" }>You already loved this!</Tooltip>;

		if(type === "ad") {
			//console.log("AD");
			return (
				<div className="videoItemWrapper adItem" key={ video_key }
						onMouseOver={ () => this.expandVideoSliderItem(video_key) }
						onMouseOut={ () => this.closeVideoSliderItem(video_key) }>
					<div className= "video-item" id={ video_key } key={ video_key }>
						<div className="p-0 m-0 video-user-wrapper">
							<img className="video-user" data-key={ video_key } src={ video.profile_image } onError={ this.handleImgError } />
						</div>
						<div className="video-thumbnail">
							<img src={ video.thumbnail  } />
							<div className={ "faPlayBacker " + controlsBgClass } onClick= { () => {
									if(video.videoUrl)
										this.handleShowExternalVideo(video);
								} } >
								<FontAwesomeIcon icon={ faPlay } className="faPlay" />
							</div>
							<div className="caption">
								{ parse(video.caption) }
							</div>
							<div className="fineprint">
								{ parse(video.subcaption) }
							</div>
						</div>
						<div className="video-details">
								{/*<div className="cumulativeLikeCount">
								<span>{ parseInt(video.like_count) + parseInt(video.love_count) }</span>
								<FontAwesomeIcon icon={ faStar } className="mx-1 faIcon" />
								</div>*/}
							<span className="video-title">
							{
								parse(video.title)
							}
							</span>
							<span className="video-description">
							{
								parse(video.description)
							}
							</span>
						</div>
					</div>
				</div>
			)
		} else {
			//console.log("NOT AD");
			return (
				<div className="videoItemWrapper" key={ video_key }
						onMouseOver={ () => this.expandVideoSliderItem(video_key) }
						onMouseOut={ () => this.closeVideoSliderItem(video_key) }>
					<div className= "video-item" id={ video_key } key={ video_key }>
						<div className="p-0 m-0 video-user-wrapper">
							<img className="video-user" data-key={ video_key } src={ video.profile_image } onError={ this.handleImgError } />
						</div>
						<div className={ "videoFeedbackButtons " + controlsBgClass }>
							<OverlayTrigger overlay={ likeTooltip } placement="right">
								<div className={ video.video_i_like ? "feedbackButtonActive" : "" } onClick={this.handleSubmitLike.bind(this, video.id)}>
									<FontAwesomeIcon icon={ faThumbsUp }/>
								</div>
							</OverlayTrigger>
							<OverlayTrigger overlay={ loveTooltip } placement="right">
								<div className={ video.video_i_love ? "feedbackButtonActive" : "" } onClick={this.handleSubmitLove.bind(this, video.id)}>
									<FontAwesomeIcon icon={ faHeart}/>
								</div>
							</OverlayTrigger>
							<div onClick={ () => this.handleShowGifts(video) }>
								<FontAwesomeIcon icon={ faGift }/>
							</div>
							<div onClick={ () => this.handleShowComments(video) }>
								<FontAwesomeIcon icon={ faComment }/>
							</div>
						</div>
						<div className="video-thumbnail" style={{backgroundColor:"#222", backgroundImage: "url("+video.vimeo_thumbnail+")"}}>
							<img src="/img/blank.png" alt=""/>
							<div className={ "faPlayBacker " + controlsBgClass }
									onClick={ () => this.handleShowVideo(video.id) }>
								<FontAwesomeIcon icon={ faPlay } className={ "faPlay " + controlsBgClass } />
							</div>
						</div>
						<div className="video-details">
							<div className="cumulativeLikeCount">
								<span>{ parseInt(video.like_count) + parseInt(video.love_count) }</span>
								<FontAwesomeIcon icon={ faStar } className="mx-1 faIcon" />
							</div>
							<span className="video-title">{ vidTitleDescription[0] }</span>
							<span className="video-description">
							{
								vidTitleDescription[1]
							}
							</span>
						</div>
					</div>
				</div>
			)
		}
	}

	buildVideoCarousel(videos) {
		const responsive = {
			desktopLarge: {
				breakpoint: { max: 3000, min: 1200 },
				items: 4,
				slidesToSlide: 4,
			},
			desktop: {
				breakpoint: { max: 1200, min: 1024 },
				items: 3,
				slidesToSlide: 3,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
				slidesToSlide: 2,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
				slidesToSlide: 1,
			},
		};

		return (
			<div className="mb-4">
				<Carousel
					swipeable={true}
					draggable={true}
					showDots={false}
					responsive={responsive}
					keyBoardControl={true}
					transitionDuration={500}
					containerClass="carousel-container video-slider contest-slider videoCarousel"
					itemClass="video-slider-item"
					customRightArrow={<CustomRightArrow />}
					customLeftArrow={<CustomLeftArrow />}
				>
					{/* <div className="videoItemWrapper" key={ -1 }>
						this empty slide is necessary because the react multi carousel doesn't render the first slide
					</div> */}
					{videos && videos.map((video, video_key) => {
						//console.log("mapping " + video_key);
						//console.log(video);
						return this.buildVideoCarouselItem(video, video_key, video.type);
					})}
				</Carousel>
				{
					(this.state.videos && this.state.videos.length > 0) && !this.state.disableViewAll?
						<div className={ (this.state.showViewAllButton === false)
								? "dimmedButton contestViewAllBacking" : "contestViewAllBacking" }>
							<div className="contestViewAllButton" onClick={ this.redirectToViewAllPage }>
								VIEW ALL
							</div>
						</div>
					: ""
				}
			</div>
		)
	}

	buildVideoTiles(videos) {
		//console.log("buildVideoTiles");
		return (
			<Row className="row contentWidthConstraint">{/*pl-lg-5 pl-sm-2 pt-3 pr-1 */}
				<div className="col-sm px-5 pb-5">
					<div className="row text-center px-lg-5 px-sm-3 pt-3">
					{
						videos && videos.map((video, key) => {
							return this.buildVideoTileItem(video, key);
						})
					}
					</div>
					{
						this.state.showViewMoreButton ?
							<div className="contestViewMoreBacking mt-4">
							{
								!this.state.isLoading && this.state.videos.length > 0 ?
									<div className="contestViewMoreButton"
											onClick={ () => { this.setState({ isLoading: true }); this.state.nextPageCallback(); } }>
										VIEW MORE
									</div>
								: <div className="loading"></div>
							}
							</div>
						: ""
					}
				</div>
			</Row>
		)
	}


	buildTrendingVideos(videos) {
		// console.log("buildTrendingVideos", videos);

		const responsive = {
			desktopLarge: {
				breakpoint: { max: 3000, min: 1200 },
				items: 1,
				slidesToSlide: 1,
			},
			desktop: {
				breakpoint: { max: 1200, min: 1024 },
				items: 1,
				slidesToSlide: 1,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 1,
				slidesToSlide: 1,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
				slidesToSlide: 1,
			},
		};

		return (
			<div className="row no-gutters d-flex flex-wrap align-items-center trendingVideos" style={{backgroundColor: '#fff'}}>
				<div className="col-md-6">
					<div className="cy-white pt-4 px-4">
						<a href="/channels"><img className="img-fluid" src="/img/main/placeholder02.png" alt="" /></a>

						<div className="trending-thumbnail-container">

							{videos && videos.map((video, video_key) => {
								{/*console.log("mapping " + video_key, video);*/}
								return this.buildTrendingVideoPreviewItem(video, video_key, video.type);
							})}

						</div>

					</div>
				</div>
				<div className="col-md-6">
					<div className="cy-element cy-slanted slanted slanted-left slant-to-left">
						<Carousel
							autoPlay
							infinite
							swipeable={false}
							draggable={false}
							showDots={false}
							responsive={responsive}
							transitionDuration={1000}
							containerClass="trending-slider-container"
							itemClass="trending-slider-item"
							removeArrowOnDeviceType={["desktopLarge", "desktop", "tablet", "mobile"]}
							beforeChange={(nextSlide, { currentSlide, onMove }) => {
								{/*console.log("nextSlide", nextSlide);*/}
								// set correct slide id in infinite mode
								if (nextSlide === 7) {
									this.setState({ currentSlide: 1 });
								} else {
									this.setState({ currentSlide: nextSlide-1 });
								}
							}}
						>

							{videos && videos.map((video, video_key) => {
								{/*console.log("mapping " + video_key, video);*/}
								return this.buildTrendingVideoItem(video, video_key, video.type);
							})}

						</Carousel>
					</div>
				</div>
			</div>
		)
	}

	buildTrendingVideoItem(video, video_key, type) {
		console.log("buildTrendingVideoItem");
		let controlsBgClass = (this.state.selectedVideoItem === video_key) ? "" : "hidden";

		if(type === "youtube") {
			//console.log("AD");
			return (
				<div className="video-thumbnail" key={ video_key } style={{backgroundImage:'url('+video.thumbnail+')'}}
						onMouseOver={ () => this.expandVideoSliderItem(video_key) }
						onMouseOut={ () => this.closeVideoSliderItem(video_key) }>
					<img src="/img/blank-4x3.png" alt="" />
					<div className={"faPlayContainer " + controlsBgClass} onClick= { () => {
							if(video.videoUrl)
								this.handleShowExternalVideo(video);
						} } >
						<div className={ "faPlayBacker " + controlsBgClass }>
							<FontAwesomeIcon icon={ faPlay } className="faPlay" />
						</div>
						<p className="faPlayCaption"><b>{video.given_name +" "+ video.family_name}</b></p>
					</div>
				</div>
			)
		} else {
			//console.log("NOT AD");
			return (
				<div className="video-thumbnail" key={ video_key } style={{backgroundImage:'url('+video.thumbnail+')'}}
						onMouseOver={ () => this.expandVideoSliderItem(video_key) }
						onMouseOut={ () => this.closeVideoSliderItem(video_key) }>
					<img src="/img/blank-4x3.png" alt="" />
					<div className={"faPlayContainer " + controlsBgClass} onClick={ () => this.handleShowVideo(video.id) }>
						<div className={ "faPlayBacker " + controlsBgClass }>
							<FontAwesomeIcon icon={ faPlay } className="faPlay" />
						</div>
						<p className="faPlayCaption"><b>{video.given_name +" "+ video.family_name}</b></p>
					</div>
				</div>
			)
		}
	}

	buildTrendingVideoPreviewItem(video, video_key, type) {
		console.log("buildTrendingVideoPreviewItem");
		let controlsBgClass = (this.state.selectedVideoItem === video_key) ? "" : "hidden";

		if(type === "youtube") {
			//console.log("AD");
			return (
				<div className={"trending-thumbnail " + (this.state.currentSlide == video_key+1 ? 'active' : '') } style={{'backgroundImage':'url('+video.thumbnail+')'}} onClick= { () => {
							if(video.videoUrl)
								this.handleShowExternalVideo(video);
						} }>
					<img src="/img/blank-4x3.png" alt="" />
				</div>
			)
		} else {
			//console.log("NOT AD");
			return (
				<div className={"trending-thumbnail " + (this.state.currentSlide == video_key+1 ? 'active' : '') } style={{'backgroundImage':'url('+video.thumbnail+')'}} onClick= { () => {
							this.handleShowVideo(video.id)
						} }>
					<img src="/img/blank-4x3.png" alt="" />
				</div>
			)
		}
	}

	render() {
		const rpModalProps = {
			loggedIn: this.state.loggedIn,
			getComments: this.getComments,
			showVideo: this.state.showVideo,
			showVideoCallback: this.showVideoCallback,
			videoFinished: this.videoFinished,
			handleShowGifts: this.handleShowGiftsCallback,
			handleShowComments: this.handleShowCommentsCallback,
			handleSubmitLike: this.handleSubmitLike,
			handleSubmitLove: this.handleSubmitLove,
			handleSubmitComment: this.handleSubmitComment
		};

		let renderVideos;
		if (this.state.mode === "slider") {
			renderVideos = this.buildVideoCarousel(this.state.videos)
		} else if (this.state.mode === "trending") {
			renderVideos = this.buildTrendingVideos(this.state.videos)
		} else {
			renderVideos = this.buildVideoTiles(this.state.videos)
		}

		return (
			<>
			{
				<div id="toast" className={ this.getToastClass() + (this.state.mode === "slider"
						? " carouselToastStyle" : " tileToastStyle") }>
					{ this.state.toastMsg }
				</div>
			}
			{
				this.state.videos ? (
					<>
					{
						<div key={ this.state.showGifts }>
							{ this.state.showGifts === true ? this.buildGiftsModal() : "" }
						</div>
					}
					{
						!this.state.showGifts && this.state.showComments === true ? this.buildCommentsModal() : ""
					}
					{renderVideos}
					{
						this.state.showVideo === true ?
							<ReactPlayerModal {...rpModalProps} key = { this.state.currentVideo }
								currentVideo = { this.state.currentVideo } />
						: ""
					}
					</>
				) : ""
			}
			</>
		);
	}
}
