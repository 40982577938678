import React, { Component } from 'react';
import { Button, Modal, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Auth } from "aws-amplify";
import config from '../config.js';
import ReactPlayer from 'react-player';
import './Artists.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChevronRight, faChevronLeft, faStar
	, faThumbsUp, faHeart, faGift, faComment, faPlay, faCoins } from '@fortawesome/free-solid-svg-icons'

class ArtistsPage extends Component {

	constructor(props) {
		super(props)
		
		this.sortByVideoCount = this.sortByVideoCount.bind(this);
		this.sortByLikeCount = this.sortByLikeCount.bind(this);

		this.state = {
			artists: [],
			gifts: [],
			jwtToken: '',
			isLoading: true,
			selectedBoxKey: null,
			selectedArtist: null,
			sortType: "alphabetical",
			currentPage: 1,
			pageSize: 15,
			pagesLoaded: [],
			showViewMoreButton: true,
			toastMsg: "",
			toastSuccess: null,
			toastTime: 5000
		}
	};

	componentDidMount() {
		Auth.currentAuthenticatedUser()
			.then(response => {
				this.setState({ isAuthenticated: true });
				var jwtToken = response.signInUserSession.idToken.jwtToken;
				this.setState({ jwtToken: jwtToken});
				this.getArtistPageAuth(1);
			})
			.catch(error => {
				console.log("Auth.currentAuthenticatedUser error");
				console.log(error);
				this.getArtistPage(1);
			})
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	setActiveArtistBox(key, artist) {
		this.setState({ selectedBoxKey: key, selectedArtist: [artist.cognito_sub, `${artist.given_name} ${artist.family_name}`] });
	}

	clearActiveArtistBox() {
		this.setState({ selectedBoxKey: null })
	}

	toggleArtistBox(key, artist) {
		this.setActiveArtistBox(key, artist);
	}

	sortTypeToString(type) {
		switch(type) {
			case "alphabetical":
				return "Alphabetical";
			case "videocount":
				return "Video Count";
			case "likecount":
				return "Like Count";
		}
	}

	sortByVideoCount(a, b) {
		return this.sortByCount(a, b, "video_count");
	}

	sortByLikeCount(a, b) {
		return this.sortByCount(a, b, "like_count");
	}

	sortByCount(a, b, label) {
		var result = parseInt(b[label]) - parseInt(a[label]);
		return result / Math.abs(result);
	}

	sortByName(a, b) {
		var result = a.family_name.toLowerCase().localeCompare(b.family_name.toLowerCase());
		
		if(result === 0)
			return a.given_name.toLowerCase().localeCompare(b.given_name.toLowerCase());
		else
			return result;
	}

	sortArtists(type) {
		//console.log("sort " + type);
		const toSort = this.state.artists;
		
		if(type === "alphabetical") {
			console.log("alpha sort");
			toSort.sort(this.sortByName);
		} else if(type === "videocount") {
			toSort.sort(this.sortByVideoCount);
		} else if(type === "likecount") {
			toSort.sort(this.sortByLikeCount);
		}
		
		this.setState({ artists: toSort, sortType: type });
	}
	
	appendArtists(artists) {
		var temp = this.state.artists;
		temp = temp.concat(artists);
		this.setState({ artists: temp, isLoading: false, showViewMoreButton: (artists.length > 0) });
	}
	
	getArtistPageAuth(page) {
		let jwtToken = this.state.jwtToken;
		
		if(!this.state.pagesLoaded.includes(page)) {
			this.setState({ isLoading: true });		
			axios.get(config.apiGateway.URL + `/users/artists?page=${page}&size=${this.state.pageSize}`, { headers: {Authorization: jwtToken} })
				.then(result => {
					console.log("GET /users/artists success");
					console.log(result.data.data);
					let tempLoaded = this.state.pagesLoaded;
					tempLoaded.push(page);
					this.appendArtists(result.data.data);
					this.setState({ currentPage: page, pagesLoaded: tempLoaded, isLoading: false });
				})
				.catch(error => {
					console.log("GET /users/artists error");
					console.log(error);
				})
		}
	}
	
	getArtistPage(page) {
		if(!this.state.pagesLoaded.includes(page)) {
			this.setState({ isLoading: true });
			axios.get(config.apiGateway.URL + `/users/artists?page=${page}&size=${this.state.pageSize}`)
				.then(result => {
					console.log("GET /users/artists success");
					console.log(result.data.data);
					let tempLoaded = this.state.pagesLoaded;
					tempLoaded.push(page);
					this.appendArtists(result.data.data);
					this.setState({ currentPage: page, pagesLoaded: tempLoaded, isLoading: false });
				})
				.catch(error => {
					console.log("GET /users/artist serror");
					console.log(error);
				})
		}
	}
	
	getToastClass() {
		return (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden";
	}
	
	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}

	getGifts() {
		axios.get(config.apiGateway.URL+'/users/gifts', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /users/gifts success");
				console.log(result.data.data);
				this.setState({ gifts: result.data.data });
			})
			.catch(error => {
				console.log("GET /users/gifts error");
				console.log(error);
			})
	}

	redirectToArtist(artist) {
		if(artist)
			window.location = `/artist/${artist}`;
		else
			console.log("No artist selected for redirect");
	}	
	
	getMe() {
		axios.get(config.apiGateway.URL+'/users/me', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /users/me success");
				console.log(result.data.data)
				this.setState({ totalToken: result.data.data.tokens });
			})
			.catch(error => {
				console.log("GET /users/me error");
				console.log(error);
			})
	}	
	
	handleCloseGifts() {
		this.setState({ showGifts: false });
	}

	handleSubmitGift = () => {
		if(!this.state.giftId || !this.state.selectedArtist[0]) {
			console.log("invalid submit gift arguments");
			return;
		}
		if(this.state.isAuthenticated)
			axios.post( config.apiGateway.URL + '/users/' + this.state.selectedArtist[0] + '/gift/' + this.state.giftId,
				{},
				{ headers: { 'Authorization': this.state.jwtToken }
				})
				.then(result => {
					console.log("POST /users/{user_sub}/gift/{gift_id} success");
					console.log(result.data.data);
					this.makeToast("Gift sent! Thanks for showing your support.", true);
				})
				.catch(error => {
					console.log("POST /users/{user_sub}/gift/{gift_id} error");
					console.log(error);
					
					if(error.response && error.response.data && error.response.data.data) {
						if(error.response.data.data.details === "User cannot afford to give gift")
							this.makeToast(error.response.data.data.public, null);
						else if(error.response && error.response.data.data.public === "You cannot give a gift to your own video")
							this.makeToast(error.response.data.data.public, null);
					}
				})
		else
			this.makeToast("Signup or login to give a gift");
	}
	
	async handleShowGifts() {
		if(!this.state.isAuthenticated) {
			this.makeToast("Signup or login to send a gift");
			return;
		}

		this.setState({ disableSend: true, showGifts: true });
		await this.getMe(); // for tokens
		await this.getGifts(); // for gift options
		this.setState({ disableSend: false });
	}
	
	buildGiftsModal() {
		return (
			<Modal size="lg" className="modalDimmer" show={ this.state.showGifts } onHide={() => this.handleCloseGifts() }>
				{/*<div id="toast" className={ "modalToastStyle " + this.getToastClass() }>{ this.state.toastMsg }</div>*/}
				<Modal.Header closeButton style={{backgroundColor: '#222'}}>
					<img className="img-fluid" src="/img/gifts-header.jpg" width="" alt="" />
				</Modal.Header>
				<Modal.Body>
					<div className="row justify-content-center align-items-center px-2">
						<h3 className="giftsHeaderText">
							SHOW YOUR LOVE
						</h3>
						<div className="p-2 giftsHeaderSubtitle inline-block">
							Spend your points and show an artist how much they rock!
						</div>
					</div>
					<div className="row px-4 mt-4">
						<div className="col-md-6">
							<h4 className="mr-4 text-uppercase font-weight-bold d-inline-block">Give a gift to </h4>
							<b className="capitalize">{ this.state.selectedArtist ? this.state.selectedArtist[1] : "" }</b>
						</div>
						<div className="col-md-6 form-group">
						</div>
					</div>
					<div className="row pt-2 px-4">
						<div className="col-md-9 form-group giftsSelect">
							<select id="giftId" name="giftId" onChange={ this.handleChange } className="form-control" disabled={this.state.disableSend}>
								<option value="">Select a gift</option>
								{
									this.state.gifts && this.state.gifts.map((gift, gift_key) => {
										return (
											<option value={ gift.id } key={ gift_key }>{ gift.name } (cost: { gift.cost })</option>
										)
									})
								}
							</select>
						</div>
						<div className="col-md-3 text-right">
						{
							this.state.totalToken !== undefined ? (
							<>
								<div className="text-uppercase font-weight-bold" key={ this.state.totalToken }>Your tokens</div>
								<span className="faTokens" > 
									<span className="tokenCount">
										<FontAwesomeIcon icon={ faCoins } />
										<span>{ this.state.totalToken }</span>
									</span>
								</span>
							</>
							) : ""
						}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => this.handleCloseGifts() }>
						Close
					</Button>
					<Button variant="primary" className="ml-3 genericWhiteButton" onClick={this.handleSubmitGift} disabled={this.state.disableSend}>
						SEND IT!
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	render() {
		const signupBgStyle = {
			'backgroundRepeat': "no-repeat",
			'backgroundSize': '100%',
			'backgroundImage': "url('/img/artists/artists-bg.jpg')"
		};

		return (
			<div className="container-fluid artistsPageContainer p-0 m-0">
				<div className="content" style={ signupBgStyle }>
					<div className="row pl-5 pt-4">
						<div className="col pt-5">
							<h1 className="artistsHeader mt-md-5 mt-sm-3 px-lg-5 px-sm-3 d-inline-block">ARTISTS</h1>
							<div className="sortingControlBar mr-5 d-inline-block">
								<span className="mr-4">Sorted By</span>
								<DropdownButton className="sortDropdown d-inline" title={ this.sortTypeToString(this.state.sortType) }>
									<Dropdown.Item onClick={ () => { this.sortArtists("alphabetical") } }>Alphabetical</Dropdown.Item>
									<Dropdown.Item onClick={ () => { this.sortArtists("videocount") } }>Video Count</Dropdown.Item>
									<Dropdown.Item onClick={ () => { this.sortArtists("likecount") } }>Like Count</Dropdown.Item>
								</DropdownButton>
							</div>
						</div>
					</div>
					{
						<div id="toast" className={ this.getToastClass() +  " tileToastStyle" }>
							{ this.state.toastMsg }</div>
					}				
					<div className="row ml-lg-5 ml-md-4 ml-sm-3 mt-lg-4 mt-sm-1 p-1">
						{ this.state.artists && this.state.artists.map((artist, artist_key) => {
							//console.log(" selected " + this.state.selectedBoxKey);
							//console.log(" key " + artist_key);
							let boxBgClass = (this.state.selectedBoxKey === artist_key) ? "activeBg" : (this.state.selectedBoxKey !== null ? "dimBg" : "");
							//console.log(" class " + boxBgClass);
							let controlsBgClass = (this.state.selectedBoxKey === artist_key) ? "" : "hidden";
							let tooltip = <Tooltip>Visit this artist's page to like their songs!</Tooltip>;
							
							return (
								<div className={ "col-md-4 col-sm-6 artist-box py-2 px-lg-5 px-md-4 px-sm-4 text-center " + boxBgClass } key={ artist_key }
										onMouseOver={ () => { this.setActiveArtistBox(artist_key, artist) } }
										onMouseOut={ () => { this.clearActiveArtistBox() } }
										onClick={ () => { this.toggleArtistBox(artist_key, artist) } }>
									<div className={ "artistFeedbackButtons " + controlsBgClass }>
										<div onClick={ () => this.handleShowGifts(this.state.selectedArtist) }>
											<FontAwesomeIcon icon={ faGift }/>
										</div>
										<OverlayTrigger placement="top" overlay={ tooltip }>
											<div onClick={ () => this.redirectToArtist(this.state.selectedArtist[0]) }>
												<FontAwesomeIcon icon={ faThumbsUp }/>
											</div>
										</OverlayTrigger>
										<OverlayTrigger placement="top" overlay={ tooltip }>
											<div onClick={ () => this.redirectToArtist(this.state.selectedArtist[0]) }>
												<FontAwesomeIcon icon={ faHeart}/>
											</div>
										</OverlayTrigger>
									</div>
									<div className="artist-portrait p-2">
										{ artist.profile_image
										? <img src={ artist.profile_image } className="profileAvatar center my-3" />
										: <img src="/img/profile/default-profile.png" className="profileAvatar center my-3" />
										}
									</div>
									<div className="artist-info text-left">
										<div className="artist-title">
											<a href={ '/artist/' + artist.cognito_sub } className="text-left">
												<h4 className="artistName d-inline-block">
													<b>{ artist.given_name } { artist.family_name }</b>
													<FontAwesomeIcon icon={ faChevronRight } className="ml-2 d-inline text-white artistChevron"/>
												</h4>
											</a>
										</div>
										<span className="artistSubtitle text-left pl-2">
											{ artist.video_count } videos
										</span>
										<div className="artistBio text-left px-2 py-3">
											<p>{ artist.bio || <i>Artist does not have a biography.</i> }</p>
										</div>
									</div>
								</div>
							)
						})}
					</div>
					{
						this.state.artists && this.state.showViewMoreButton ? 
							<div className="contestViewMoreBacking mt-4">
							{
								!this.state.isLoading ?
									<div className="contestViewMoreButton" onClick={ () => this.getArtistPage(this.state.currentPage + 1) }>
										VIEW MORE
									</div>
								: <div className="loading"></div>
							}
							</div>
						: ""
					}
					{
						this.buildGiftsModal()
					}
					{ 
						this.props.footer
					}
				</div>
			</div>
		)
	}
}

export default ArtistsPage;
