import React, { Component, useCallback } from 'react';
import { Container, Row, Col, Form, FormControl, Modal, Button, Spinner, Dropdown } from 'react-bootstrap';
import { Auth } from "aws-amplify";
import { useDropzone } from 'react-dropzone'
import axios from "axios";
import config from '../config';
import './VideoUpload.css';

function DragAndDropDialog(props) {
	const onDrop = useCallback(acceptedFiles => {
		props.callback(acceptedFiles, props.ddkey);
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div { ...getRootProps() } className={ props.styles }>
			<input { ...getInputProps() } />
			{
				isDragActive ?
					<span>Drop the file here ...</span> : (
						props.selectedFile ?
							<div className="m-2">{ props.selectedFile }</div>
						: (
							<div className="ddBorder m-0 py-5 px-2">
								<div>
									{ props.prompt }, or
									<span className="text-info m-2">Browse</span>
								</div>
								{
									(props.format) ? (
										<>
											<span className="ddDialogFormat py-2 text-muted">{ props.format }</span>
											{
												(props.dims) ? <span className="ddDialogFormat px-2">{ props.dims }</span> : ""
											}
										</>
									) : ("")
								}
							</div>
						)
					)
			}
		</div>
	);
}

export default class VideoUpload extends Component {
	// inline styles
	formStyle = {
		padding: '15px 5px'
	}

	labelStyle = {
		'fontSize': '1.1em',
		'paddingTop': '2px'
	}

	rowStyle = {
		padding: '5px 0'
	}

	uploadDialogStyle = {
		padding: '10px 5px'
	}

	toastStyle = {
		padding: '15px',
		'fontSize': '1.3em',
		'borderRadius': '5px',
		color: '#FFFFFF'
	}
	
	constructor(props) {
		super(props);
		this.dragAndDropFileChange = this.dragAndDropFileChange.bind(this);

		this.state = {
			fileName: "",
			fileData: {},
			imageFileName: "",
			imageFileData: {},
			songTitle: "",
			songDescription: "",
			songTags: [],
			contestId: props.contestId,
			contestSelectPlaceholder: "Select a contest",
			preuploadData: {},
			preuploadDone: false,
			videoIsUploading: false,
			imageIsUploading: false,
			showModalCallback: props.showModalCallback,
			toastMsg: "",
			toastTime: 5000
		};

		this.getGenres();
		this.getContests();
	}


	/*
		Add a genre to the current song upload.
	*/
	addTag(tag) {
		const temp = this.state.songTags;
		temp.push(tag);
		this.setState({ songTags: temp });
		//console.log("new song tags after add");
		//console.log(temp);
	}

	/*
		Remove a genre from the current song upload.
	*/
	removeTag(id) {
		var temp = this.state.songTags;
		// linear search because search space is small
		for(var i = 0; i < temp.length; i++) {
			if(temp[i].id === id) {
				temp = temp.slice(0, i).concat(temp.slice(i + 1, temp.length));
				break;
			}
		}
		this.setState({ songTags: temp });
		//console.log("new song tags after remove");
		//console.log(temp);
	}
	
	getContests() {
		const endpoint = `${config.apiGateway.URL}/contests/current`;

		axios.get(endpoint, {}, {}).then(response => {
			this.setState({ contests: response.data.data });
		}).catch(error => {
			console.log(error);
		});
	}
	
	getGenres() {
		const endpoint = `${config.apiGateway.URL}/videos/genres`;

		axios.get(endpoint, {}, {}).then(response => {
			this.setState({ genreData: response.data.data });
		}).catch(error => {
			console.log(error);
		});

		//axios.post(endpoint, data, { headers: headers })
	}



	/*
		Make the POST request to the upload endpoint to get the final S3 upload URL.
	*/
	uploadVideo() {
		// first option in select is placeholder - ignore input if value is not integer
		if(this.state.fileName && this.state.fileName !== ""
				&& this.state.contestId !== this.state.contestSelectPlaceholder) {

			// if they selected an invalid file, abort the save
			if(!this.fileValidation(this.state.fileName)) {
				//console.log("invalid file extension for video");
				this.makeToast("Invalid video file extension format", false);
				return;
			}

			// verify all required fields are not empty
			if(this.state.songTitle === ""
					|| this.state.songDescription === ""
					|| this.state.contestId === ""
					|| this.state.songTags.length < 1) {
				//console.log("missing video title, description, contest ID, or tags");
				this.makeToast("Video title, description, and tags are required fields", false);
				return;
			}
			
			this.setState({ videoIsUploading: true });
			
			// if it exists, get image type
			var imageType;
			if(this.state.imageFileName)
				imageType = this.state.imageFileName.split(".")[1];

			// get song file type
			const type = this.state.fileName.split(".")[1];

			// build request body
			const data = {
				title: this.state.songTitle,
				description: this.state.songDescription,
				contest_id: this.state.contestId,
				file_extension: type,
				genres: this.state.songTags.map(val => { return val.id }),
				...(imageType && { image_file_extension: imageType })
			};

			//console.log("request body");
			//console.log(data);
			//alert("");

			// get token from auth and make call
			Auth.currentAuthenticatedUser().then(response => {
				var jwtToken = response.signInUserSession.idToken.jwtToken;
				const endpoint = config.apiGateway.URL + "/videos/unmoderated";
				const headers = {
					'Authorization': jwtToken,
				};

				axios.post(endpoint, data, { headers: headers })
					.then(result => {
						console.log("POST videos/unmoderated success");
						console.log(result)

						this.setState({ uploadName: result.data.data.file_name,
							uploadURL: result.data.data.presigned_url,
							...(result.data.data.image_file_name, { imageUploadName: result.data.data.image_file_name }),
							...(result.data.data.image_presigned_url, { imageUploadURL: result.data.data.image_presigned_url})
						});

						this.doFinalVideoUpload();
						this.doFinalImageUpload();
					})
					.catch(error => {
						console.log("POST videos/unmoderated error");
						console.log(error);
					})
			})
		} else {
			//console.log("file name or contest ID are invalid");
			this.makeToast("File name is invalid", false);
		}
	}

	doFinalImageUpload() {
		if(!this.state.imageUploadName || ! this.state.imageUploadURL) {
			console.log("No presigned upload name or URL for image");
			return;
		}

		// rename file
		this.setState({ imageFileName: this.state.imageUploadName, imageIsUploading: true });
		// get mime type of file
		var mime = require('mime-types');
		var type = mime.lookup(this.state.imageUploadName);
		// fileData[0] is the file chosen by the file selector dialog
		const newFile = new File([this.state.imageFileData[0]], this.state.imageUploadName, { type: type });
		// make request and update state
		const headers = { 'Content-Type': type };

		console.log("DOING FINAL UPLOAD FOR IMAGE");
		//console.log(headers);
		//console.log(type);
		//console.log(newFile);

		axios({
			method: 'PUT',
			url: this.state.imageUploadURL,
			headers: headers,
			data: newFile
		})
		.then((response) => {
			//console.log("PUT success");
			//console.log(response);
			//this.makeToast("Image uploaded successfully!", true);
			this.setState({ imageIsUploading: false });
			this.resetForm();
		})
		.catch((error) => {
			console.log("PUT error");
			console.log(error);
			this.makeToast("Error uploading image", false);
		});
	}

	/*
		After preupload has returned the new file name and upload URL, make the PUT call.
	*/
	doFinalVideoUpload() {
		if(!this.state.uploadName || ! this.state.uploadURL) {
			console.log("No presigned upload name or URL for video");
			return;
		}

		// rename file
		this.setState({ fileName: this.state.uploadName, videoIsUploading: true  });
		// get mime type of file
		var mime = require('mime-types');
		var type = mime.lookup(this.state.uploadName);
		// fileData[0] is the file chosen by the file selector dialog
		const newFile = new File([this.state.fileData[0]], this.state.uploadName, {type: type});
		// make request and update state
		const headers = { 'Content-Type': type };

		console.log("DOING FINAL VIDEO UPLOAD");
		console.log(headers);
		console.log(type);
		console.log(newFile);

		axios({
			method: 'PUT',
			url: this.state.uploadURL,
			headers: headers,
			data: newFile
		})
		.then((response) => {
			//console.log("PUT success");
			//console.log(response);
			this.makeToast("Video uploaded successfully!", true);
			this.setState({ videoIsUploading: false });
			this.resetForm();
		})
		.catch((error) => {
			console.log("PUT error");
			console.log(error);
			this.makeToast("Error uploading video...", false);
		});
	}


	/*
		Reset the upload form.
	*/
	resetForm() {
		document.getElementById("videoUploadForm").reset();

		// clear all video-related state variables
		this.setState({ fileName: "", fileData: {}, songTitle: "",
			songDescription: "", contestId: "", preuploadData: {}, preuploadDone: false });
	}


	/*
		Validates a selected file to ensure it's an accepted format.
	*/
	fileValidation(path){
		path = path.toLowerCase();
		//console.log(path);
		// common video formats
		var allowedExtensions = /(\.webm|\.mpg|\.mp2|\.mpeg|\.mpe|\.mpv|\.ogg|\.mp4|\.m4p|\.m4v|\.avi|\.wmv|\.mov|\.qt|\.flv|\.swf|\.avchd)$/i;
		return allowedExtensions.exec(path);
	}


	/*
		Change handler for the file selector input.
	*/
	handleFileChange(e) {
		this.setState({ fileName: e.target.value, fileData: e.target.files });
	}

	dragAndDropFileChange(files, key) {
		console.log("dragAndDropFileChange");
		console.log(files);

		if(key === "song") {
			console.log("setting song");
			this.setState({ fileName: files[0].name, fileData: files });
		} else if(key === "picture") {
			console.log("setting picture");
			this.setState({ imageFileName: files[0].name, imageFileData: files });
		}

		//this.setState({ fileName:
	}

	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(true);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}


	buildModal(props) {
		const toastClass = (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success toastStyle"
			) : (
				this.state.toastSuccess === false ? "bg-danger toastStyle" : "bg-info toastStyle"
			)
		) : "hidden";

		return (
			<Modal centered show={ true } size="lg" className="videoUploadModal" onHide={ this.state.showModalCallback }>
				<Modal.Header closeButton className="uploadModalBg border-bottom-0 p-0">
					<Modal.Title id="contained-modal-title-vcenter" className="uploadModalTitle text-center w-100">
						UPLOAD NEW SONG
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="uploadModalBg text-center p-0">
					<div id="toast" className={ toastClass } style={ this.toastStyle }>{ this.state.toastMsg }</div>
						{/*<Container>
						<Row style={ this.rowStyle }>
							<Col>

							</Col>
						</Row>
						</Container>*/}
					{
						props.uploadForm
					}
				</Modal.Body>
				<Modal.Footer className="uploadModalBg">
					<Button onClick={ this.state.showModalCallback } className="uploadButton closeButton">Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	componentDidMount() {
		//console.log("mounted");
	}
	
	render() {
		const uploadForm = this.buildUploadForm();
		const modalWindow = this.buildModal({ uploadForm: uploadForm });

		return (
			<Container>
			{
				modalWindow
			}
			</Container>
		);
	}

	handleSelectChange(e) {
		this.setState({ contestName: e.target.value});
		console.log("setting new contest " + e.target.value);
	}
	
	/*
		Render the video upload form.
	*/
	buildUploadForm() {
		return (
			<Container className="w-75">
				<Form id="videoUploadForm" style={ this.formStyle }>
					<Row>
						<Col>
							<Row>
								<Col>
									<Form.Group className="mb-4 contestSelectGroup">
										<Dropdown>
											<Dropdown.Toggle id="dropdown-basic" className="contestSelectInput">
												{ this.state.contestName ? this.state.contestName : "Select a contest" }
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{
													this.state.contests ? this.state.contests.map(contest => {
														return <Dropdown.Item href="#" key={ contest.id } className="contestSelectOption"
															onClick={ () => { this.setState({ contestName: contest.name, contestId: contest.id }) } }>{ contest.name }</Dropdown.Item>
													}) : ""
												}
											</Dropdown.Menu>
										</Dropdown>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormControl
										type="text"
										value={ this.state.songTitle }
										placeholder="Song title"
										className="videoUploadTextInput"
										onChange={ (e) => {
											this.setState({ songTitle: e.target.value });
										} }/>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormControl
										as="textarea"
										rows="4"
										value={ this.state.songDescription }
										placeholder="Song Description (120 characters)"
										className="videoUploadTextInput videoUploadTextArea"
										onChange={ (e) => {
											this.setState({ songDescription: e.target.value });
										} }/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col>
									<div className="songTagsLabel">My Song Genres <span className="text-muted">(click to remove)</span></div>
									<div className="videoUploadTextInput videoUploadTextArea">
									{
										(this.state.songTags.length > 0) ? (
											this.state.songTags.map(val => {
												return (
													<div className="tagButton" key={ val.name }onClick={ (e) => { this.removeTag(val.id) } }>
														{ val.name }
													</div>
												)
											})
										) : ( "" )
									}
									</div>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col>
									<div className="songTagsLabel">All Song Genres <span className="text-muted">(click to add to your song)</span></div>
									<div className="videoUploadTextInput videoUploadTextArea">
									{
										(this.state.genreData) ? (
											this.state.genreData.map(val => {
												return (
													<div className="tagButton" onClick={ (e) => { this.addTag(val) } } key={ val.name }>
														{ val.name }
													</div>
												)
											})
										) : ( "" )
									}
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<DragAndDropDialog callback={ this.dragAndDropFileChange }
										prompt="Drag your video file here"
										format="Supports: mp4, avi, webm"
										dims=""
										styles="dragAndDropDialog videoUploadTextInput"
										ddkey="song"
										selectedFile={ this.state.fileName }/>
								</Col>
							</Row>
							<Row>
								<Col>
									<DragAndDropDialog callback={ this.dragAndDropFileChange }
										prompt="Drag your song image here"
										format="Supports: jpg, png"
										dims="Dimensions: 800 x 800"
										styles="dragAndDropDialog videoUploadTextInput"
										ddkey="picture"
										selectedFile={ this.state.imageFileName }/>
								</Col>
							</Row>
							<Row>
								<Col>
								{
									(this.state.videoIsUploading || this.state.imageIsUploading) ? (
										<>
											<span className="uploadingMessage pb-3">Uploading, please wait</span>
											<div className="m-2 d-inline-block spinnerContainer">
												<Spinner animation="grow" variant="primary"/>
											</div>
										</>
									) :
										<button className="uploadButton uploadModalBg my-4" onClick = { (e) => {
											e.preventDefault();
											this.uploadVideo()
										} }>
											Upload new song
											<div className="glyphicon glyphicon-plus"></div>
										</button>
								}
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
}