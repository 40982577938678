import React, { Component } from 'react';
import styled from 'styled-components';

// inline stylesheet
const Styles = styled.div`
	html, body {
		height: 100%;
	}
	.crowd-bg {
		width: 100%;
		background: #fff url('/img/bg-crowd.jpg') no-repeat right bottom;
	}
	.container {
		max-width: 100%;
	}
	.content {
		width: 70%;
		margin: auto;
	}
	p {
		font-size: 14px;
		padding-top: 20px;
	}
	@media only screen and (max-width: 768px) {
		/* For mobile phones: */
		.content {
			width: 100%;
			text-align: center;
		}
		p {
			text-align: left;
		}
		[class*="col-"] {
			width: 100%;
		}
		img {
			width: 90%;
		}
	}
`;

// main renderer
class ContestRules extends Component {
	render() {
		return (
			<Styles>
				<div className="container">
					<div className="row content">
						<div className="col-md-12">
							<p className="text-center">
								<img className="img-fluid" src="/img/contest-rules-header.png" alt="Contest Rules" />
							</p>
							<p>Welcome to CelebYou.com (“CelebYou” or “Website”).  The singing contest on this Website (“Contest”) is operated by CelebYou, LLC.</p>
							<p><strong>Eligibility for Entering Contest</strong> The Contest is open to all U.S. Residents, and may include both amateur and professional singers.  However, employees of CelebYou, LLC, their families, subsidiaries, and affiliates are not eligible.Importantly, any contestants under the age of 18 MUST have written permission from their parent or guardian to enter the contest and to create an account on this Website.  Any grand prize winner under age 18 must also have an adult chaperone accompany them throughout the entirety of the grand prize trip.</p>
							<p><strong>Submissions by Mail</strong> If you wish to enter the Contest by mail, each entry must include: (a) a Completed entry form, with your original signature, and the original signature of your parent or guardian, if you are under 18.  (b) CD or Audio Cassette(s) containing one (1) song only, 5 minutes or less in length.  (c) A check or money order for $4.99 (US currency only).  All entries must be made on or before the contest deadline.Mail all entries to: CelebYou, LLC, Street, City, State, Zip.  Cassettes and CDs or other physical entries received in the mail will not be returned.  All contestants, including the winner, will retain full rights to their original songs submitted for the Contest.</p>
							<p><strong>Entry Fees</strong> Each entry is charged a service fee of $4.99, and is subject to change.  The service fee is non-refundable.  CelebYou is not responsible for late, lost or damaged, misdirected, postage due, stolen or misappropriated entries.</p>
							<p><strong>Determining a Contest Grand Prize Winner</strong> The Contest grand prize winner will be chosen by a Judging Committee comprised of music industry professionals, including A&amp;R managers from record labels, publishers, producers, and artists.  Contest entries are judged equally, whether submitted as a video or audio entry; the entry will be judged on the vocal quality of the performance, and production will not be considered.  Original songs may be in any language.Winners will be notified online or by mail, depending on the contact information provided, and must sign and return an affidavit confirming that winner’s song is original and he/she holds rights to the song. Failure to sign and return the form within 30 days will result in immediate disqualification and an alternative winner will be selected.  Entry constitutes permission to use winner’s name, likeness and voice for future publicity and advertising purposes without additional compensation.  Winners will be determined by vocal skills and originality.  After the winner is determined, each entrant will receive a winners list by e-mail.If you enter as a duo or group, travel is only provided to one contestant (and their chaperone, if a minor), and determination of the contestant who gets that travel is the responsibility of the winners.  If others in the group cover their own travel, they may also take advantage of the recording prize as a group.The winner(s) releases CelebYou and its sponsors from all liability regarding prizes won.  Any taxes are the winner’s responsibility.</p>
							<p><strong>Extension of Rules </strong>CelebYou reserves the right to extend the deadline of the Contest if needed.</p>
							<p><strong>Communications and Deadlines </strong>No reminders will be given regarding deadlines.  Follow-through is the responsibility of each contestant.  Contestants will receive replies to all emails and submissions within several days.  If a reply is not received, please resend your information or inquiry.</p>
							<p><strong>Cancellation or Delay of Contest </strong>In the event that the Contest is cancelled or delayed for any reason, we are not responsible for any costs associated with travel or accommodations, or for any other losses arising from the cancellation, including entry fees.  We will do our very best to alert everyone in advance about possible changes.</p>
							<p><strong>Prizes</strong> There will be five (5) finalists in every ninety (90) day contest period that will compete for the grand prize. Each of the five finalists will receive $100.00. The grand prize is a personal songwriting and recording session in Nashville with true industry professional. You will get the opportunity to collaborate with longtime industry professionals such as songwriters, musicians and producers. The grand prize is inclusive of a roundtrip coach flights and reasonable accommodations for two (2) nights for the winner and one guest.  If the winner is a minor, the guest will need to be a parent or legal guardian who can accompany the minor throughout the entire trip. Prizes and the distribution of prizes are subject to change depending on the final number of entries. Sponsor prizes are the sole responsibility of the sponsors. Winners must contact prize sponsors immediately and comply with all prize sponsor requirements. CelebYou disclaims all responsibility for the delivery, content, and quality of the sponsor prizes. Sponsor prizes are non-transferable and have no cash value. Prizes may be subject to tax. Some prizes are offered locally and winners must agree to travel to sponsor’s location (or as otherwise directed by the sponsor) to claim their prizes. CelebYou and its respective sponsors are independent of each other and neither have the right to contractually bind the other. Nothing herein obligates CelebYou to provide any talent agent services, artist development services, referrals or promotion or endorsement of any kind to you.</p>
						</div>
					</div>
				</div>

				<div className="container">
					{ this.props.footer }
				</div>
			</Styles>
		)
	}
}

export default ContestRules;