import React, { Component, Fragment } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Auth } from "aws-amplify";
import ReactPlayer from 'react-player';
import config from "../config";
import './Artist.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import VideoCarousel from './VideoCarousel';

class ArtistPage extends Component {

	constructor(props) {
		super(props)
		
		this.state = {
			isAuthenticated: false,
			jwtToken: '',
			user: [],
			videos: [],
			currentVideo: [],
			isLoading: true,
			showViewMoreButton: false,
			toastMsg: "",
			toastTime: 5000
		}
	};

	componentDidMount() {
		const { cognitoSub } = this.props.match.params;
		//console.log(cognitoSub)

		Auth.currentAuthenticatedUser()
			.then(response => {
				//console.log("Auth.currentAuthenticatedUser success");
				//console.log(response);
				var jwtToken = response.signInUserSession.idToken.jwtToken;
				this.setState({ isAuthenticated: true, jwtToken: jwtToken });

				axios.get(config.apiGateway.URL+'/users/' + cognitoSub, { headers: {Authorization: jwtToken} })
					.then(result => {
						console.log("GET /users/:cognitoSub success");
						console.log(result)
						this.setState({ user: result.data.data });
						this.setState({ videos: this.state.user.videos, isLoading: false });
					})
					.catch(error => {
						console.log("GET /users/:cognitoSub error");
						console.log(error);
					})
			})
			.catch(error => {
				console.log("Auth.currentAuthenticatedUser error");
				console.log(error);

				axios.get(config.apiGateway.URL+'/users/' + cognitoSub)//unauth
					.then(result => {
						console.log("GET /users/:cognitoSub success");
						console.log(result);
						this.setState({ user: result.data.data });
						this.setState({ videos: this.state.user.videos, isLoading: false });
					})
					.catch(error => {
						console.log("GET /users/:cognitoSub error");
						console.log(error);
					})
			})
	}
	
	backToArtistsClickHandler(e) {
		window.location = "/artists";
	}
	
	nextPageCallback() {
		console.log("artist nextPageCallback");
	}
	
	redirectCallback(sub) {
		console.log("artist redirectCallback");
	}
	
	redirectToArtists() {
		window.location = "/artists";
	}
	
	getToastClass() {
		let toastClass = (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden";
		
		return toastClass;
	}
	
	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}
	
	render() {
		console.log(this.state.user);
		let videoCarouselProps = {
			mode: "tile",
			videos: this.state.videos,
			nextPageCallback: this.nextPage,
			showViewMoreButton: this.state.showViewMoreButton,
			redirectCallback: this.redirectCallback,
			redirectId: this.state.user.cognito_sub,
			isAuthenticated: this.state.isAuthenticated,
			jwtToken: this.state.jwtToken,
			...(this.state.user && {selectedArtist: [this.state.user.cognito_sub, `${this.state.user.given_name} ${this.state.user.family_name}`] }),
			...(this.state.selectedVideo && {selectedVideo: this.state.selectedVideo })
		};
		
		const signupBgStyle = {
			'backgroundRepeat': "no-repeat",
			'backgroundSize': '100%',
			'backgroundImage': "url('/img/artists/artists-bg.jpg')",
			'width': '100%'
		};
		
		const hideStyle = {
			display: 'none'
		};

		const responsive = {
			desktopLarge: {
				breakpoint: { max: 3000, min: 1200 },
				items: 4,
				slidesToSlide: 4,
			},
			desktop: {
				breakpoint: { max: 1200, min: 1024 },
				items: 3,
				slidesToSlide: 3,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
				slidesToSlide: 2,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
				slidesToSlide: 1,
			},
		};
		
		return (
			this.state.isLoading ?
				<div className="loading"></div>
			:	
				<div className="container artistWrapper">
					<div className="content" style={ signupBgStyle } className="p-5">
						<div className="row pt-5 pl-lg-5 pl-sm-3">
							<div className="col-sm-12 pt-5">
								<div className="artistsBackControl m-4" onClick={ this.backToArtistsClickHandler }>
									<FontAwesomeIcon icon={ faChevronLeft }/> Back to Artists
								</div>
								<h1 className="artist-name">{ this.state.user.given_name } { this.state.user.family_name }</h1>
							</div>
						</div>
						<div className="row p-3 pl-lg-5 pl-sm-3">
							<div className="col-sm-4 text-center d-inline-block">
								{ this.state.user.profile_image
									? <img src={this.state.user.profile_image} className="img-fluid w-100 profileImage mt-1" alt="Profile image" />
									: <img src="/img/profile/default-profile.png" className="img-fluid profileImage mt-1" alt="Default profile image" />
								}
								{ this.state.user.cover_image
									? <img src={this.state.user.cover_image} className="img-fluid w-100 mt-3 profileImage" alt="Cover image" />
									: ""
								}
							</div>
							<div className="col-sm-7 col-lg-8 bioPanel pl-5 p-3">
								<h4 className="text-white"><b>BIOGRAPHY</b></h4>
								<p>{ this.state.user.bio || "This artist does not have a biography."}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h3 className="text-white artistVideosHeader p-3 pl-lg-5 pl-sm-3">VIDEOS</h3>
							</div>
							<div className="container videoCarouselContainer">
								<div className="row">
									<div className="col-12 mb-5 p-0">
									{
										!this.state.isLoading ? <VideoCarousel { ...videoCarouselProps } /> : ""
									}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		)
	}
}

export default ArtistPage;
