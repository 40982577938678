import React, { Component } from 'react';
import { Dropdown, DropdownButton, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Auth } from "aws-amplify";
import config from '../config.js';
import VideoCarousel from './VideoCarousel.js';
import './Channels.css';
import './SingleContest.css';

// delay function for resolving toast promises
/*const delay = t => new Promise(resolve => setTimeout(resolve, t));*/

export default class SingleContest extends Component {

	constructor(props) {
		super(props);

		this.nextPage = this.nextPage.bind(this);
		this.findSingleContestData = this.findSingleContestData.bind(this);
		this.getCurrentVideo = this.getCurrentVideo.bind(this);
		this.sortByLikeCount = this.sortByLikeCount.bind(this);
		this.sortByLoveCount = this.sortByLoveCount.bind(this);
		this.sortByPoints = this.sortByPoints.bind(this);
		this.sortByName = this.sortByName.bind(this);
		// this.clearToast = this.clearToast.bind(this);
		this.getCurrentContest = this.getCurrentContest.bind(this);

		//console.log(this.props.match.params.id);
		//console.log(this.props.match.params);
		//console.log(this.props.match);
		this.state = {
			isLoading: true,
			showVideo: false,
			contestId: props.match.params.id,
			sortType: "alphabetical",
			selectedBoxKey: null,
			toastMsg: "",
			toastTime: 5000,
			toastSuccess: null,
			currentPage: 1,
			pageSize: 15,
			videosOnPage: [],
			pagesLoaded: [],
			showViewMoreButton: true,
			showGifts: false,
			artists: [],
			gifts: []
		}

		this.insertHardcodedThinEnergyAd();
	}

	componentDidMount() {
		// get contest data for all contests
		Auth.currentAuthenticatedUser()
			.then(response => {
				this.setState({ isAuthenticated: true });
				var jwtToken = response.signInUserSession.idToken.jwtToken;
				this.setState({ jwtToken: jwtToken });
				this.loadContests();
			})
			.catch(error => {
				console.log("Auth.currentAuthenticatedUser error");
				console.log(error);

				this.getContestPage(1);
			})
	}

	// TODO combine the following 2 functions
	getContestPageAuth(page) {
		console.log("getting page " + page);

		if(!this.state.pagesLoaded.includes(page))
			axios.get(config.apiGateway.URL + `/contests/current?page=${page}&size=${this.state.pageSize}`, { headers: {Authorization: this.state.jwtToken} })
				.then(result => {
					console.log("GET auth /contests/current?page success");
					console.log(result.data.data);

					const tempLoaded = this.state.pagesLoaded;
					tempLoaded.push(page);

					this.findSingleContestData(result.data.data);
					this.setState({ currentPage: page, pagesLoaded: tempLoaded });
				})
				.catch(error => {
					console.log("GET auth /contests/current?page error");
					console.log(error);
				})
	}

	getContestPage(page) {
		//console.log("getting page " + page);
		if(!this.state.pagesLoaded.includes(page)) {
			this.setState({ isLoading: true });
			axios.get(config.apiGateway.URL + `/contests/current?page=${page}&size=${this.state.pageSize}`)
				.then(result => {
					console.log("GET /contests/current?page success");
					console.log(result.data.data);

					const tempLoaded = this.state.pagesLoaded;
					tempLoaded.push(page);

					this.findSingleContestData(result.data.data);
					this.setState({ currentPage: page, pagesLoaded: tempLoaded, isLoading: false });
				})
				.catch(error => {
					console.log("GET /contests/current?page error");
					console.log(error);
				})
		}
	}

	//temp
	findSingleContestData(contests) {
		//console.log("Searcing");
		//console.log(contests);
		let selectedContestData = null;
		// temp
		for(var i = 0; i < contests.length; i++) {
			//console.log(contests[i].id);
			//console.log(this.state.contestId);
			if(parseInt(contests[i].id) === parseInt(this.state.contestId)) {
				selectedContestData = contests[i];
				this.setState({ showViewMoreButton: (selectedContestData.videos.length > 0) });
				break;
			}
		}

		//console.log(selectedContestData);

		if(selectedContestData) {
			var tempVids = this.state.videosOnPage;
			//console.log(tempVids);
			//console.log("selectedContestData.videos ");
			//console.log(selectedContestData.videos);
			var tempVids = tempVids.concat(selectedContestData.videos);
			//console.log(" new tempVids ");
			//console.log(tempVids);
			this.setState({ contest: selectedContestData, videosOnPage: tempVids, isLoading: false });
		}
	}

	/* temporary ad-related code */
	insertHardcodedThinEnergyAd() {
		let url = "https://www.youtube.com/embed/8m2cGXraFAI";
		let title = "Earn 20 points for watching";
		let description = "Exclusive Brand brought to you by Multi Shot Nutrition&reg;, LLC.<br/>#TheHealthyAlternative&reg;<br/>\"Give It A ShotTM\" Exclusive Brand brought to you by Multi Shot Nutrition&reg;, LLC.";
		let caption = "Try thin energy<br/> 5 day supply $9.99<br/> FREE SHIPPING";
		let subcaption = "Energy you need. Weight loss benefits you want!";
		let brand = "/img/ads/thin_energy/brand.png";
		let profile = "/img/ads/thin_energy/profile.png";
		let thumbnail = "https://img.youtube.com/vi/8m2cGXraFAI/sddefault.jpg";
		let pos = 0;
		this.insertAd(null, url, title, description, profile, thumbnail, brand, caption, subcaption, pos);
	}

	/*
		Construct a video object for use with the buildVideoCarouselItem ad-specific render block.

		vidId: the ID of the video in the API
		title: ...
		description ...
		profileImg: the URI of the profile image for the ad owner, displayed in usual video profile img spot
		thumbnail: the URI of the thumbnail image of the ad
		caption: a secondary text label displayed above the thumbnail
		position: where to insert the ad in the sequence of videos (0 to N, if > N it will be placed at end)
	*/
	insertAd(vidId, videoUrl, title, description, profileImg, thumbnail, brand, caption, subcaption, position) {
		let videoItem = {
			type: "ad",
			videoUrl: videoUrl,
			id: vidId,
			title: title,
			description: description,
			profile_image: profileImg,
			thumbnail: thumbnail,
			brand_image: brand,
			caption: caption,
			subcaption: subcaption,
			position: position
		};

		let temp = this.state.videosOnPage;

		if(position < 0) {
			console.log("Can't insert ad before index 0");
			return;
		} else if(position >= temp.length) {
			// add at end
			temp.push(videoItem);
			this.setState({ videosOnPage: temp });
		} else {
			// insert
			temp.splice(position, 0, videoItem);
			this.setState({ videosOnPage: temp });
		}
	}
	/* end of ad-related code */

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });
		document.getElementById("toast").scrollIntoView(false);
		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}

	loadContests() {
		this.getContestPageAuth(1);
	}

	sortTypeToString(type) {
		switch(type) {
			case "alphabetical":
				return "Alphabetical";
			case "lovecount":
				return "Love Count";
			case "likecount":
				return "Like Count";
			case "points":
				return "Points";
		}
	}

	/*clearToast() {
		//clear toast after certain amount of time
		delay(this.state.toastTime).then(function() {
			this.setState({ toastMsg: "", toastSuccess: null });
		});
	}*/

	setActiveVideoBox(key) {
		if(key !== this.state.selectedBoxKey)
			this.setState({ selectedBoxKey: key, ...(this.state.contest && { currentVideo: this.state.contest.videos[key] }) });
	}

	/*clearActiveVideoBox() {
		//console.log("clearing");
		this.setState({ selectedBoxKey: null });
	}*/

	getCurrentVideo() {
		return this.state.currentVideo;
	}

	sortByLikeCount(a, b) {
		return this.sortByCount(a, b, "like_count");
	}

	sortByLoveCount(a, b) {
		return this.sortByCount(a, b, "love_count");
	}

	sortByPoints(a, b) {
		return this.sortByCount(a, b, "points");
	}

	sortByCount(a, b, label) {
		var result = parseInt(b[label]) - parseInt(a[label]);
		return result / Math.abs(result);
	}

	sortByName(a, b) {
		var result = a.title.toLowerCase().localeCompare(b.title.toLowerCase());
		if(result === 0)
			return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
		else
			return result;
	}

	sortVideos(type) {
		this.setState({ isLoading: true });
		//console.log("sort " + type);
		const toSort = this.state.videosOnPage;
		if(type === "alphabetical") {
			//console.log("alpha sort");
			toSort.sort(this.sortByName);
		} else if(type === "likecount") {
			toSort.sort(this.sortByLikeCount);
		} else if(type === "lovecount") {
			toSort.sort(this.sortByLoveCount);
		} else if(type === "points") {
			toSort.sort(this.sortByPoints);
		}
		this.setState({ videosOnPage: toSort, sortType: type, isLoading: false });
	}

	getToastClass() {
		return (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden"
	}

	getCurrentContest() {
		return this.state.contestId;
	}

	redirectCallback(contestId) {
		window.location = `/contest/${contestId}`;
	}

	nextPage() {
		this.getContestPageAuth(this.state.currentPage + 1);
	}

	redirectToChannels() {
		window.location = `/channels`;
	}

	render() {
		let videoCarouselProps = {
			mode: "tile",
			videos: this.state.videosOnPage,
			nextPageCallback: this.nextPage,
			showViewMoreButton: this.state.showViewMoreButton,
			redirectCallback: this.redirectCallback,
			redirectId: this.state.contestId,
			isAuthenticated: this.state.isAuthenticated,
			jwtToken: this.state.jwtToken,
			...(this.state.selectedVideo && {selectedVideo: this.state.selectedVideo }),
			...(this.state.selectedArtist && {selectedArtist: this.state.selectedArtist })
		};

		return (
			<>{
				this.state.isLoading ?
					<div className="loading"></div>
				:
					<div className="contest-background">
						<Row>
							<Col sm={8}>
								<div className="mt-md-5 mt-sm-2 pt-5">
									<h1 className="contestsHeader mt-md-5 mt-sm-2 ml-md-5 ml-sm-4 px-4 d-inline-block">
										{ this.state.contest ? this.state.contest.name : "" }
									</h1>
								</div>
							</Col>
							<Col sm={4}>
								<div className="contestSortingControlBar m-3">
									<span className="mr-4">Sorted By</span>
									<DropdownButton className="sortDropdown d-inline" title={ this.sortTypeToString(this.state.sortType) }>
										<Dropdown.Item onClick={ () => { this.sortVideos("alphabetical") } }>Alphabetical</Dropdown.Item>
										{/*<Dropdown.Item onClick={ () => { this.sortVideos("lovecount") } }>Love Count</Dropdown.Item>*/}
										{/*<Dropdown.Item onClick={ () => { this.sortVideos("likecount") } }>Like Count</Dropdown.Item>*/}
										<Dropdown.Item onClick={ () => { this.sortVideos("points") } }>Points</Dropdown.Item>
									</DropdownButton>
								</div>
							</Col>
						</Row>
						{
							!this.state.isLoading ?
								<VideoCarousel {...videoCarouselProps} key={ this.state.videosOnPage.length } />
							: ""
						}
					</div>
			}</>
		)
	}
}
