import React, { Component} from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import  { Redirect } from 'react-router-dom'
import "./LoginHeader.css";
import Amplify, { Auth } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown} from '@fortawesome/free-solid-svg-icons'

export default class LoginHeader extends Component {

	constructor(props) {
		super(props);
		
		this.handleImgError = this.handleImgError.bind(this);
		
		this.state = {
			isAuthenticated: props.childProps.isAuthenticated,
			userHasAuthenticated: props.childProps.userHasAuthenticated,
			userData: props.userData,
			isLoading: true,
			dropdownMenuIsOpen: false,
			imgErrorTriggered: false
		}
	}

	componentDidMount() {
		let currentComponent = this;
	}

	componentDidUpdate() {

	}

	redirectToLogin() {
		window.location.href = window.location.origin + "/login";
	}

	redirectToSignup() {
		window.location.href = window.location.origin + "/signup";
	}

	handleLogout = async event => {
		await Auth.signOut();
		this.state.userHasAuthenticated(false);
		window.location = "/login";
	}

	handleImgError(e) {
		//console.log(e);
		if(!this.state.imgErrorTriggered && e.target) {
			//console.log("hiding");
			//console.log(e.target);
			//console.log(e.target.style);
			e.target.style.display = "none";
			this.setState({ imgErrorTriggered: true });
		} else {
			//console.log("skipping");
		}
	}

	renderAuth() {
		const dropdownIcon = (<div className="capitalize loginHeaderText">
				{ (this.state.userData) ? this.state.userData.fullName: "MY ACCOUNT" }
				<FontAwesomeIcon icon={ faChevronDown } className="loginHeaderMenuButton"/>
			</div>);
		return (
			<div className="loginHeaderContainer">
				{
					(this.state.userData) ?
						<a href="/account" className="headerProfileImage d-inline-block" style={{'backgroundImage': 'url('+(this.state.userData.profile_image ? this.state.userData.profile_image : "/img/profile/default-profile.png")+')' }}>
							<img src="/img/blank.png" alt={ this.state.userData.fullName } onError={ this.handleImgError } />
						</a>
					: ""
				}

				<Nav className="d-inline-block">
					<NavDropdown title={ dropdownIcon } open={ this.state.dropdownMenuIsOpen } className="d-inline-block loginHeaderUsername">
						<NavDropdown.Item href="/account">My Account</NavDropdown.Item>
						<NavDropdown.Item onClick={ this.handleLogout }>Logout</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</div>
		);
	}

	renderUnauth() {
		return (
			<div className="loginHeaderContainer">
				<div className="loginHeaderBtn pointerCursor d-inline-block" onClick={ () => { this.redirectToLogin() } }>
					LOGIN
				</div>
				<div className="registerHeaderBtn pointerCursor d-inline-block" onClick={ () => { this.redirectToSignup() } }>
					Register
				</div>
			</div>
		);
	}

	render() {
		return (this.state.isAuthenticated) ? this.renderAuth() : this.renderUnauth();
	}
}
