import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Auth } from "aws-amplify";
import $ from "jquery";
import config from '../config.js';
import ReactPlayer from 'react-player';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Genres.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChevronRight, faChevronLeft, faStar
	, faThumbsUp, faHeart, faGift, faComment, faPlay } from '@fortawesome/free-solid-svg-icons'
import ReactPlayerModal from './ReactPlayerModal';
import VideoCarousel from './VideoCarousel';


const CustomRightArrow = ({ onClick, ...rest }) => {
  // onMove means if dragging or swiping in progress.
  return (
	<div onClick={() => onClick()} className="customCarouselArrow customRightArrow">
		<FontAwesomeIcon icon={ faChevronRight } />
	</div>
	);
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  // onMove means if dragging or swiping in progress.
  return (
	<div onClick={() => onClick()} className="customCarouselArrow customLeftArrow">
		<FontAwesomeIcon icon={ faChevronLeft } />
	</div>
	);
};


// main renderer
class GenresPage extends Component {

	constructor(props) {
		super(props)

		this.showVideoCallback = this.showVideoCallback.bind(this);
		this.getCurrentVideo = this.getCurrentVideo.bind(this);
		this.getCurrentGenre = this.getCurrentGenre.bind(this);
		this.redirectCallback = this.redirectCallback.bind(this);

		this.state = {
			genres: [],
			artists: [],
			videos: {}, // indexed by genre id
			loggedIn: false,
			jwtToken: '',
			commentId: '',
			artistId: '',
			currentVideo: [],
			currentGenre: null,
			isLoading: true,
			windowWidth: window.innerWidth,
			defaultPageSize: 10,
			maxCharsInSliderVideoDescription: 150,
			showViewAllButton: true,
			toastMsg: "",
			toastSuccess: null,
			toastTime: 5000
		}
	};

	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	componentDidMount() {
		Auth.currentAuthenticatedUser()
			.then(response => {
				this.setState({ isAuthenticated: true });
				var jwtToken = response.signInUserSession.idToken.jwtToken;
				this.setState({ jwtToken: jwtToken });
				this.getMe();
				this.loadGenres();
			})
			.catch(error => {
				//console.log("Auth.currentAuthenticatedUser error");
				console.log(error);
				// loadGenres() is for authorized users
				axios.get(config.apiGateway.URL+'/videos/genres')
					.then(result => {
						console.log("GET /videos/genres success");
						console.log(result.data.data);
						this.sortAndStoreGenres(result.data.data);
						this.loadAllVideos(result.data.data);
					})
					.catch(error => {
						console.log("GET /videos/genres error");
						console.log(error);
					})
			})
	}

	sortAndStoreGenres(data) {
		data.sort((a, b) => { return a.position - b.position });
		console.log(data);
		this.setState({ genres: data });
	}

	/*
		Display a notification message when an API action is taken
	*/
	makeToast(msg, success) {
		this.setState({ toastMsg: msg, toastSuccess: success });

		if(document.getElementById("toast"))
			document.getElementById("toast").scrollIntoView(false);

		//clear toast after certain amount of time
		setTimeout(() => {
			this.setState({ toastMsg: "", toastSuccess: null });
		}, this.state.toastTime);
	}

	getToastClass() {
		return (this.state.toastMsg) ? (
			this.state.toastSuccess === true ? (
				"bg-success"
			) : (
				this.state.toastSuccess === false ? "bg-danger" : "bg-info"
			)
		) : "hidden";
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};


	loadAllVideos(genres) {
		for(var i = 0; i < genres.length; i++) {
			//console.log("genre " + result.data.data[i]);
			this.getVideos(genres[i]);
		}
	}

	loadGenres() {
		axios.get(config.apiGateway.URL+`/videos/genres`, { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /videos/genres success");
				//console.log(result.data.data);
				this.sortAndStoreGenres(result.data.data);
				this.loadAllVideos(result.data.data);
				this.getComments();
			})
			.catch(error => {
				console.log("GET /videos/genres error");
				console.log(error);
			})
	}

	getVideos(id) {
		axios.get(config.apiGateway.URL+`/videos/genre/${id.id}`, { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET videos/genres success");
				//console.log(result.data.data);
				let tempVideos = this.state.videos;
				tempVideos[id.id] = result.data.data;
				this.setState({ videos: tempVideos, isLoading: false });
				//console.log(tempVideos[id.id]);
				//this.getComments();
			})
			.catch(error => {
				console.log("GET videos/genres error");
				console.log(error);
			})
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	expandVideoSliderItem(id) {
		this.setState({ selectedVideoItem: id, showViewAllButton: false });
	}

	closeVideoSliderItem(id) {
		this.setState({ selectedVideoItem: "", showViewAllButton: true });
	}

	/* redirect to artist page on user avatar click */
	redirectToArtist(artist) {
		if(artist)
			window.location = `/artist/${artist}`;
		else
			console.log("No artist selected for redirect");
	}

	redirectToGenrePage(genre) {
		//console.log("redirecting to " + genre.id);

		if(genre) {
			let genreName = genre.name.replace("/", "-");
			window.location = `/genre/${genre.id}/${genreName}`;
		}
	}

	showVideoCallback(show) {
		this.setState({ showVideo: show });
	}

	getCurrentVideo() {
		return this.state.currentVideo;
	}

	getCurrentGenre() {
		return this.state.currentGenre;
	}

	redirectCallback(genreId) {
		let genre = null;

		//genre ID gives route for redirect, linear search genre data for name as URI parameter
		for(var i = 0; i < this.state.genres.length; i++) {

			if(this.state.genres[i].id === genreId) {
				genre = this.state.genres[i];
				break;
			}
		}

		if(genre)
			this.redirectToGenrePage(genre);
	}

	render() {
		return (
			<div className="genre-background">
				<div className="row pt-5 mb-5 pl-4">
					<div className="col mb-3">
						<div className="pl-md-5 pl-sm-2 pt-4">
							<h1 className="genresHeader mt-md-5 ml-3 d-inline-block">GENRES</h1>
						</div>
					</div>
				</div>
				{ this.state.isLoading
					? <div className="loading"></div>
					: ""
				}
				<div id="toast" className={ "toastStyle " + this.getToastClass() }>{ this.state.toastMsg }</div>
				{
					this.state.genres && this.state.genres.map((genre, genre_key) => {
						let videoCarouselProps = {
							mode: "slider",
							videos: this.state.videos[genre.id],
							redirectCallback: this.redirectCallback,
							redirectId: genre.id,
							isAuthenticated: this.state.isAuthenticated,
							jwtToken: this.state.jwtToken,
							...(this.state.selectedVideo && {selectedVideo: this.state.selectedVideo }),
							...(this.state.selectedArtist && {selectedArtist: this.state.selectedArtist })
						};

						return (
							<Fragment key={ genre_key }>
								{
									(this.state.videos[genre.id] && (this.state.videos[genre.id] && this.state.videos[genre.id].length > 0)) ? (
										<div className="container videoCarouselContainer m-0 p-0">
											<div className="row">
												<div className="col-12 mb-3 p-0">
													<h3 className="section-title-genres ml-md-5 ml-sm-3 pl-3">{ genre.name }</h3>
													<VideoCarousel {...videoCarouselProps} />
												</div>
											</div>
										</div>
									) : ""
								}
							</Fragment>
						)
					})
				}
				{
					this.props.footer
				}
			</div>
		)
	}
}

export default GenresPage;
