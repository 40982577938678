import React, { Component, Fragment } from 'react';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Auth } from "aws-amplify";
import ReactPlayer from 'react-player';
import config from '../config.js';
// import VideoUpload from './VideoUpload';
import CustomCarouselArrow from './CustomCarouselArrow';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Account.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitter, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import { faPencilAlt, faFileVideo, faChevronRight, faUpload } from '@fortawesome/free-solid-svg-icons';
import ScrollIntoView from 'react-scroll-into-view';
import VideoUpload from './VideoUpload.js';
import VideoCarousel from './VideoCarousel.js';

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    state: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button onClick={() => onClick()} />;
};

// main renderer
class AccountPage extends Component {
	// state object
	constructor(props) {
		super(props);

		this.handleShowVideo = this.handleShowVideo.bind(this);
		this.handleCloseVideo = this.handleCloseVideo.bind(this);
		this.showModalCallback = this.showModalCallback.bind(this);
		this.showVideoCallback = this.showVideoCallback.bind(this);
		this.getCurrentVideo = this.getCurrentVideo.bind(this);
		this.getVideoUrl = this.getVideoUrl.bind(this);
		this.getComments = this.getComments.bind(this);
		//this.updateLikes = this.updateLikes.bind(this);
		//this.updateLoves = this.updateLoves.bind(this);


		this.state = {
			isAuthenticated: false,
			jwtToken: '',
			cognito: '',
			bio: '',
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			birthDate: '',
			coverImage: '',
			profileImage: '',
			badges: [],
			videos: [],
			currentVideo: [],
			likeCount: '',
			loveCount: '',
			showVideo: false,
			isLoading: true,
			windowWidth: window.innerWidth,
			showVideoUploadForm: false
		}
	};

	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	componentDidMount() {
		Auth.currentAuthenticatedUser()
			.then(response => {
				if(response.signInUserSession) {
					var jwtToken = response.signInUserSession.idToken.jwtToken;
					this.setState({'jwtToken': jwtToken, isAuthenticated: true });
				}



				axios.get(config.apiGateway.URL + '/users/me', jwtToken ? { headers: {Authorization: jwtToken} } : {})
					.then(result => {
						console.log("GET /users/me success");
						console.log(result.data.data)
						this.setState({
							cognito: result.data.data.cognito_sub,
							bio: result.data.data.bio,
							firstName: result.data.data.given_name,
							lastName: result.data.data.family_name,
							email: result.data.data.email,
							phoneNumber: result.data.data.phone_number,
							birthDate: result.data.data.birthdate,
							coverImage: result.data.data.cover_image,
							profileImage: result.data.data.profile_image,
							likeCount: result.data.data.like_count,
							loveCount: result.data.data.love_count,
							badges: result.data.data.badges,
							videos: result.data.data.videos,
							isLoading: false
						});
					})
					.catch(error => {
						console.log("GET /users/me error");
						console.log(error);
						//window.alert(JSON.stringify(error));
					})
			})
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	handleSubmitLike = (videoId, contestId) => {
		axios.post(config.apiGateway.URL+'/videos/' + videoId + '/like',
			{},
			{ headers: { 'Authorization': this.state.jwtToken }
			})
			.then(result => {
				console.log("POST /videos/{video_id}/like success");
				console.log(result.data.data);
				//this.updateLikes(videoId, this.findContestFromId(contestId));
				if(this.state.showVideo) {
					this.setState({ showVideo: false });
					this.handleShowVideo(videoId);
				}
				//this.loadContests();
				// window.location.reload();
			})
			.catch(error => {
				console.log("POST /videos/{video_id}/like error");
				console.log(error);
			})
	}

	handleSubmitLove = (videoId, contestId) => {
		axios.post(config.apiGateway.URL+'/videos/' + videoId + '/love',
			{},
			{ headers: { 'Authorization': this.state.jwtToken }
			})
			.then(result => {
				console.log("POST /videos/{video_id}/love success");
				console.log(result.data.data);
				//this.updateLoves(videoId, this.findContestFromId(contestId));
				if(this.state.showVideo) {
					this.setState({ showVideo: false });
					this.handleShowVideo(videoId);
				}
				//this.loadContests();
				// window.location.reload();
			})
			.catch(error => {
				console.log("POST /videos/{video_id}/love error");
				console.log(error);
			})
	}

	handleSubmitComment = (videoId) => {
		axios.post(config.apiGateway.URL+'/videos/' + videoId + '/comment/' + this.state.commentId,
			{},
			{ headers: { 'Authorization': this.state.jwtToken }
			})
			.then(result => {
				console.log("POST /videos/{video_id}/comment/{comment_id} success");
				console.log(result.data.data);
			})
			.catch(error => {
				console.log("POST /videos/{video_id}/comment/{comment_id} error");
				console.log(error);
			})
	}

	handleSubmitGift = () => {
		//console.log("handleSubmitGift");

		// get the cost of the gift and make sure the user can afford it
		//const gift = this.getGift(this.state.gifts, this.state.giftId);

		// this is now handled withthe backend 400 response
		/*if (gift.cost > parseInt(this.state.totalToken)) {
			this.makeToast('You do not have enough token to purchase this gift', false);
			return;
		}*/

		axios.post(config.apiGateway.URL + '/users/' + this.state.artistId + '/gift/' + this.state.giftId,
			{},
			{ headers: { 'Authorization': this.state.jwtToken }
			})
			.then(result => {
				console.log("POST /users/{user_sub}/gift/{gift_id}/ success");
				console.log(result.data.data);
				this.makeToast("Gift sent! Thanks for showing your support.", true);
			})
			.catch(error => {
				console.log("POST /users/{user_sub}/gift/{gift_id}/ error");
				console.log(error);
				if(error.response.data.data.details === "User cannot afford to give gift")
					this.makeToast(error.response.data.data.public, null);
			})
	}

	handleCloseVideo() {
		this.setState({ showVideo: false, currentVideo:[] });
	}

	showVideoCallback(show) {
		this.setState({ showVideo: show });
	}

	getCurrentVideo() {
		return this.state.currentVideo;
	}

	async handleShowVideo(videoId) {
		this.setState({ disableSend: true });

		await this.getVideoUrl(videoId);

		this.setState({ disableSend: false, showVideo: true });
	}

	getComments() {
		axios.get(config.apiGateway.URL+'/videos/comments/canned', { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /videos/comments/canned success");
				console.log(result.data.data);
				this.setState({ comments: result.data.data });
			})
			.catch(error => {
				console.log("GET /videos/comments/canned error");
				console.log(error);
			})
	}

	getVideoUrl(videoId) {
		axios.get(config.apiGateway.URL+'/videos/'+videoId, { headers: {Authorization: this.state.jwtToken} })
			.then(result => {
				console.log("GET /videos/"+videoId+" success");
				console.log(result.data.data);

				this.setState({ currentVideo: result.data.data });
			})
			.catch(error => {
				console.log("GET /videos/"+videoId+" error");
				console.log(error);
				//window.alert(JSON.stringify(error));
			});
	}

	showModalCallback() {
		this.setState({ showVideoUploadForm: false });
	}

	redirectCallback(id) {
		//do nothing
		console.log("redirect " + id);
	}

	render() {

		const { width } = this.state;
		const isMobile = width < 768;

		const responsive = {
			desktopLarge: {
				breakpoint: { max: 3000, min: 1200 },
				items: 4,
				slidesToSlide: 4,
			},
			desktop: {
				breakpoint: { max: 1200, min: 1024 },
				items: 3,
				slidesToSlide: 3,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
				slidesToSlide: 2,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
				slidesToSlide: 1,
			},
		};

		const bgStyle = {
			'background': `linear-gradient(to bottom, rgba(45, 45, 45, 0) 0%, rgba(45, 45, 45, 1.0) 250px) left center no-repeat, url("${this.state.coverImage}") left top / 100% no-repeat`,
			'backgroundSize': '100%'
		};

		let tempVideos = this.state.videos;
		for(var i = 0; i < tempVideos.length; i++) {
			tempVideos[i].given_name = this.state.firstName;
			tempVideos[i].family_name = this.state.lastName;
		}

		let videoCarouselProps = {
			mode: "slider",
			videos: tempVideos,
			redirectCallback: this.redirectCallback,
			redirectId: this.state.cognito,
			isAuthenticated: this.state.isAuthenticated,
			disableViewAll: true,
			jwtToken: this.state.jwtToken,
			selectedArtist: [this.state.cognito, this.state.firstName + " " + this.state.lastName],
			...(this.state.selectedVideo && {selectedVideo: this.state.selectedVideo })
		};

		//
		return (
			(this.state.isLoading) ? <div className="loading"></div> :
			<Row className="p-0 m-0">
				{
					this.state.showVideoUploadForm ? <VideoUpload contestID={ this.state.contestID } showModalCallback={ this.showModalCallback }/> : ""
				}
				<Col lg={2} sm={12} className="leftCol pt-lg-5 pt-md-3">
					<Row>
						<Col className="text-center p-0 m-0">
							<div className="leftColProfileImg" style={{'backgroundImage': 'url('+(this.state.profileImage ? this.state.profileImage : "/img/profile/default-profile.png")+')' }}>
								<img src="/img/blank.png" alt={ this.getUserFullname() } />
							</div>
							<div className="leftColUsername">{ this.getUserFullname() }</div>
							<div className="faIcons">
								<FontAwesomeIcon icon={ faFacebookSquare }/>
								<FontAwesomeIcon icon={ faTwitter }/>
								<FontAwesomeIcon icon={ faInstagram }/>
							</div>
							<div className="myAccountHeading mt-3 text-left">
								<span>MY ACCOUNT</span>
							</div>
						</Col>
					</Row>
					<Row className="myAccountNav">
						<Col sm={12}>
							<ScrollIntoView selector="#mySongsCarousel" className="leftColLink">
								Songs
							</ScrollIntoView>
						</Col>
						{/*<Col sm={12}>
							<ScrollIntoView selector="#myPlaylistsCarousel" className="leftColLink">
								Playlists
							</ScrollIntoView>
						</Col>*/}
						<Col sm={12}>
							<ScrollIntoView selector="#myBadgesCarousel" className="leftColLink">
								Badges
							</ScrollIntoView>
						</Col>
						<Col sm={12}>Presents</Col>
						<Col sm={12}>
							<span>Loves</span>
							<div className="float-right">{ this.state.loveCount }</div>
						</Col>
						<Col sm={12}>
							<span>Likes</span>
							<div  className="float-right">{ this.state.likeCount  }</div>
						</Col>
					</Row>
				</Col>
				<Col lg={10} sm={12} style={ bgStyle } className="pt-5">
					<Row>
						<Col sm={12} className="rightColContents">
							<div className="mt-lg-5 mt-md-3 pt-lg-5 pt-md-3 px-lg-5 px-md-4 px-sm-2">
								<div className="rightColProfileImg d-inline-block" style={{'backgroundImage': 'url('+(this.state.profileImage ? this.state.profileImage : "/img/profile/default-profile.png")+')' }}>
									<img src="/img/blank.png" alt={ this.getUserFullname() } />
								</div>
								<div className="d-inline-block pl-4">
									<h2 className="rightColUsername m-0">{ this.getUserFullname() }</h2>
									<div className="editAccountButton">
										<FontAwesomeIcon icon={ faPencilAlt } className="mx-2"/>
										<a href="/account/edit">Edit Account</a>
									</div>
								</div>
								<p className="pt-5 pb-3 mx-4 userBio">
								{
									(this.state.bio) ? this.state.bio : "This artist does not have a biography yet."
								}
								</p>
							</div>
						</Col>
					</Row>
					<Row id="mySongsCarousel">
						<Col className="p-0 mx-0 mt-5">
							<div className="carouselContainer">
								<h4 className="mySongsCarousel">
									MY SONGS
								</h4>
								<VideoCarousel {...videoCarouselProps} />
								<div className="uploadSongLink" onClick={ () => { this.setState({ showVideoUploadForm: true, contestID: 1 }) } }>
									<FontAwesomeIcon icon={ faUpload } className="mx-2 uploadSongIcon"/>
									Upload song
								</div>
							</div>
						</Col>
					</Row>
					<Row id="myBadgesCarousel">
						<Col className="p-0 mx-0 my-5">
							<div className="carouselContainer">
								<h4 className="myBadgesCarousel">
									MY BADGES
								</h4>
									<Carousel
										swipeable={true}
										draggable={true}
										showDots={false}
										responsive={responsive}
										keyBoardControl={true}
										transitionDuration={500}
										itemClass="badgeCarouselItem"
									>
									{
										this.state.badges && this.state.badges.length > 0 ? this.state.badges.map((badge, badge_key) => {
											return (
												<div className="badgeContainer" key={ badge_key }>
													<img className="img-fluid badges py-4" width="100" height="100" src={ badge.img } alt={ badge.name } />
													<p>{ badge.name }</p>
												</div>
											)
										}) : (
											<div className="emptyBadge badgeCarouselItem p-4">Participate to earn some badges!</div>
										)
									}
								</Carousel>
							</div>
						</Col>
					</Row>
				</Col>
				<Col sm={12}>
					{ this.props.footer }
				</Col>
			</Row>
		)
	}

	getUserFullname() {
		return (this.state.firstName && this.state.lastName) ? (
			(`${this.state.firstName} ${this.state.lastName}`).toUpperCase()
		) : ""
	}
}

export default AccountPage;
