import React from "react";
import { Button } from "react-bootstrap";
import "./LoaderButton.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons'

export default ({
    isLoading,
    text,
    loadingText,
    className = "",
    disabled = false,
    ...props
}) =>
    <Button
        className={`LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
        {isLoading && <FontAwesomeIcon icon={faSpinner} />}&nbsp;
        
		{
			!isLoading && props.type === "facebook" ?
				<FontAwesomeIcon icon={ faFacebookSquare } className="mr-3" />
			: ""
		}
		{
			!isLoading && props.type === "twitter" ?
				<FontAwesomeIcon icon={ faTwitter } className="mr-3" />
			: ""
		}
		{
			!isLoading && props.type === "google" ?
				<FontAwesomeIcon icon={ faGoogle } className="mr-3" />
			: ""
		}
		
        {!isLoading ? text : loadingText}
    </Button>;
