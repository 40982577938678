// import { Auth } from "aws-amplify";
import React, { Component } from "react";
import {
	Container,
    Row,
    Col,
	FormGroup,
	FormControl
} from "react-bootstrap";
// import LoaderButton from "./LoaderButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import FacebookButton from './FacebookButton';
import Login from "./Login";
import Register from "./Register";
import LoaderButton from "./LoaderButton";
import "./Signup.css";


/*
	Signup is the container page for the Login / Register form.
*/
export default class Signup extends Component {

	constructor(props) {
		super();
		this.state = {
			userAuthenticated: props.userAuthenticated,
			userHasAuthenticated: props.userHasAuthenticated,
			mode: props.mode,
			expanded: false,
			userDataCallback: props.userDataCallback
		}
		this.goBack = this.goBack.bind(this);
		this.userDataCallback = this.userDataCallback.bind(this);
	}

	componentDidMount() {
		this.validateEmail = this.validateEmail.bind(this);
		this.expandSignupForm = this.expandSignupForm.bind(this);
	}

	componentWillUnmount() {

	}

	// go back to previous form state
	goBack(e) {
		if(this.state.expanded)
			this.setState({ expanded: false });
	}

	// universal change handler
	handleChange = event => {
    	this.setState({
      		[event.target.id]: event.target.value
    	});
  	}

    handleFbLogin = () => {
        this.state.userHasAuthenticated(true);
    };

	/*
		Builds the login buttons and "start of process" form for the register new account component.
	*/
	buildFederatedRegisterForm() {
		return (
		<>
			<div className="mt-4">
				<FacebookButton onLogin={ this.handleFbLogin } />
				{/*<LoaderButton
					block
					className="federatedLoginButton FacebookButon"
					text="Signup with Facebook"
					onClick={ this.handleClick }
					disabled={ this.state.isLoading }
					type="facebook"
				/>
				<LoaderButton
					block
					className="federatedLoginButton TwitterButton"
					text="Signup with Twitter"
					onClick={ this.handleClick }
					disabled={ this.state.isLoading }
					type="twitter"
				/>
				<LoaderButton
					block
					className="federatedLoginButton GoogleButton"
					text="Signup with Google"
					onClick={ this.handleClick }
					disabled={ this.state.isLoading }
					type="google"
				/>*/}
				<div className="thickHrContainer">
					<Row>
						<Col className="text-center my-4">
							<hr className="thickHorizontalRule d-inline-block"/>
							<h4 className="d-inline-block font-weight-bold thickHrLabel">OR</h4>
							<hr className="thickHorizontalRule d-inline-block"/>
						</Col>
					</Row>
				</div>
			</div>
			<form onSubmit={this.handleSubmit}>
				<FormGroup className="bold">
					<FormControl
						autoFocus
						type="email"
						value={ this.state.email }
						onChange={ this.handleChange }
						placeholder="Email Address"
						className="fatTextField"
						id="email"
					/>
					<FontAwesomeIcon icon={ faEnvelope } className="inputIcon"/>
				</FormGroup>
				<Row>
					<Col sm={2}></Col>
					<Col sm={8}>
						<LoaderButton
							size="lg"
							type="submit"
							disabled={ !this.validateEmail() }
							onClick={ this.expandSignupForm }
							isLoading={ this.state.isLoading }
							text="CREATE ACCOUNT"
							loadingText="Signing up…"
							className="signupButton"
						/>
					</Col>
				</Row>
			</form>
		</>
		)
	}

	userDataCallback(data) {
		console.log("callback in signup");
		this.state.userDataCallback(data);
	}

	validateEmail() {
		return this.state.email && this.state.email.length > 0;
	}

	expandSignupForm() {
		this.setState({ expanded: true });
	}

	renderAuth() {
		return "You are already logged in.";
	}

	renderUnauth() {
		const signupBgStyle = {
			'backgroundImage': "url('img/signup/login-signup-bg.jpg')",
			'backgroundRepeat': "no-repeat",
			'backgroundSize': '100%'
		};
		const loginClass = this.state.mode === "login" || this.state.mode === "recovery" ? "selected" : "";
		const registerClass = this.state.mode === "register" ? "selected" : "";

		return (
			<div className="signupBg">
				<Row style={ signupBgStyle }>
					<Col sm={1}></Col>
					<Col sm={10} className="signupFormColumn text-center">
						<Row className="text-center signupLogo">
							<Col>
								<img src={ "img/signup/login-signup-logo.png" } alt="Register" />
							</Col>
						</Row>
						<Row>
							<Col className="formContainer">
								<span className={"signupFormHeader " + loginClass} onClick={ () => { this.setState({mode: "login"})} }>LOGIN</span>
								<span className="signupFormSeparator">|</span>
								<span className={"signupFormHeader " + registerClass} onClick={ () => { this.setState({mode: "register"})} }>REGISTER</span>
								<div className="Signup">
								{
									(this.state.mode === "register") ? (
										(this.state.expanded === true) ? (
											<Row>
												<Col className="registerFormSubheading text-white">
													Complete Account Setup
												</Col>
											</Row>
										): (
											<Row>
												<Col className="registerFormSubheading text-muted">
													Create a new account
												</Col>
											</Row>
										)
									) : (
										<Row>
											<Col className="loginFormSubheading">
												Login to access your account
											</Col>
										</Row>
									)
								}
								{

									(this.state.mode === "login" || this.state.mode === "recovery") ? (
										<Login userHasAuthenticated={ this.state.userHasAuthenticated } userDataCallback={ this.state.userDataCallback }
											mode={ this.state.mode }/>
									) : (
										(this.state.expanded === true) ? (
											<>
												<div onClick={ this.goBack } className="text-left backButton">
													<FontAwesomeIcon icon={ faArrowLeft } className="d-inline-block mx-2"/>
													<div className="d-inline-block backButtonLabel">Back</div>
												</div>
												<Register userHasAuthenticated={ this.state.userHasAuthenticated }
														userDataCallback={ this.state.userDataCallback } email={ this.state.email }/>
											</>
										): this.buildFederatedRegisterForm()
									)
								}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		)
	}

	render() {
		return this.state.userAuthenticated ? this.renderAuth() : this.renderUnauth();
	}
}
