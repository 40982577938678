import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";

import config from "../config";

function waitForInit() {
	console.log("wait for init");
    return new Promise((res, rej) => {
		
        const hasFbLoaded = () => {
			console.log("spinning");
            if (window.FB) {
                res();
            } else {
                setTimeout(hasFbLoaded, 300);
            }
        };
		
        hasFbLoaded();
    });
}

export default class FacebookButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    async componentDidMount() {
        await waitForInit();
        this.setState({ isLoading: false });
    }

    statusChangeCallback = response => {
		//console.log("callback");
		//console.log(response);
		//alert("statusChangeCallback");
		
        if (response.status === 'connected') {
            this.handleResponse(response.authResponse);
        } else {
            window.FB.login(
                response => {
                    if (!response || !response.authResponse) {
                        return;
                    }
                    this.handleResponse(response.authResponse);
                },
                {
                    // the authorized scopes
                    scope: 'public_profile,email'
                }
            );
        }
    };

    checkLoginState = () => {
        window.FB.getLoginStatus(this.statusChangeCallback);
    };

    handleClick = () => {
       // window.FB.login(this.checkLoginState, {scope: "public_profile,email"});
        //var host = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        var host = 'https://' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        
        window.location.href = config.cognito.DOMAIN + "/login?response_type=token&client_id=" + config.cognito.APP_CLIENT_ID + "&redirect_uri=" + host;
        //window.location.href = config.cognito.DOMAIN + "/login?response_type=token&client_id=" + config.cognito.APP_CLIENT_ID + "&redirect_uri=" + host;
    };

    handleResponse(response) {
        const { accessToken, expiresIn } = response;
        const date = new Date();
        const expires_at = expiresIn * 1000 + date.getTime();
		
		//console.log(response);
		//alert(accessToken);
		//alert("abc");
		
        if (!accessToken) {
            return;
        }
		
		
		
        window.FB.api('/me', { fields: 'email,first_name,last_name,id' }, response => {
            const user = {
                id: "Facebook_" + response.id,
                name: response.name,
                email: response.email,
                given_name: response.first_name,
                family_name: response.last_name
            };

            Auth.federatedSignIn('facebook', { token: accessToken, expires_at }, user)
                .then(credentials => {
                    console.log(credentials);
                    window.location.href = config.cognito.DOMAIN + "/login?response_type=token&client_id=" + config.cognito.APP_CLIENT_ID + "&redirect_uri=https://localhost:3000/";
                });
        });
    }

    handleError(error) {
        alert(error);
        //alert("handleerror");
    }

    /*async handleResponse(data) {
        const { email, accessToken: token, expiresIn } = data;
        const expires_at = expiresIn * 1000 + new Date().getTime();
        const user = { email };

        this.setState({ isLoading: true });

        try {
            const response = await Auth.federatedSignIn(
                "facebook",
                { token, expires_at },
                user
            );
            this.setState({ isLoading: false });
            this.props.onLogin(response);
        } catch (e) {
            this.setState({ isLoading: false });
            this.handleError(e);
        }
    }*/

    render() {
        return (
            <LoaderButton
                className="federatedLoginButton FacebookButton"
                text="Login with Facebook"
                onClick={ this.handleClick }
                disabled={ this.state.isLoading }
				type="facebook"
            />
        );
    }
}
