import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default class CustomCarouselArrow extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			direction: props.direction
		};
	}
	
	render() {

		
		return (
			<button className="carouselControl">
				hi!
				<FontAwesomeIcon icon={ this.state.direction === "left" ? faChevronLeft : faChevronRight }/>
			</button>
		)
	}
}
