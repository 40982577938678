import React, { Component } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import config from '../config.js';
import './ReactPlayerModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faHeart, faGift, faComment } from '@fortawesome/free-solid-svg-icons'
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons'
import axios from 'axios';

const parse = require('html-react-parser');


/*
	This looks for the links property of props.currentVideo to display normal videos.
	If it's not defined it will embed an external video from currentVideo.videoUrl.
*/
export default class ReactPlayerModal extends Component {

	constructor(props) {
		super(props);

		if(!props.currentVideo) {
			console.log("React player modal was not provided with a video");
		}

		this.handleCloseVideo = this.handleCloseVideo.bind(this);

		this.state = {
			mode: props.mode,
			loggedIn: props.loggedIn,
			disableSend: props.disableSend,
			showVideo: props.showVideo,
			showVideoCallback: props.showVideoCallback,
			currentVideo: props.currentVideo,
			videoFinished: props.videoFinished,
			handleSubmitLike: props.handleSubmitLike,
			handleSubmitLove: props.handleSubmitLove,
			handleShowGifts: props.handleShowGifts,
			handleShowComments: props.handleShowComments,
			embedPlayerHeight: "450px"
		}
	}

	handleCloseVideo() {
		this.setState({ showVideo: false, currentVideo:[] });
		this.state.showVideoCallback(false);
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		if(this.state.currentVideo) {
			const likeTooltip = <Tooltip className={ !this.state.currentVideo.video_i_like ? "hidden" : "" }>You already liked this!</Tooltip>;
			const loveTooltip = <Tooltip className={ !this.state.currentVideo.video_i_love ? "hidden" : "" }>You already loved this!</Tooltip>;

			return (
				<Modal size="lg" show={this.state.showVideo} onHide={this.handleCloseVideo}>
					<Modal.Body>
						<div className="row justify-content-center align-items-center">
							{ this.state.currentVideo ?
								<div className="col-md-12">
									{
										this.state.currentVideo.links ?
											Object.keys(this.state.currentVideo.links)
												.filter(key => this.state.currentVideo.links[key].quality === '540p')
												.map((key, index) => {
													return (
														<ReactPlayer key={key} url={this.state.currentVideo.links[key].url} width="100%" height="100%" playing controls
															onEnded={() => this.state.videoFinished(this.state.currentVideo.id)} />
													)
												})
										:
											<ReactPlayer key={ this.state.currentVideo.title } url={ this.state.currentVideo.videoUrl }
												width="100%" height={ this.state.embedPlayerHeight } playing controls />
									}
									<div className="video-details-modal">
										<div className="row pt-3">
											<div className={ this.state.currentVideo.links ? "col-sm-6" : "col-sm-12 pl-4 text-left" }>
												<h3 className="red"><b>{ this.state.currentVideo.title ? parse(this.state.currentVideo.title) : "" }</b></h3>
												<p className="description py-2">{ this.state.currentVideo.description ? parse(this.state.currentVideo.description) : "" }</p>
											</div>
											<div className="col-md-6">
											{
												this.state.currentVideo.links ?
													<div className="modalVideoFeedbackButtons">
														<OverlayTrigger overlay={ likeTooltip } placement="right">
															<div className="feedbackButton { this.state.currentVideo.video_i_like ? 'feedbackButtonActive' : '' }"
																	onClick={ () => this.state.handleSubmitLike(this.state.currentVideo.id)}>
																<FontAwesomeIcon icon={ faThumbsUp }/>
															</div>
														</OverlayTrigger>
														<OverlayTrigger overlay={ loveTooltip } placement="right">
															<div className="feedbackButton { this.state.currentVideo.video_i_love ? 'feedbackButtonActive' : '' }"
																	onClick={() => this.state.handleSubmitLove(this.state.currentVideo.id)}>
																<FontAwesomeIcon icon={ faHeart}/>
															</div>
														</OverlayTrigger>

														<div className="feedbackButton" onClick={ () => this.state.handleShowGifts(this.state.currentVideo) }>
															<FontAwesomeIcon icon={ faGift }/>
														</div>
														<div className="feedbackButton" onClick={ () => this.state.handleShowComments(this.state.currentVideo) }>
															<FontAwesomeIcon icon={ faComment }/>
														</div>
														<div className="faLikes">
															<FontAwesomeIcon className="faPoints" icon={ faRegularHeart }/>
															<span>{ this.state.currentVideo.points }</span>
														</div>
													</div>
												: ""
											}
											</div>
										</div>
									</div>
								</div>
								: <div className="loading"></div>
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="modalCloseButton" variant="secondary"
								onClick={ this.handleCloseVideo } disabled={ this.state.disableSend }>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			)
		} else {
			return "";
		}
	}
}
