import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Judges from './components/Judges';
import NotFound from './components/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ContestRules from './components/ContestRules';
import About from './components/About';
import ContestWinners from './components/ContestWinners';
import Account from './components/Account';
import AccountEdit from './components/AccountEdit';
import Channels from './components/Channels';
import Genres from './components/Genres';
import SingleContest from './components/SingleContest';
import SingleGenre from './components/SingleGenre';
import Artists from './components/Artists';
import Artist from './components/Artist';

export default ({ childProps }) =>
  	<Switch>
	    <AppliedRoute path="/" exact component={Home} props={childProps} />
	    <AppliedRoute path="/judges" exact component={Judges} props={childProps} />
	    <AppliedRoute path="/privacy" exact component={PrivacyPolicy} props={childProps} />
	    <AppliedRoute path="/terms" exact component={TermsOfService} props={childProps} />
	    <AppliedRoute path="/channels" exact component={Channels} props={childProps} />
	    <AppliedRoute path="/genres" exact component={Genres} props={childProps} />
	    <AppliedRoute path="/contest/:id" exact component={SingleContest} props={childProps} />
	    <AppliedRoute path="/genre/:id/:name" exact component={SingleGenre} props={childProps} />
	    <AppliedRoute path="/about" exact component={About} props={childProps} />
	    <AppliedRoute path="/rules" exact component={ContestRules} props={childProps} />
	    <AppliedRoute path="/contest/winners" exact component={ContestWinners} props={childProps} />
	    <AppliedRoute path="/artists" exact component={Artists} props={childProps} />
	    <AppliedRoute path="/artist/:cognitoSub" exact component={Artist} props={childProps} />
		<UnauthenticatedRoute path="/signup" exact component={Signup} props={{...childProps, mode:"register"}} />
		<UnauthenticatedRoute path="/login" exact component={Signup} props={{...childProps, mode:"login"}} />
		<UnauthenticatedRoute path="/recovery" exact component={Signup} props={{...childProps, mode:"recovery"}} />
	    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />
	    <AuthenticatedRoute path="/account/edit" exact component={AccountEdit} props={childProps} />
	    {/*<AuthenticatedRoute path="/notes/:id" exact component={Notes} props={childProps} />*/}
	    { /* Finally, catch all unmatched routes */ }
	    <Route component={NotFound} />
  	</Switch>;
