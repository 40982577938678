import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

// inline stylesheet
const Styles = styled.div`
	footer a {
		color: white;
		margin: 4px;
	}
`;

// main renderer
class Footer extends Component {
	render() {
		return (
			<Styles>
				<footer className="siteFooter my-4">
						<Row>
							<Col className="text-center">
								<a href="/"><img src="/img/footer-logo.png" className="my-3" alt="CelebYou"/></a>
								<p className="text-center text-white" style={{width: '100%'}}>
									<a href="/privacy" className="d-inline-block">Privacy Policy</a>
									<span className="footerSeparator">|</span>
									<a href="/terms" className="d-inline-block">Terms of Service</a>
									<span className="footerSeparator">|</span>
									<a href="/rules" className="d-inline-block">Contest Rules</a>
									<span className="footerSeparator">|</span>
									{/*<a href="/contactus" className="d-inline-block">Contact Us</a>
									<span className="footerSeparator">|</span>*/}
									<a href="/recovery" className="d-inline-block">Password Recovery</a>
								</p>
							</Col>
						</Row>
						<Row>
							<p className="py-1 mt-3 mx-auto text-center text-white footerCopyright">© 2019 CelebYou. All rights reserved. CelebYou takes copyright infringement very seriously, and abides by the Digital Millennium Copyright Act. For more information, please carefully review our Terms of Service.</p>
						</Row>
				</footer>
			</Styles>
		)
	}
};

export default Footer;